import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ColorsForPie } from '../../../utils/variables/folderStructureFiles';

ChartJS.register(Title, Tooltip, Legend, ArcElement, ChartDataLabels);

const CompanyChart = ({ userData, lableData, navigate }) => {   
    console.log(userData, lableData,"userLableData")
    const [chartData, setChartData] = useState({
        labels: lableData,
        datasets: [
            {
                label: "Total Applicants",
                data: userData,
                backgroundColor: ColorsForPie,
                borderWidth: 2,
            },
        ],
    });

    useEffect(()=>{
        setChartData({
            labels: lableData,
            datasets: [
                {
                    label: "Total Applicants",
                    data: userData,
                    backgroundColor: ColorsForPie,
                    borderWidth: 2,
                },
            ],
        })
    },[userData, lableData])

    const options = {
        aspectRatio: 1.225,
        plugins: {
            title: {
                display: true,
                text: "All Jobs and Candidates",
            },
            datalabels: {
                color: '#fff', // Set text color
                font: {
                    weight: 'bold',
                    size: 14,
                },
                anchor: 'center', // Positioning of labels
                align: 'start', // Align labels with the slices
                formatter: (value) => value, // Show the value
            },
        },
    };

    return (
        <>
            {userData.length && lableData.length ? (
                <center className='bg-transparent'>
                    <Pie data={chartData} options={options} />
                </center>
            ) : (
                <p className='text-center w-full text-base'>No Data Available</p>
            )}
        </>
    );
};

export default CompanyChart;
