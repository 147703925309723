import React, { useEffect, useState } from 'react'
import { loadMoreData, Recruitmentfunnel } from '../../../Apis/getApis'
import { base_url } from '../../../utils/endpoints/endpoints';

const FunnelChart = React.memo(({funRange,funnFromDate,funnToDate,selectedJob}) => {
    const [data,setData] = useState([]);
    const [totalCount,setTotalCount] = useState(0);

     useEffect(() => {
            if (funnFromDate !== "" && funnToDate !== "") {
                
                    let url = `${base_url}/api/v1/web/users/recruitment_funnel_dashboard/?start_date=${funnFromDate}&end_date=${funnToDate}&job_id=${selectedJob}`
                    loadMoreData(url)
                        .then((res) => {
                            console.log(res, "newLoaddata")
                            setData(res?.data?.data?.recruitment_funnel)
            const totalData = res?.data?.data?.recruitment_funnel.reduce((sum, item) => sum + item.count, 0);
            setTotalCount(totalData);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                
            }
    
        }, [funnFromDate, funnToDate,selectedJob])
    
        useEffect(() => {
            if (funRange !== "") {
                let url = `${base_url}/api/v1/web/users/recruitment_funnel_dashboard/?range=${funRange}&job_id=${selectedJob}`
                loadMoreData(url)
                    .then((res) => {
                    console.log(res, "newLoaddata")
                    setData(res?.data?.data?.recruitment_funnel)
                    const totalData = res?.data?.data?.recruitment_funnel.reduce((sum, item) => sum + item.count, 0);
                    setTotalCount(totalData);
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }, [funRange])

    useEffect(()=>{
        if(selectedJob){
            Recruitmentfunnel(selectedJob)
            .then((res)=>{
                console.log(res?.data?.data?.recruitment_funnel,"funnelData")
                setData(res?.data?.data?.recruitment_funnel)
                const totalData = res?.data?.data?.recruitment_funnel.reduce((sum, item) => sum + item.count, 0);
                setTotalCount(totalData);
            })
            .catch((err)=>{
                console.log(err)
            })
        }
    },[selectedJob])



    return (
        <div className="mt-2 flex flex-col gap-y-2 text-red-500">
            
    {data.length>0 ?
    data.map(( item,index ) => {
        console.log(item,"funnelItem")
        const percentage = ((item?.count) / totalCount) * 100;
        console.log(percentage,item?.count,totalCount,"percentage")
        return(
            <div key={index.toString()} className='relative w-full'>
            <div 
                key={item?.status} 
                className="text-center m-auto mt-2 rounded-sm" 
                style={{ 
                    height: "40px", 
                    backgroundColor: "#56CDFF", 
                    width: `${percentage}%`
                }}
                >
            </div> 
            <center className='text-blue-950 flex flex-row justify-center items-center absolute inset-0 z-20 capitalize'>
                    {item?.status} - {item?.count}
                </center>
                    </div>    

        )
})
: <p>No data Found</p>
}
</div>
    )
})

export default FunnelChart;
