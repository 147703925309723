import React, { useEffect, useState } from "react";
import {
  getCandidateFresherList,
  getCandidateList,
  getFresherWithCdc,
  loadMoreData,
} from "../../../../../Apis/getApis";
import { BallTriangle } from "react-loader-spinner";
import ResponsivePagination from "react-responsive-pagination";
import { FaEye } from "react-icons/fa";
import Swal from "sweetalert2";
import { Modal } from "react-responsive-modal";

import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineBlock, MdOutlineLogout } from "react-icons/md";
import { deleteInstitute } from "../../../../../Apis/deleteApis";
import {
  updateBlockStatus,
  updateCandidateStatus,
  updateInstituteStatus,
} from "../../../../../Apis/updateApis";
import { AiOutlineClose } from "react-icons/ai";
import { SiTicktick } from "react-icons/si";
import { base_url } from "../../../../../utils/endpoints/endpoints";
import {
  deleteToast,
  errorToast,
  successToast,
} from "../../../../../utils/helperFunctions/HelperFunctions";
import useToken from "../../../../../customHook/useToken";

const PendingCandidates = () => {
  const access = useToken();
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(2);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [seafarerSearchData, setseafarerSearchData] = useState([]);
  const [fresherSearchData, setFresherSearchData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [count, setCount] = useState(1);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [seafarerCount, setSeafarerCount] = useState(0);
  const [fresherCount, setFresherCount] = useState(0);
  const [fresherData, setFresherData] = useState([]);
  const [fresherCdc,setFresherCdc] = useState([]);
  const [usersToShow, setUsersToShow] = useState("all");

  const pageChange = (e) => {
    setCurrentPage(e);
    setTableData([]);
    setCurrentPage(e);
    let url = `${base_url}/api/v1/web/users/?user_type=seafarer&status=pending&page=${e}`;
    loadMoreData(url)
      .then((res) => {
        setTableData(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });

    let url2 = `${base_url}/api/v1/web/users/?user_type=fresher&status=pending&page=${e}`;
    loadMoreData(url2)
      .then((res) => {
        setFresherData(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(()=>{
    getFresherWithCdc()
    .then((res)=>{
      console.log(res,"fresher");
      setFresherCdc(res?.data?.data?.results);
    })
    .catch((err)=>{
      console.log(err);
    })
  },[])

  const updateStatus = (item) => {
    if (!item?.cdc_number) {
      errorToast("Can Not Verify Candidate without CDC");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You are Verifing the Status of Candidate to be ACCEPTED.",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Verify it!",
    }).then((result) => {
      if (result.isConfirmed) {
        updateCandidateStatus(item?.id, { status: "accepted" },access)
          .then((res) => {
            console.log(res);
            getAllInstitutes();
            successToast("Candidate Is Verified");
          })
          .catch((err) => {
            console.log(err);
            errorToast("Something went wrong");
          });
      } else {
        setSelectedItem();
      }
    });
  };

  const showModal = (item) => {
    setSelectedUser(item);
    setVisible(true);
  };

  async function getAllInstitutes() {
    function loadData(resNext, from) {
      if (resNext == null) {
        return;
      }

      loadMoreData(resNext)
        .then((res) => {
          if (from == "fresher") {
            setFresherData((prev) => [...prev, ...res?.data?.data?.results]);
            loadData(res?.data?.data?.next, from);
          }
          if (from == "seafarer") {
            setTableData((prev) => [...prev, ...res?.data?.data?.results]);
            loadData(res?.data?.data?.next, from);
          }
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    }

    await getCandidateFresherList("pending")
      .then((res) => {
        console.log(res, "fetch one");
        setFresherCount(res?.data?.data?.count);
        setFresherData(res?.data?.data?.results);
        loadData(res?.data?.data?.next, "fresher");
      })
      .catch((error) => {
        console.error("Error fetching data from API 1:", error);
        return [];
      });

    await getCandidateList("pending")
      .then((res) => {
        console.log(res, "fetch one");
        setSeafarerCount(res?.data?.data?.count);
        setTableData(res?.data?.data?.results);
        loadData(res?.data?.data?.next, "seafarer");
      })
      .catch((error) => {
        console.error("Error fetching data from API 1:", error);
        return [];
      });
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    getAllInstitutes();
  }, []);

  // useEffect(() => {
  //     setTimeout(() => {
  //         if (search !== '') {
  //             setSearchData([]);
  //             let arr = [];
  //             if (usersToShow === "all") {
  //                 let composite = [...tableData, ...fresherData];
  //                 for (let i = 0; i < composite.length; i++) {
  //                     if (composite[i].first_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
  //                         arr.push(composite[i]);
  //                     }
  //                 }
  //             } else if (usersToShow === 'seafarer') {
  //                 for (let i = 0; i < tableData.length; i++) {
  //                     if (tableData[i].first_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
  //                         arr.push(tableData[i]);
  //                     }
  //                 }
  //             } else {
  //                 for (let i = 0; i < fresherData.length; i++) {
  //                     if (fresherData[i].first_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
  //                         arr.push(fresherData[i]);
  //                     }
  //                 }
  //             }
  //             setSearchData(arr);
  //         } else {
  //             setSearchData([])
  //         }
  //     }, 1000);

  // }, [search])

  useEffect(() => {
    let timer;
    if (search !== "") {
      timer = setTimeout(() => {
        let url = `${base_url}/api/v1/web/users/?user_type=fresher&search=${search}&status=pending`;
        let url2 = `${base_url}/api/v1/web/users/?user_type=seafarer&search=${search}&status=pending`;
        function loadData(resNext, from) {
          if (resNext == null) {
            return;
          }

          loadMoreData(resNext)
            .then((res) => {
              if (from == "fresher") {
                setFresherSearchData((prev) => [
                  ...prev,
                  ...res?.data?.data?.results,
                ]);
                loadData(res?.data?.data?.next, from);
              }
              if (from == "seafarer") {
                setseafarerSearchData((prev) => [
                  ...prev,
                  ...res?.data?.data?.results,
                ]);
                loadData(res?.data?.data?.next, from);
              }
            })
            .catch((err) => {
              console.log(err);
              return;
            });
        }

        loadMoreData(url)
          .then((res) => {
            setFresherSearchData([...res?.data?.data?.results]);
            loadData(res?.data?.data?.next);
          })
          .catch((err) => {
            console.log(err);
          });

        loadMoreData(url2)
          .then((res) => {
            setseafarerSearchData([...res?.data?.data?.results]);
            loadData(res?.data?.data?.next, "seafarer");
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    } else {
      setFresherSearchData([]);
      setseafarerSearchData([]);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You can't revert the changes",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteInstitute(id,access)
          .then((res) => {
            console.log(res);
            getAllInstitutes();
            successToast("Candidate Deleted Successfully");
          })
          .catch((err) => {
            console.log(err);
            errorToast("Something Went Wrong");
          });
      } else {
        setSelectedItem();
      }
    });
  };

  const handleReject = (selectedUser) => {
    updateInstituteStatus(selectedUser?.id, {
      status: "rejected",
      reason: rejectReason,
    }, access)
      .then((res) => {
        console.log(res);
        getAllInstitutes();
        setRejectModal(false);
        setVisible(false);
        deleteToast("Application is rejected");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateUserBlockStatus = (item) => {
    if (selectedItem && selectedItem?.id === item?.id) {
      setSelectedItem();
    } else {
      setSelectedItem(item);
      Swal.fire({
        title: "Are you sure?",
        text: item?.is_blocked_user
          ? "Do you want to Unblock User"
          : "Do you want to block User",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: item?.is_blocked_user
          ? "Yes, Unblock user!"
          : "Yes, Blocked user!",
      }).then((result) => {
        if (result.isConfirmed) {
          updateBlockStatus(item.id, access)
            .then((res) => {
              console.log(res);
              getAllInstitutes();
              successToast(
                item?.is_blocked_user
                  ? "User is Unblocked."
                  : "User is blocked."
              );
            })
            .catch((err) => {
              console.log(err);
              errorToast("Something went wrong");
            });
        } else {
          setSelectedItem();
        }
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        className="input-group position-sticky"
        style={{ marginBottom: "50px", paddingLeft: "10px" }}
      >
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="form-control position-static"
          placeholder="Search ......🔍"
          aria-label="Recipient's username"
        />
      </div>
      <div className="input-group positon-sticky d-flex flex-row">
        <div
          className={
            usersToShow === "all"
              ? "candidate-sort-button candidate-sort-button-active"
              : "candidate-sort-button"
          }
          onClick={() => setUsersToShow("all")}
        >
          All (
          {search == ""
            ? fresherCount + seafarerCount
            : seafarerSearchData.length + fresherSearchData.length}
          )
        </div>
        <div
          className={
            usersToShow === "seafarer"
              ? "candidate-sort-button candidate-sort-button-active"
              : "candidate-sort-button"
          }
          onClick={() => setUsersToShow("seafarer")}
        >
          Seafarer ({search == "" ? seafarerCount : seafarerSearchData.length})
        </div>
        <div
          className={
            usersToShow === "fresher"
              ? "candidate-sort-button candidate-sort-button-active"
              : "candidate-sort-button"
          }
          onClick={() => setUsersToShow("fresher")}
        >
          Fresher ({search == "" ? fresherCount : fresherSearchData.length})
        </div>
        <div
          className={
            usersToShow === "freshercdc"
              ? "candidate-sort-button candidate-sort-button-active"
              : "candidate-sort-button"
          }
          onClick={() => setUsersToShow("freshercdc")}
        >
          Fresher(with CDC) 
        </div>
      </div>
      {loading ? (
        <div style={{ maxWidth: "100px", maxHeight: "100px", margin: "auto" }}>
          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>
      ) : (
        <div className="table-container">
          {usersToShow === "all" && (
            <React.Fragment>
              {[...tableData, ...fresherData]?.length > 0 ? (
                <div className="table-Data">
                  <table className="table">
                    <thead>
                      <tr
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="border-b-[1px] border-b-slate-600"
                      >
                        <th style={{ fontWeight: "700", maxWidth: "80px" }}>
                          Sr No.
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          Candidate Name
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          CDC Number
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          Country
                        </th>
                        <th style={{ fontWeight: "700" }}>Status</th>
                        <th style={{ fontWeight: "700" }}>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {search === "" &&
                        [...tableData, ...fresherData]?.map((item, index) => {
                          return (
                            <tr
                              key={item.id}
                              className=""
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <th scope="row">{index + 1}</th>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.first_name}
                              </td>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.cdc_number || "NA"}
                              </td>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.country}
                              </td>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                }}
                                onClick={() => updateUserBlockStatus(item)}
                              >
                                {!item?.is_blocked_user ? (
                                  <img
                                    loading="lazy"
                                    src="/assets/commonAssets/toggleTrue.svg"
                                    style={{ cursor: "pointer" }}
                                    alt="logo"
                                  />
                                ) : (
                                  <img
                                    src="/assets/commonAssets/toggleFalse.svg"
                                    style={{ cursor: "pointer" }}
                                    alt="logo"
                                  />
                                )}
                              </td>
                              <td>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "start",
                                  }}
                                >
                                  <FaEye
                                    onClick={() => {
                                      showModal(item);
                                      setSelectedItem(item);
                                    }}
                                    title="Details"
                                    style={{
                                      width: "40px",
                                      height: "30px",
                                      color: "#0CB1C0",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopLeftRadius: "10px",
                                      borderBottomLeftRadius: "10px",
                                    }}
                                  />
                                  <RiDeleteBin6Line
                                    onClick={() => handleDelete(item?.id)}
                                    title="Delete"
                                    style={{
                                      width: "40px",
                                      height: "30px",
                                      color: "#F84F31",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopRightRadius: "10px",
                                      borderBottomRightRadius: "10px",
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      {search !== "" &&
                      [...seafarerSearchData, ...fresherSearchData].length >
                        0 ? (
                        [...seafarerSearchData, ...fresherSearchData]?.map(
                          (item, index) => {
                            return (
                              <tr
                                key={item.id}
                                className=""
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <th scope="row">{index + 1}</th>
                                <td
                                  style={{
                                    textWrap: "wrap",
                                    textAlign: "start",
                                  }}
                                >
                                  {item?.first_name}
                                </td>
                                <td
                                  style={{
                                    textWrap: "wrap",
                                    textAlign: "start",
                                  }}
                                >
                                  {item?.cdc_number || "NA"}
                                </td>
                                <td
                                  style={{
                                    textWrap: "wrap",
                                    textAlign: "start",
                                  }}
                                >
                                  {item?.country}
                                </td>
                                <td
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  onClick={() => updateUserBlockStatus(item)}
                                >
                                  {!item?.is_blocked_user ? (
                                    <img
                                      loading="lazy"
                                      src="/assets/commonAssets/toggleTrue.svg"
                                      style={{ cursor: "pointer" }}
                                      alt="logo"
                                    />
                                  ) : (
                                    <img
                                      src="/assets/commonAssets/toggleFalse.svg"
                                      style={{ cursor: "pointer" }}
                                      alt="logo"
                                    />
                                  )}
                                </td>
                                <td>
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <FaEye
                                      onClick={() => {
                                        showModal(item);
                                        setSelectedItem(item);
                                      }}
                                      title="Details"
                                      style={{
                                        width: "40px",
                                        height: "30px",
                                        color: "#0CB1C0",
                                        border: "1px solid gray",
                                        padding: "5px",
                                        borderTopLeftRadius: "10px",
                                        borderBottomLeftRadius: "10px",
                                      }}
                                    />
                                    <RiDeleteBin6Line
                                      onClick={() => handleDelete(item?.id)}
                                      title="Delete"
                                      style={{
                                        width: "40px",
                                        height: "30px",
                                        color: "#F84F31",
                                        border: "1px solid gray",
                                        padding: "5px",
                                        borderTopRightRadius: "10px",
                                        borderBottomRightRadius: "10px",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <>
                          {search !== "" && (
                            <center style={{ marginTop: "20px" }}>
                              No Match Found
                            </center>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <center>No Data found</center>
              )}
            </React.Fragment>
          )}

          {usersToShow === "seafarer" && (
            <React.Fragment>
              {tableData?.length > 0 ? (
                <div className="table-Data">
                  <table className="table">
                    <thead>
                      <tr
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <th style={{ fontWeight: "700", maxWidth: "80px" }}>
                          Sr No.
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          Candidate Name
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          CDC Number
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          Country
                        </th>
                        <th style={{ fontWeight: "700" }}>Status</th>
                        <th style={{ fontWeight: "700" }}>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {search === "" &&
                        [...tableData]?.map((item, index) => {
                          return (
                            <tr
                              key={item.id}
                              className=""
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <th scope="row">{index + 1}</th>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.first_name}
                              </td>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.cdc_number || "NA"}
                              </td>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.country}
                              </td>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                }}
                                onClick={() => updateUserBlockStatus(item)}
                              >
                                {!item?.is_blocked_user ? (
                                  <img
                                    loading="lazy"
                                    src="/assets/commonAssets/toggleTrue.svg"
                                    style={{ cursor: "pointer" }}
                                    alt="logo"
                                  />
                                ) : (
                                  <img
                                    src="/assets/commonAssets/toggleFalse.svg"
                                    style={{ cursor: "pointer" }}
                                    alt="logo"
                                  />
                                )}
                              </td>
                              <td>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "start",
                                  }}
                                >
                                  <FaEye
                                    onClick={() => {
                                      showModal(item);
                                      setSelectedItem(item);
                                    }}
                                    title="Details"
                                    style={{
                                      width: "40px",
                                      height: "30px",
                                      color: "#0CB1C0",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopLeftRadius: "10px",
                                      borderBottomLeftRadius: "10px",
                                    }}
                                  />
                                  <RiDeleteBin6Line
                                    onClick={() => handleDelete(item?.id)}
                                    title="Delete"
                                    style={{
                                      width: "40px",
                                      height: "30px",
                                      color: "#F84F31",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopRightRadius: "10px",
                                      borderBottomRightRadius: "10px",
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      {search !== "" && [...seafarerSearchData].length > 0 ? (
                        [...seafarerSearchData]?.map((item, index) => {
                          return (
                            <tr
                              key={item.id}
                              className=""
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <th scope="row">{index + 1}</th>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.first_name}
                              </td>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.cdc_number || "NA"}
                              </td>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.country}
                              </td>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                onClick={() => updateUserBlockStatus(item)}
                              >
                                {!item?.is_blocked_user ? (
                                  <img
                                    loading="lazy"
                                    src="/assets/commonAssets/toggleTrue.svg"
                                    style={{ cursor: "pointer" }}
                                    alt="logo"
                                  />
                                ) : (
                                  <img
                                    src="/assets/commonAssets/toggleFalse.svg"
                                    style={{ cursor: "pointer" }}
                                    alt="logo"
                                  />
                                )}
                              </td>
                              <td>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FaEye
                                    onClick={() => {
                                      showModal(item);
                                      setSelectedItem(item);
                                    }}
                                    title="Details"
                                    style={{
                                      width: "40px",
                                      height: "30px",
                                      color: "#0CB1C0",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopLeftRadius: "10px",
                                      borderBottomLeftRadius: "10px",
                                    }}
                                  />
                                  <RiDeleteBin6Line
                                    onClick={() => handleDelete(item?.id)}
                                    title="Delete"
                                    style={{
                                      width: "40px",
                                      height: "30px",
                                      color: "#F84F31",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopRightRadius: "10px",
                                      borderBottomRightRadius: "10px",
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <>
                          {search !== "" && (
                            <center style={{ marginTop: "20px" }}>
                              No Match Found
                            </center>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <center>No Data found</center>
              )}
            </React.Fragment>
          )}

          {(usersToShow === "fresher" || usersToShow === "freshercdc") && (
            <React.Fragment>
              {fresherData?.length > 0 ? (
                <div className="table-Data">
                  <table className="table">
                    <thead>
                      <tr
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <th
                          style={{
                            fontWeight: "700",
                            maxWidth: "50px",
                            textAlign: "start",
                          }}
                        >
                          Sr No.
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          Candidate Name
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          CDC Number
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          Country
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          Status
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {search === "" &&
                        (usersToShow==="fresher" ? [...fresherData] : [...fresherCdc])?.map((item, index) => {
                          return (
                            <tr
                              key={item.id}
                              className=""
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <th scope="row">{index + 1}</th>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.first_name}
                              </td>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.cdc_number || "NA"}
                              </td>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.country}
                              </td>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                }}
                                onClick={() => updateUserBlockStatus(item)}
                              >
                                {!item?.is_blocked_user ? (
                                  <img
                                    loading="lazy"
                                    src="/assets/commonAssets/toggleTrue.svg"
                                    style={{ cursor: "pointer" }}
                                    alt="logo"
                                  />
                                ) : (
                                  <img
                                    src="/assets/commonAssets/toggleFalse.svg"
                                    style={{ cursor: "pointer" }}
                                    alt="logo"
                                  />
                                )}
                              </td>
                              <td>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "start",
                                  }}
                                >
                                  <FaEye
                                    onClick={() => {
                                      showModal(item);
                                      setSelectedItem(item);
                                    }}
                                    title="Details"
                                    style={{
                                      width: "40px",
                                      height: "30px",
                                      color: "#0CB1C0",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopLeftRadius: "10px",
                                      borderBottomLeftRadius: "10px",
                                    }}
                                  />
                                  <RiDeleteBin6Line
                                    onClick={() => handleDelete(item?.id)}
                                    title="Delete"
                                    style={{
                                      width: "40px",
                                      height: "30px",
                                      color: "#F84F31",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopRightRadius: "10px",
                                      borderBottomRightRadius: "10px",
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      {search !== "" && fresherSearchData.length > 0 ? (
                        fresherSearchData?.map((item, index) => {
                          return (
                            <tr
                              key={item.id}
                              className=""
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <th scope="row">{index + 1}</th>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.first_name}
                              </td>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.cdc_number || "NA"}
                              </td>
                              <td
                                style={{ textWrap: "wrap", textAlign: "start" }}
                              >
                                {item?.country}
                              </td>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                onClick={() => updateUserBlockStatus(item)}
                              >
                                {!item?.is_blocked_user ? (
                                  <img
                                    loading="lazy"
                                    src="/assets/commonAssets/toggleTrue.svg"
                                    style={{ cursor: "pointer" }}
                                    alt="logo"
                                  />
                                ) : (
                                  <img
                                    src="/assets/commonAssets/toggleFalse.svg"
                                    style={{ cursor: "pointer" }}
                                    alt="logo"
                                  />
                                )}
                              </td>
                              <td>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FaEye
                                    onClick={() => {
                                      showModal(item);
                                      setSelectedItem(item);
                                    }}
                                    title="Details"
                                    style={{
                                      width: "40px",
                                      height: "30px",
                                      color: "#0CB1C0",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopLeftRadius: "10px",
                                      borderBottomLeftRadius: "10px",
                                    }}
                                  />
                                  <RiDeleteBin6Line
                                    onClick={() => handleDelete(item?.id)}
                                    title="Delete"
                                    style={{
                                      width: "40px",
                                      height: "30px",
                                      color: "#F84F31",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopRightRadius: "10px",
                                      borderBottomRightRadius: "10px",
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <>
                          {search !== "" && (
                            <center style={{ marginTop: "20px" }}>
                              No Match Found
                            </center>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <center>No Data found</center>
              )}
            </React.Fragment>
          )}
        </div>
      )}

      <Modal open={visible} onClose={() => setVisible(false)} center={true}>
        <div className="candidateDetails-body-Container">
          <div className="details-row">
            <h6 className="card-lable-heading">Name</h6>
            <p>-</p>
            <h6 className="card-lable-value">
              {selectedUser?.first_name}
              {selectedUser?.last_name && " " + selectedUser?.last_name}
            </h6>
          </div>
          <div className="details-row">
            <h6 className="card-lable-heading">CDC Number</h6>
            <p>-</p>
            <h6 className="card-lable-value">
              {selectedUser?.cdc_number || "NA"}
            </h6>
          </div>
          <div className="details-row">
            <h6 className="card-lable-heading">Country</h6>
            <p>-</p>
            <h6 className="card-lable-value">
              {selectedUser?.country || "NA"}
            </h6>
          </div>
          <div className="details-row">
            <h6 className="card-lable-heading">City</h6>
            <p>-</p>
            <h6 className="card-lable-value">{selectedUser?.city || "NA"}</h6>
          </div>
          <div className="details-row">
            <h6 className="card-lable-heading">DOB</h6>
            <p>-</p>
            <h6 className="card-lable-value">
              {selectedUser?.cdc_details
                ? selectedUser?.cdc_details[0]?.date_of_birth
                : "NA"}
            </h6>
          </div>
          <div className="details-row">
            <h6 className="card-lable-heading">Email</h6>
            <p>-</p>
            <h6 className="card-lable-value">{selectedUser?.email}</h6>
          </div>
          <div className="details-row">
            <h6 className="card-lable-heading">Mobile</h6>
            <p>-</p>
            <h6 className="card-lable-value">
              {selectedUser?.mobile_number1 || "NA"}
            </h6>
          </div>
          <div className="details-row">
            <h6 className="card-lable-heading">Gender</h6>
            <p>-</p>
            <h6 className="card-lable-value">{selectedUser?.gender || "NA"}</h6>
          </div>

          {selectedUser?.cdc_number && (
            <div className="details-row-button ">
              <button
                onClick={() => {
                  setVisible(false);
                  updateStatus(selectedItem);
                }}
                className="logOutButton"
                style={{ marginRight: "20px" }}
                data-dismiss="modal"
              >
                <SiTicktick
                  style={{ width: "30px", height: "30px", marginLeft: "20px" }}
                />
                <span>Verify</span>
              </button>
              <button
                className="logOutButton"
                onClick={() => {
                  setRejectModal(true);
                }}
                style={{ backgroundColor: "#c06c84" }}
                data-dismiss="modal"
              >
                <AiOutlineClose
                  style={{ width: "30px", height: "30px", marginLeft: "20px" }}
                />
                <span>Reject</span>
              </button>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        open={rejectModal}
        onClose={() => setRejectModal(false)}
        center={true}
      >
        <div className="candidateDetails-body-Container">
          <div className="RejectReasonCard-row">
            <center style={{ maxWidth: "100%", maxHeight: "100px" }}>
              <h3>Rejection ?</h3>
            </center>
            <textarea
              rows="3"
              placeholder="Type Rejection reason"
              size="ms"
              resize={"vertical"}
              className="form-control sailors-Input-field"
              value={rejectReason}
              style={{ height: "250px" }}
              onChange={(e) => setRejectReason(e.target.value)}
            />
          </div>

          <div className="details-row">
            <button
              type="button"
              disabled={rejectReason === "" ? true : false}
              onClick={() => {
                handleReject(selectedUser);
              }}
              className="logOutButton"
              style={{
                marginRight: "20px",
                opacity: rejectReason === "" ? 0.5 : 1,
                cursor: rejectReason === "" ? "not-allowed" : "pointer",
              }}
            >
              <MdOutlineBlock
                style={{ width: "30px", height: "30px", marginLeft: "20px" }}
              />
              <span>Reject</span>
            </button>
            <button
              onClick={() => setRejectModal(false)}
              className="logOutButton"
              style={{
                backgroundColor: "white",
                color: "#005A80",
                border: "1px solid #005A80",
              }}
              data-dismiss="modal"
            >
              <AiOutlineClose
                style={{ width: "30px", height: "30px", marginLeft: "20px" }}
              />
              <span>Close</span>
            </button>
          </div>
        </div>
      </Modal>

      {/* {tableData.length > 0 && (
        <div style={{ maxWidth: "50px", marginTop: "30px", margin: "auto" }}>
          <ResponsivePagination
            current={currentPage}
            total={Math.ceil(count / 10)}
            onPageChange={(e) => pageChange(e)}
          />
        </div>
      )} */}
    </div>
  );
};

export default PendingCandidates;
