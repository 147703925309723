import React, { Fragment, useEffect, useState } from "react";
import { FaCaretDown, FaPen } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BallTriangle } from "react-loader-spinner";
import "./courseBatch.css";
import {
  GetLearningCourses,
  getCountries,
  getFeesByCourseDetails,
  getFeesById,
  getScheduledCourses,
} from "../../../Apis/getApis";
import { saveAdminScheduleCourse } from "../../../Apis/postApis";
import Swal from "sweetalert2";
import { deleteCourseDetails } from "../../../Apis/deleteApis";
import { updateCourseData } from "../../../Apis/updateApis";
import {
  deleteToast,
  errorToast,
  successToast,
} from "../../../utils/helperFunctions/HelperFunctions";
import useToken from "../../../customHook/useToken";

const CourseBatch = () => {
  const access = useToken();
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scheduledCourseList, setScheduledCourseList] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [course, setCourse] = useState("");
  const width = window.innerWidth;
  const [feeSettting, setFeeSetting] = useState("manual");
  const [countries, setCountries] = useState([]);
  const [feesForAll, setFeesForAll] = useState(false);
  const [courseCountries, setCourseCountries] = useState([]);
  const [feesData, setFeesData] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState("free");
  const [numOfAttempt, setNumOfAttempt] = useState(0);
  const [passPercentage, setPassPercentage] = useState(0);
  const [numOfQues, setNumOfQues] = useState(0);
  const [feesDescription, setFeesDescription] = useState("");
  const [selectedItem, setSelectedItem] = useState();

  const handleCancle = (e) => {
    e.preventDefault();
    setIsEdit(false);
    setCourse("");
    setNumOfAttempt(0);

    setSelectedItem();
    setCourse("");
    setSubscriptionType("free");
    setNumOfQues(0);
    setPassPercentage(0);
  };

  const saveCourse = (e) => {
    e.preventDefault();
    if (course == "") {
      errorToast("Please Select the course");
    }

    if (!isEdit) {
      let paidObj = {
        course: course,
        payment_type: subscriptionType,
        total_questions_per_attempt: numOfQues,
        number_of_attempts: numOfAttempt,
        course_fee: courseCountries,
        pass_percentage: passPercentage,
        fee_description: feesDescription,
        countries: courseCountries,
      };
      let freeObj = {
        course: course,
        payment_type: subscriptionType,
        total_questions_per_attempt: numOfQues,
        number_of_attempts: numOfAttempt,
        pass_percentage: passPercentage,
        countries: courseCountries,
      };
      saveAdminScheduleCourse(subscriptionType == "paid" ? paidObj : freeObj,access)
        .then((res) => {
          getAllScheduledCourses();
          successToast("Course Saved SuccessFully");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      updateCourseData(selectedItem?.id, {
        course: course,
        payment_type: subscriptionType,
        total_questions_per_attempt: numOfQues,
        course_fee: courseCountries,
        number_of_attempts: numOfAttempt,
        pass_percentage: passPercentage,
        fee_description: feesDescription,
        countries: courseCountries,
      },access)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleFees = (fees, id) => {
    if (id == "auto") {
      let newDataObj = [];
      for (let i = 0; i < countries.length; i++) {
        let obj = {};
        obj["fee"] = fees;
        obj["country"] = countries[i].id;
        console.log(obj, "countryOBj");
        newDataObj.push(obj);
      }
      setCourseCountries(newDataObj);
    } else {
      let newArr = [...countries];
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i].id == id) {
          delete newArr[i].code;
          delete newArr[i].lat;
          delete newArr[i].lon;
          delete newArr[i].name;
          delete newArr[i].phone_code;
          delete newArr[i].symbol;

          newArr[i]["country"] = newArr[i].id;
          newArr[i]["fee"] = fees;
          break;
        }
      }
      setCourseCountries(newArr);
    }
  };

  const editCourse = (item) => {
    setIsEdit(true);
    setSelectedItem(item);
    setCourse(item?.course);
    setSubscriptionType(item?.payment_type);
    courseFeesData(item?.id);
    setNumOfQues(item?.total_questions_per_attempt);
    setPassPercentage(item?.pass_percentage);
    setNumOfAttempt(item?.number_of_attempts);
  };

  const deleteCourse = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You can revert Changes once you delete!",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete Course!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCourseDetails(id,access)
          .then((res) => {
            getAllScheduledCourses();
            deleteToast("Course Deleted Successfully");
          })
          .catch((err) => {
            console.log(err);
            errorToast("Please check the internet Or try after some Time");
          });
      }
    });
  };

  const getCourseById = (id) => {
    for (let i = 0; i < courseData.length; i++) {
      if (courseData[i]?.id === id) {
        return courseData[i]?.course_name;
      }
    }
    return "Learning Course";
  };

  const courseFeesData = (id) => {
    if (id) {
      getFeesById(id)
        .then((res) => {
          setCourseCountries(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function getAllLearningCourses() {
    GetLearningCourses("accepted")
      .then((res) => {
        setCourseData(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getAllScheduledCourses() {
    getScheduledCourses()
      .then((res) => {
        setScheduledCourseList(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCountries()
      .then((res) => {
        setCountries(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllLearningCourses();
    getAllScheduledCourses();
  }, []);

  useEffect(() => {
    let timer;
    if (feeSettting === "auto") {
      timer = setTimeout(() => {
        let arr = [];
      }, 1300);
    }
  }, [feeSettting]);

  useEffect(() => {
    if (passPercentage && passPercentage > 100) {
      errorToast("Pass Percentage Can not be more that 100");
      setPassPercentage(0);
    }
  }, [passPercentage]);

  useEffect(() => {
    if (feeSettting == "manual") {
      let newArr = [...courseCountries];
      for (let i = 0; i < newArr.length; i++) {
        newArr[i]["fees"] = 0;
      }
      setCourseCountries(newArr);
    }
  }, [feeSettting]);

  useEffect(() => {
    if (course && courseData.length > 0) {
      let arr = [];
      const getCourse = courseData.filter((item) => item?.id == course);
      if (getCourse[0]?.countries.length > 0) {
        for (let i = 0; i < getCourse[0].countries.length; i++) {
          let p = 0,
            q = countries.length - 1;
          while (p < q) {
            if (countries[p].id == getCourse[0].countries[i].id) {
              arr.push(countries[p]);
              break;
            }
            if (countries[q].id == getCourse[0].countries[i].id) {
              arr.push(countries[q]);
              break;
            }
            p++;
            q--;
          }
        }
      } else {
        arr = countries;
      }
      setCourseCountries(arr);
    }

    if (course) {
      getFeesByCourseDetails(course)
        .then((res) => {
          console.log(res, "feesData");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [course]);

  return (
    <div
      className="institute-container animate__animated animate__fadeIn"
      style={{ transition: "ease-in-out 250ms" }}
    >
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4>Schedule Course</h4>
        </div>
      </div>
      <div
        className="table-container"
        style={{ transition: "ease-in-out 250ms" }}
      >
        <div
          className="allDropDown allField-learning-courses position-relative"
          style={{ transition: "ease-in-out 250ms" }}
        >
          <span
            className="mustStar"
            style={{
              textAlign: "end",
              fontSize: "15px",
              position: "absolute",
              top: "-20px",
              left: "-16px",
            }}
          >
            Fields marked (*) are mandatory.
          </span>

          <span
            className="add-course-container add-course-country-container"
            style={{ maxWidth: "100%", padding: "20px" }}
          >
            <p style={{ maxWidth: "231px" }}>
              Learning Course Name<span className="mustStar">*</span>
            </p>
            <div
              className="dropdown dropDown-locality dropDown-subLearning-Courses"
              style={{ maxWidth: "928px", marginRight: "-22px" }}
            >
              <button
                type="button"
                className={
                  width < 500
                    ? "btn drdpbtn form-control sailors-Input-field col"
                    : "btn form-control drdpbtn sailors-Input-field row"
                }
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ backgroundColor: "white", maxWidth: "100%" }}
              >
                {getCourseById(course)}
                <FaCaretDown style={{ width: "15px" }} />
              </button>
              <div
                className="dropdown-menu"
                style={{
                  boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)",
                  width: "90%",
                  height: "200px",
                  overflowY: "scroll",
                }}
              >
                {courseData.length > 0 ? (
                  courseData?.map((item, index) => {
                    return (
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          setCourse(item.id);
                        }}
                        style={{ height: "50px", cursor: "pointer" }}
                      >
                        {getCourseById(item?.id)}
                      </div>
                    );
                  })
                ) : (
                  <center style={{ marginTop: "10%" }}>
                    !Opps check the Internet
                  </center>
                )}
              </div>
            </div>
          </span>

          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Subscription Type<span className='mustStar'>*</span></p>
            
            <div className='subCheckDivMain'>
              <div className='subCheckDiv'>
                <label>Free</label>
                <input type='radio' name='subscription' value='free' onChange={()=>setSubscriptionType('free')} checked={subscriptionType==='free'?true:false}/>
              </div>
              <div className='subCheckDiv'>
                <label>Paid</label>
                <input type="radio" name='subscription' value='paid' checked={subscriptionType==='paid'?true:false} onChange={()=>setSubscriptionType('paid')}/>
              </div>
            </div>
          </span> */}

          {subscriptionType === "paid" && (
            <span
              className="d-flex add-course-container justify-content-center"
              style={{
                maxWidth: "100%",
                padding: "10px",
                transition: "ease-in-out 700ms",
              }}
            >
              <p style={{ maxWidth: "231px" }}>Fees Setting</p>

              <div className="subCheckDivMain">
                <div className="feeCheckDiv">
                  <input
                    name="fees"
                    type="radio"
                    value={"manual"}
                    onChange={(e) => setFeeSetting("manual")}
                    checked={feeSettting === "manual"}
                  />
                  <label style={{ textAlign: "start" }}>
                    Set Fees Manually
                  </label>
                </div>
                <div className="feeCheckDiv">
                  <input
                    name="fees"
                    type="radio"
                    value={"auto"}
                    onChange={(e) => setFeeSetting("auto")}
                    checked={feeSettting === "auto"}
                  />
                  <label style={{ textAlign: "start" }}>
                    Set One fees to all
                  </label>
                </div>
              </div>
            </span>
          )}

          {subscriptionType === "paid" && feeSettting == "auto" && (
            <span
              className="d-flex add-course-container animate__animated animate__fadeInDown justify-content-center"
              style={{ maxWidth: "100%", padding: "10px" }}
            >
              <p style={{ maxWidth: "231px" }}>
                Enter for All Fees<span className="mustStar">*</span>
              </p>

              <input
                type="number"
                onChange={(e) => {
                  handleFees(e.target.value, "auto");
                }}
                className="form-control sailors-Input-field"
                style={{ maxWidth: "1024px" }}
              />
            </span>
          )}

          {subscriptionType === "paid" && (
            <span
              className="d-flex add-course-container animate__animated animate__fadeInDown justify-content-center"
              style={{ maxWidth: "100%", padding: "10px" }}
            >
              <p style={{ maxWidth: "231px" }}>
                Enter Fees Description<span className="mustStar">*</span>
              </p>

              <input
                type="text"
                value={feesDescription}
                onChange={(e) => setFeesDescription(e.target.value)}
                className="form-control sailors-Input-field"
                style={{ maxWidth: "1024px" }}
              />
            </span>
          )}

          {subscriptionType == "paid" && feeSettting == "manual" && (
            <span
              className="d-flex add-course-container justify-content-center"
              style={{
                maxWidth: "100%",
                padding: "10px",
                transition: "ease-in-out 700ms",
              }}
            >
              <div className="table-container" style={{ marginTop: "10px" }}>
                <div
                  className="table-Data  animate__animated animate__fadeIn"
                  style={{ overflow: !course && "hidden" }}
                >
                  {course ? (
                    <table className="table">
                      <thead>
                        <tr
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <th
                            scope="col"
                            style={{ fontWeight: "700", maxWidth: "150px" }}
                          >
                            Sr No.
                          </th>
                          <th
                            scope="col"
                            style={{ fontWeight: "700", textAlign: "start" }}
                          >
                            Country Name
                          </th>
                          <th
                            scope="col"
                            style={{ fontWeight: "700", textAlign: "start" }}
                          >
                            Fees Amount
                          </th>
                          <th
                            scope="col"
                            style={{ fontWeight: "700", textAlign: "center" }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>

                      <tbody
                        className="animate_animated animate__fadeIn"
                        style={{
                          maxHeight: "400px",
                          height: course ? "500px" : "50px",
                          overflowY: "scroll",
                        }}
                      >
                        {courseCountries.length > 0 ? (
                          courseCountries
                            .filter((item) => item.name !== "")
                            .map((item, index) => {
                              return (
                                <tr
                                  className=""
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <th scope="row" style={{ maxWidth: "150px" }}>
                                    {index + 1}
                                  </th>
                                  <td
                                    style={{
                                      textWrap: "wrap",
                                      textAlign: "start",
                                    }}
                                  >
                                    {item.name}
                                  </td>
                                  <td
                                    style={{
                                      textWrap: "wrap",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item?.currency_code}
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={item?.fee || ""}
                                      onChange={(e) =>
                                        handleFees(e.target.value, item?.id)
                                      }
                                      style={{ marginLeft: "4px" }}
                                    />
                                  </td>

                                  <td>
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <FaPen
                                        onClick={() => editCourse(item)}
                                        title="Edit"
                                        style={{
                                          width: "40px",
                                          height: "30px",
                                          color: "#0CB1C0",
                                          border: "1px solid gray",
                                          padding: "5px",
                                          borderTopLeftRadius: "10px",
                                          borderBottomLeftRadius: "10px",
                                        }}
                                      />
                                      <RiDeleteBin6Line
                                        onClick={() => deleteCourse(item.id)}
                                        title="Delete"
                                        style={{
                                          width: "40px",
                                          height: "30px",
                                          color: "#F84F31",
                                          border: "1px solid gray",
                                          padding: "5px",
                                          borderTopRightRadius: "10px",
                                          borderBottomRightRadius: "10px",
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <center style={{ marginLeft: "50%" }}>
                            Please Select the Course
                          </center>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <center>Please Select the course</center>
                  )}
                </div>
              </div>
            </span>
          )}

          <span
            className="d-flex add-course-container justify-content-center"
            style={{ maxWidth: "100%", padding: "10px" }}
          >
            <p style={{ maxWidth: "231px" }}>
              Number of Questions<span className="mustStar">*</span>
            </p>

            <input
              type="text"
              className="form-control sailors-Input-field"
              value={numOfQues}
              onChange={(e) => setNumOfQues(e.target.value)}
              style={{ maxWidth: "1024px" }}
            />
          </span>

          <span
            className="d-flex add-course-container justify-content-center"
            style={{ maxWidth: "100%", padding: "10px" }}
          >
            <p style={{ maxWidth: "231px" }}>
              Pass Percentage(%)<span className="mustStar">*</span>
            </p>

            <input
              type="text"
              className="form-control sailors-Input-field"
              value={passPercentage}
              onChange={(e) => setPassPercentage(e.target.value)}
              style={{ maxWidth: "1024px" }}
            />
          </span>

          <span
            className="d-flex add-course-container justify-content-center"
            style={{ maxWidth: "100%", padding: "10px" }}
          >
            <p style={{ maxWidth: "231px" }}>
              Number of Attempts<span className="mustStar">*</span>
            </p>

            <input
              type="text"
              value={numOfAttempt}
              onChange={(e) => setNumOfAttempt(e.target.value)}
              className="form-control sailors-Input-field"
              style={{ maxWidth: "1024px" }}
            />
          </span>
        </div>
        <div className="form-btns" style={{ marginBottom: "10px" }}>
          <button type="submit" onClick={saveCourse} className="btn">
            {isEdit ? "Update" : "Save"}
          </button>
          <button
            type="button"
            onClick={(e) => handleCancle(e)}
            className="btn btn-OnboardForm"
            style={{ border: "1px solid #005A80" }}
          >
            Cancel
          </button>
        </div>
      </div>
      <div
        className="instituteOnboarding-list-view"
        style={{ marginTop: "20px" }}
      >
        <div className="headerPart">
          <h4>All Scheduled Courses</h4>
        </div>
      </div>

      {loading ? (
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>
          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>
      ) : (
        <div className="table-container">
          <div className="table-Data">
            <table className="table">
              <thead>
                <tr style={{ display: "flex", justifyContent: "flex-start" }}>
                  <th style={{ fontWeight: "700", maxWidth: "50px" }}>
                    Sr No.
                  </th>
                  <th style={{ fontWeight: "700", textAlign: "center" }}>
                    course Name
                  </th>
                  <th style={{ fontWeight: "700", textAlign: "center" }}>
                    Subscription type
                  </th>
                  <th style={{ fontWeight: "700" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {scheduledCourseList.length > 0 ? (
                  scheduledCourseList.map((item, index) => {
                    return (
                      <tr
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <th scope="row">{index + 1}</th>
                        <td style={{ textWrap: "wrap", textAlign: "center" }}>
                          {getCourseById(item?.course)}
                        </td>
                        <td style={{ textWrap: "wrap", textAlign: "center" }}>
                          {item?.payment_type}
                        </td>

                        <td>
                          <div
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <FaPen
                              onClick={() => editCourse(item)}
                              title="Edit"
                              style={{
                                width: "40px",
                                height: "30px",
                                color: "#0CB1C0",
                                border: "1px solid gray",
                                padding: "5px",
                                borderTopLeftRadius: "10px",
                                borderBottomLeftRadius: "10px",
                              }}
                            />
                            <RiDeleteBin6Line
                              onClick={() => deleteCourse(item.id)}
                              title="Delete"
                              style={{
                                width: "40px",
                                height: "30px",
                                color: "#F84F31",
                                border: "1px solid gray",
                                padding: "5px",
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <center>No data found</center>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseBatch;
