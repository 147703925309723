import React, { useEffect, useState } from "react";
import { FaDownload, FaEye, FaPen, FaRegFilePdf } from "react-icons/fa";
import "./CandidateResume.css";
import { Modal } from "react-responsive-modal";
import { getAppliedUserData, getResumeData } from "../../../Apis/getApis";
import { jobApplicantStatusChange } from "../../../Apis/updateApis";
import { toast } from "react-toastify";
import { PDFReader } from "reactjs-pdf-reader";
import { errorToast } from "../../../utils/helperFunctions/HelperFunctions";
import useToken from "../../../customHook/useToken";

const CandidateResume = () => {
  const access = useToken();
  const [resumeData, setResumeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataType, setDataType] = useState("pending");
  const [selectedItem, setSelectedItem] = useState();
  const [visible, setVisible] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [resume, setResume] = useState("");
  const [open, setOpen] = useState(false);
  const getDate = (data) => {
    let newdDate = new Date(data);
    let date = newdDate.getDate();
    let month = newdDate.getMonth();
    let year = newdDate.getFullYear();
    return [date, month, year];
  };

  const statuses = [
    { label: "New", value: "pending" },
    { label: "In Review", value: "viewed" },
    { label: "Shortlisted", value: "shortlisted" },
    { label: "Hired", value: "hired" },
    { label: "Joined", value: "joined" },
    { label: "Rejected", value: "rejected" },
  ];

  function viewpdf(item) {
    if (
      item?.resume == null ||
      item?.resume == "" ||
      item?.resume == undefined
    ) {
      toast.error("Resume Not Availble at Candidate");
      return;
    } else {
      setResume(item?.resume);
      setShowPdf(true);
      if (dataType == "pending") {
        changeStatus(item?.id, { status: "viewed" });
      }
    }
  }

  function Increment() {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => (prev += 1));
    }
  }
  function Decrement() {
    if (currentPage > 1) {
      setCurrentPage((prev) => (prev -= 1));
    }
  }

  async function downloadPDF(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    const fileName = url.split("/").pop();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  const toastSuccess = (data) => toast.success(data);

  const showDetail = (data, pageType) => {
    setSelectedItem(data);
    setVisible(true);
  };

  const changeStatus = (id, body) => {
    jobApplicantStatusChange(id, body,access)
      .then((res) => {
        if (body?.status === "shortlisted") {
          toastSuccess("Applicant ShortListed");
        } else if (body?.status === "rejected") {
          errorToast("Applicant Rejected");
        } else if (body?.status === "viewed") {
          toastSuccess("Applicant Viewed");
        }
        getData(dataType);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSingleUserData = (id) => {
    getAppliedUserData(id)
      .then((res) => {
        setSelectedItem(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getData(type) {
    setDataType(type);
    setLoading(true);
    getResumeData(type)
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setResumeData(res?.data?.data?.results);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        console.log(err);
      });
  }

  useEffect(() => {
    getData("pending");
  }, []);

  return (
    <div className="institute-container animate__animated animate__fadeIn">
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4>Applicant Resume</h4>
        </div>
      </div>
      <div className="table-container py-2">
        <div className="w-full flex flex-row justify-center gap-x-3 px-2">
          {statuses.map(({ label, value }) => (
            <div key={value} className="flex flex-row gap-x-2 items-center">
              <input
                type="radio"
                name="resumeType"
                checked={dataType === value}
                onClick={() => getData(value)}
                className="size-4"
              />
              <p className="mb-0">{label}</p>
            </div>
          ))}
        </div>
        {loading ? (
          <div
            style={{
              maxWidth: "100%",
              maxHeight: "180px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            {/* <BallTriangle
                            height={5}
                            width={5}
                            radius={5}
                            color="red"
                            ariaLabel="ball-triangle-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        /> */}
            <img
              src="/assets/commonAssets/captainAnimation.gif"
              style={{
                width: "150px",
                margin: "15px",
                height: "100px",
                borderRadius: "40%",
              }}
              alt=""
            />
            <center style={{ fontSize: "25px" }}>Loading...</center>
          </div>
        ) : (
          <div className="table-container" style={{ boxShadow: "none" }}>
            {resumeData?.length > 0 ? (
              <div className="table-Data">
                <table className="table">
                  <thead>
                    <tr
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <th
                        style={{
                          fontWeight: "700",
                          textAlign: "start",
                          minWidth: "calc(100%/8)",
                          color: "#005A80",
                        }}
                        scope="col"
                      >
                        Sr No.
                      </th>
                      <th
                        style={{
                          fontWeight: "700",
                          textAlign: "start",
                          color: "#005A80",
                          minWidth: "calc(100%/8)",
                        }}
                        scope="col"
                      >
                        Candidate Name
                      </th>
                      <th
                        style={{
                          fontWeight: "700",
                          textAlign: "start",
                          color: "#005A80",
                          minWidth: "calc(100%/8)",
                        }}
                        scope="col"
                      >
                        Country
                      </th>
                      <th
                        style={{
                          fontWeight: "700",
                          textAlign: "start",
                          color: "#005A80",
                          minWidth: "calc(100%/8)",
                        }}
                        scope="col"
                      >
                        Curent Company
                      </th>
                      <th
                        style={{
                          fontWeight: "700",
                          textAlign: "start",
                          color: "#005A80",
                          minWidth: "calc(100%/8)",
                        }}
                        scope="col"
                      >
                        Current rank
                      </th>
                      <th
                        style={{
                          fontWeight: "700",
                          textAlign: "start",
                          color: "#005A80",
                          minWidth: "calc(100%/8)",
                        }}
                        scope="col"
                      >
                        Applied Rank/Date{" "}
                      </th>
                      <th
                        style={{
                          fontWeight: "700",
                          textAlign: "start",
                          color: "#005A80",
                          minWidth: "calc(100%/8)",
                        }}
                        scope="col"
                      >
                        Actions
                      </th>
                      <th
                        style={{
                          fontWeight: "700",
                          textAlign: "start",
                          color: "#005A80",
                          minWidth: "calc(100%/8)",
                        }}
                        scope="col"
                      >
                        Resume
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {resumeData.map((item, index) => {
                      return (
                        <tr
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            borderBottom: "1px solid #00000099",
                          }}
                        >
                          <th style={{ minWidth: "calc(100%/8)" }} scope="row">
                            {index + 1}
                          </th>
                          <td style={{ minWidth: "calc(100%/8)" }}>
                            {item.candidate_name}
                          </td>
                          <td style={{ minWidth: "calc(100%/8)" }}>
                            {item?.country || "NA"}
                          </td>
                          <td style={{ minWidth: "calc(100%/8)" }}>
                            {item?.recent_experience?.company || "NA"}
                          </td>
                          <td style={{ minWidth: "calc(100%/8)" }}>
                            {item?.job_role}
                          </td>
                          <td style={{ minWidth: "calc(100%/8)" }}>
                            {item.applied_job_role}
                            {"\n"}
                            {getDate(item.application_date)[0]}/
                            {getDate(item.application_date)[1]}/
                            {getDate(item.application_date)[2]}
                          </td>
                          <td style={{ minWidth: "calc(100%/8)" }}>
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                              }}
                            >
                              <FaEye
                                data-toggle="modal"
                                onClick={() => {
                                  showDetail(item, dataType);
                                }}
                                data-target="#instituteDetailsModal"
                                title="Details"
                                style={{
                                  width: "30px",
                                  color: "#0CB1C0",
                                  border: "1px solid gray",
                                  padding: "5px",
                                  borderRadius: "10px",
                                }}
                              />
                            </div>
                          </td>
                          {/* viewResume(item,dataType) */}
                          <td style={{ minWidth: "calc(100%/8)" }}>
                            <div
                              onClick={() => {
                                viewpdf(item);
                              }}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                color: "#005A80",
                              }}
                            >
                              Resume
                              <FaRegFilePdf
                                style={{
                                  width: "30px",
                                  color: "#FA1B0C",
                                  padding: "5px",
                                }}
                                data-title="View Resume"
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <center>No Data found</center>
            )}
          </div>
        )}
      </div>

      <Modal open={visible} onClose={() => setVisible(false)} center={true}>
        <div
          className="candidateDetails-body-Container m-auto"
          style={{ minWidth: "450px" }}
        >
          <center className="mb-4">
            <h4 className="m-auto">Candidate Details</h4>
          </center>
          <div
            className="details-row"
            style={{ borderBottom: "1px solid #2A737A66" }}
          >
            <h6 className="card-title">Candidate Name</h6>
            <p>-</p>
            <h6>{selectedItem?.candidate_name}</h6>
          </div>
          <div
            className="details-row mt-2"
            style={{ borderBottom: "1px solid #2A737A66" }}
          >
            <h6 className="card-title">Country</h6>
            <p>-</p>
            <h6>{selectedItem?.country}</h6>
          </div>
          <div
            className="details-row mt-2"
            style={{ borderBottom: "1px solid #2A737A66" }}
          >
            <h6 className="card-title">Email</h6>
            <p>-</p>
            <h6>{selectedItem?.email}</h6>
          </div>
          <div
            className="details-row mt-2"
            style={{ borderBottom: "1px solid #2A737A66" }}
          >
            <h6 className="card-title">Contact Number</h6>
            <p>-</p>
            <h6>{selectedItem?.mobile_number || "NA"}</h6>
          </div>
          <div
            className="details-row mt-2"
            style={{ borderBottom: "1px solid #2A737A66" }}
          >
            <h6 className="card-title">Current Company</h6>
            <p>-</p>
            <h6>{selectedItem?.recent_experience?.company}</h6>
          </div>
          <div
            className="details-row mt-2"
            style={{ borderBottom: "1px solid #2A737A66" }}
          >
            <h6 className="card-title">Current Ship Type</h6>
            <p>-</p>
            <h6>{selectedItem?.recent_experience?.ship_type}</h6>
          </div>
          <div
            className="details-row mt-2"
            style={{ borderBottom: "1px solid #2A737A66" }}
          >
            <h6 className="card-title">Date of Joining(Current Company)</h6>
            <p>-</p>
            <h6>{selectedItem?.recent_experience?.sign_on_date}</h6>
          </div>
          <div
            className="details-row mt-2"
            style={{ borderBottom: "1px solid #2A737A66" }}
          >
            <h6 className="card-title">Exp. In Current Company</h6>
            <p>-</p>
            <h6>{selectedItem?.recent_experience?.duration}</h6>
          </div>
          <div className="details-row"></div>

          {dataType === "pending" && (
            <div className="details-row">
              <button
                class="button-13"
                onClick={() =>
                  changeStatus(selectedItem?.id, { status: "shortlisted" })
                }
              >
                Shortlist
              </button>
              <button
                class="button-13 reject"
                onClick={() =>
                  changeStatus(selectedItem?.id, { status: "rejected" })
                }
                style={{ color: "white" }}
              >
                Reject
              </button>
            </div>
          )}
          {dataType === "viewed" && (
            <div className="details-row">
              <button
                class="button-13"
                onClick={() =>
                  changeStatus(selectedItem?.id, { status: "shortlisted" })
                }
              >
                Shortlist
              </button>
              <button
                class="button-13 reject"
                onClick={() =>
                  changeStatus(selectedItem?.id, { status: "rejected" })
                }
                style={{ color: "white" }}
              >
                Reject
              </button>
            </div>
          )}
          {dataType === "shortlisted" && (
            <div className="details-row">
              <button
                class="button-13"
                onClick={() =>
                  changeStatus(selectedItem?.id, { status: "hired" })
                }
              >
                Hire
              </button>
              <button
                class="button-13 reject"
                onClick={() =>
                  changeStatus(selectedItem?.id, { status: "rejected" })
                }
                style={{ color: "white" }}
              >
                Reject
              </button>
            </div>
          )}
          {dataType === "hired" && (
            <div className="details-row">
              <button
                class="button-13"
                onClick={() =>
                  changeStatus(selectedItem?.id, { status: "joined" })
                }
              >
                Joined
              </button>
              <button
                class="button-13 reject"
                onClick={() =>
                  changeStatus(selectedItem?.id, { status: "rejected" })
                }
                style={{ color: "white" }}
              >
                Reject
              </button>
            </div>
          )}
        </div>
      </Modal>

      <Modal open={showPdf} onClose={() => setShowPdf(false)} center={true}>
        <div className="position-relative w-full">
          <center className="one">
            <h1>Candidate Resume</h1>
          </center>
          <button
            className="w-auto px-2 flex flex-row gap-x-2"
            style={{
              background: "#005A80",
              color: "white",
              padding: "2px",
              marginBottom: "2px",
            }}
            onClick={() => downloadPDF(resume)}
          >
            DownLoad PDF{" "}
            <FaDownload style={{ width: "15px", color: "white" }} />
          </button>
          <PDFReader
            scale="1.3"
            isShowHeader="false"
            isShowFooter="false"
            page={currentPage}
            onDocumentComplete={(pages) => setTotalPages(pages)}
            url={resume}
          />

          <center
            className="w-25 me-2 d-flex flex-row justify-content-around align-items-center m-auto mt-2"
            style={{
              position: "sticky",
              bottom: "0",
              maxHeight: "20px",
              left: "-50",
            }}
          >
            <button
              onClick={Decrement}
              style={{ width: "20px", background: "#005A80", color: "white" }}
            >
              {"<"}
            </button>
            <div>
              {currentPage}/{totalPages}
            </div>
            <button
              onClick={Increment}
              style={{ width: "20px", background: "#005A80", color: "white" }}
            >
              {">"}
            </button>
          </center>
        </div>
      </Modal>
    </div>
  );
};

export default CandidateResume;
