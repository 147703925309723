
import React, { useEffect, useState } from 'react';
import { FaCaretDown, FaPen } from 'react-icons/fa';
import { getDocumentsExpiry, loadMoreData } from '../../../../Apis/getApis';
import { saveCertificateExpiry } from "../../../../Apis/postApis";
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BallTriangle } from 'react-loader-spinner';
import { updateExpiryCertificate } from '../../../../Apis/updateApis';
import { deleteCertificateExpiry } from '../../../../Apis/deleteApis';
import Swal from 'sweetalert2';
import { certificatesForExpiry } from '../../../../utils/variables/folderStructureFiles';
import { errorToast, successToast } from '../../../../utils/helperFunctions/HelperFunctions';
import useToken from '../../../../customHook/useToken';
const Certificates = () => {
    const access = useToken()
    const [width, setWidth] = useState(window.innerWidth);
    const [certificate, setCertificate] = useState('Select Certificate');
    const [certificateId, setCertificateId] = useState();
    const [expiryValue, setExpiryValue] = useState('Select Expiry');
    const [expiryUnit, setExpiryUnit] = useState('');
    const [loading, setLoading] = useState(false);
    const [certificateExpiryList, setCertificateExpiryList] = useState([]);
    const [selectedItem, setSelectedItem] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const handleCancel = () => {
        setIsEdit(false);
        setCertificateId('');
        setCertificate('Select Certificate');
        setExpiryValue("Select Expiry");
        setExpiryUnit('');
    }


    const getCertificateById = (id) => {
        for (let i = 0; i < certificatesForExpiry.length; i++) {
            if (certificatesForExpiry[i].id === id) {
                return certificatesForExpiry[i].name
            }
        }
    }




    const getAllCreatedCertificates = async () => {
        async function loadData(resNext) {
            if (resNext == null) {
                setLoading(false);
                return;
            }

            await loadMoreData(resNext)
                .then((res) => {
                    console.log(res)
                    setCertificateExpiryList(prev => [...prev, ...res?.data?.data?.results])
                    loadData(res?.data?.data?.next)
                })
                .catch((err) => {
                    console.log(err)
                })
        }


        await getDocumentsExpiry()
            .then((res) => {
                console.log(res)
                setCertificateExpiryList(res?.data?.data?.results)
                loadData(res?.data?.data?.next);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        setLoading(true);
        getAllCreatedCertificates();
    }, [])



    const saveCertificateExpiryfunc = () => {
        if (certificate === "Select Certificate") {
            errorToast("Select Document please");
            return;
        }
        if (expiryValue === "Select Expiry") {
            errorToast("Expiry value must be given");
            return;
        }
        if (expiryUnit === "") {
            errorToast("Please select the expiry unit (Month, Year, Days)");
            return;
        }

        if (!isEdit) {
            saveCertificateExpiry(
                {
                    document: certificateId,
                    duration: {
                        value: expiryValue,
                        unit: expiryUnit
                    },
                }, access
            )
                .then((res) => {
                    getAllCreatedCertificates();
                    handleCancel();
                    successToast("Document Expity Saved Successfully");
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            updateExpiryCertificate(selectedItem?.id,
                {
                    document: certificateId,
                    duration: {
                        value: expiryValue,
                        unit: expiryUnit
                    },
                }, access)
                .then((res) => {
                    getAllCreatedCertificates();
                    handleCancel();
                    successToast("Document Expity Updated Successfully");
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const editCertificate = (item) => {
        setSelectedItem(item);
        setIsEdit(true);
        setCertificate(item?.document);
        console.log("cameHere1")
        setCertificateId(item?.document);
        console.log("cameHere2")
        setExpiryValue(item?.duration?.value);
        setExpiryUnit(item?.duration?.unit);
    }
    const deleteCertificate = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You can revert Changes once you delete!",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete Document!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCertificateExpiry(id,access)
                    .then((res) => {
                        getAllCreatedCertificates();
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                successToast("Deleted Document");
            }
        });
    }



    return (
        <div className='institute-container animate__animated animate__fadeIn'>
            <div className='instituteOnboarding-list-view'>
                <div className='headerPart'>
                    <h4>Document expiry</h4>
                </div>
            </div>
            <div className='table-container position-relative'>
                <span className='mustStar' style={{ textAlign: 'end', fontSize: "15px", position: 'absolute', top: "-20px", left: "-16px" }}>Fields marked (*) are mandatory.</span>
                <div className='allDropDown allField-learning-courses'>
                    {/* <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "0px 20px" }}>
                        <p style={{ maxWidth: "231px" }}>Select Country<span className='mustStar'>*</span></p>
                        
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {getCountyById(country)}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
                                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                                    <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                                        <input className='position-static form-control' type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search ......" aria-label="Recipient's username" />
                                    </div>

                                </div>
                                {search == '' && <React.Fragment>{countryList.length > 0 ? countryList?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={()=>setCountry(item.id)} style={{ height: "50px", cursor: "pointer" }} >{item?.name}</div>
                                    )
                                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}</React.Fragment>}

                                {search !== '' && <>{searchData.length > 0 ? searchData.reverse()?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={()=>setCountry(item.id)} style={{ height: "50px", cursor: "pointer" }} >{item.name}</div>
                                    )
                                }) : <center style={{ marginTop: "10%" }}>No Match</center>}</>}

                            </div>
                        </div>
                    </span> */}

                    {/* <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "0px 20px" }}>
                        <p style={{ maxWidth: "231px" }}>Select Rank<span className='mustStar'>*</span></p>
                        
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {rank}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
                                
                               {rankList.length > 0 ? rankList?.map((item) => {
                                    return (
                                        <div className="dropdown-item" onClick={()=>{setRank(item.name);setRankId(item.id)}} style={{ height: "50px", cursor: "pointer" }} >{item?.name}</div>
                                    )
                                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}

                            </div>
                        </div>
                    </span> */}

                    <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "0px 20px" }}>
                        <p style={{ maxWidth: "231px" }}>Select Document<span className='mustStar'>*</span></p>

                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {certificate.toUpperCase()}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>

                                {certificatesForExpiry?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => { setCertificate(item.name); setCertificateId(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{item?.name}</div>
                                    )
                                })}

                            </div>
                        </div>
                    </span>

                    {/* <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "0px 20px" }}>
                        <p style={{ maxWidth: "231px" }}>Select Validity<span className='mustStar'>*</span></p>
                        
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {validityValue}{" "+validityUnit.toUpperCase()}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
                                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                                    <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                                        <input className='position-static form-control' type="number" value={validityValue} onChange={(e) => setValidityValue(e.target.value)} placeholder="value..." aria-label="Recipient's username" />
                                    </div>

                                </div>
                                { ["months", "years", "weeks", "days" ]?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={()=>setValidityUnit(item)} style={{ height: "50px", cursor: "pointer" }} >{item.toUpperCase()}</div>
                                    )
                                })}

                            </div>
                        </div>
                    </span> */}

                    <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "0px 20px" }}>
                        <p style={{ maxWidth: "231px" }}>Select Expiry<span className='mustStar'>*</span></p>

                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {expiryValue}{" " + expiryUnit.toUpperCase()}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
                                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                                    <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                                        <input className='position-static form-control' type="number" value={expiryValue} onChange={(e) => setExpiryValue(e.target.value)} placeholder="value..." />
                                    </div>

                                </div>
                                {["months", "years", "weeks", "days"]?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => setExpiryUnit(item)} style={{ height: "50px", cursor: "pointer" }} >{item.toUpperCase()}</div>
                                    )
                                })}

                            </div>
                        </div>
                    </span>

                    {/* <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "0px 20px" }}>
                        <p style={{ maxWidth: "231px" }}>Expiry Alert</p>
                        
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {expiryValue}{" "+expiryUnit}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
                                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                                    <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                                        <input className='position-static form-control' type="number" value={expiryValue} onChange={(e) => setExpiryValue(e.target.value)} placeholder="value..." aria-label="Recipient's username" />
                                    </div>

                                </div>
                                { ["months", "years", "weeks", "days" ]?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={()=>setExpiryUnit(item)} style={{ height: "50px", cursor: "pointer" }} >{item}</div>
                                    )
                                })}

                            </div>
                        </div>
                    </span> */}

                    {/* 
                    <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}> Expiry Message</p>
                        <Textarea
                            placeholder='Enter expiry Message'
                            size='sm'
                            resize={'vertical'}
                            className='form-control sailors-Input-field'
                            value={expiryAlertMessage}
                            onChange={(e) => setExpiryAlertMessage(e.target.value)}
                        />
                    </span> */}


                </div>
                <div className='form-btns' style={{ marginBottom: "10px" }}>
                    <button type="submit" onClick={saveCertificateExpiryfunc} className="btn">{isEdit ? "Update" : "Save"}</button>
                    <button className="btn btn-OnboardForm" onClick={handleCancel}>Cancel</button>
                </div>
            </div>
            <div className='instituteOnboarding-list-view' style={{ marginTop: "20px" }}>
                <div className='headerPart'>
                    <h4>Certificate Expiry List</h4>
                </div>
            </div>
            {loading ?
                <div style={{ maxWidth: "100px", maxHeight: "100px" }}>

                    <BallTriangle
                        height={20}
                        width={20}
                        radius={5}
                        color="red"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                    <span>Loading...</span>
                </div>

                : <div className='table-container'>

                    {certificateExpiryList?.length > 0 ?
                        <div className='table-Data'>
                            <table className='table'>
                                <thead>
                                    <tr style={{ display: 'flex', justifyContent: "flex-start" }}>
                                        <th style={{ fontWeight: "700", maxWidth: "50px" }}>Sr No.</th>
                                        <th style={{ fontWeight: "700", textAlign: 'center' }}>Document Name</th>
                                        <th style={{ fontWeight: "700", textAlign: 'center' }}>Date</th>
                                        <th style={{ fontWeight: "700" }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {certificateExpiryList.map((item, index) => {
                                        return (
                                            <tr className='' style={{ display: 'flex', justifyContent: "space-around" }}>
                                                <th scope="row">{index + 1}</th>
                                                <td style={{ textWrap: 'wrap', textAlign: 'center' }}>{item?.document?.toUpperCase()}</td>
                                                <td style={{ textWrap: 'wrap', textAlign: 'center' }}>{item?.duration?.value + " " + item?.duration?.unit}</td>
                                                <td>
                                                    <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                                                        <FaPen onClick={() => editCertificate(item)} title='Details' style={{ width: "40px", height: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /><RiDeleteBin6Line onClick={() => deleteCertificate(item.id)} title='Delete' style={{ width: "40px", height: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        :
                        <center>No Data found</center>}
                </div>}


        </div>
    )
}

export default Certificates;
