import React from 'react';
import './App.css';
import AppRoutes from './Routes/Routes';
import ThemeProvider from './theme';
import { ToastContainer } from 'react-toastify';
// import "./css/bootstrap-reboot.min.css"
// import "./css/bootstrap.min.css"
function App() {
  return (
    <div className="App h-auto ">
      <ThemeProvider>
       <AppRoutes/>
      </ThemeProvider>
      <ToastContainer position='top-center' style={{
        maxHeight: "50px"
      }} />
    </div>
  );
}

export default App;
