import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import "./sideBar.css";
import { devices } from "../../utils/variables/folderStructureFiles";
import {
  sidebarDataAdmin,
  sidebarDataCompany,
  sidebarDataInstitute,
} from "./sidebarData";
import { useDispatch, useSelector } from "react-redux";
import { setSidebar } from "../../store/actions/commonSlice";
import { FaBell, FaUserEdit } from "react-icons/fa";
import { MdOutlineLogout } from "react-icons/md";
import { Tooltip } from "react-tooltip";

const SubMenu = React.lazy(() => import("./SubMenu"));

const Nav = styled.div`
background:#FFFFFF;
height:70px;
display:flex;
justify-content:space-between;
align-items:center;
color:black
@media ${devices.mobileM}{
    height:50px
    margin-top:30px
}
`;
const NavIcon = styled(Link)`
  margin-left: 1rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media ${devices.mobileM} {
    height: 50px;
  }
  @media ${devices.mobileS} {
    height: 30px;
  }
`;
const SidebarNav = styled.nav`
  background: rgb(171, 232, 255);
  background: linear-gradient(
    180deg,
    rgba(171, 232, 255, 1) 0%,
    rgba(255, 255, 255, 1) 0%
  );
  position: fixed;
  left: 0%;
  transition: ease-in-out 350ms;
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  padding-top: "100px";
  margin-top: "100px";
  max-width: ${({ sidebar }) => (sidebar ? "330px" : "85px")};
  @media ${devices.tablet} {
    max-width: ${({ sidebar }) => (sidebar ? "330px" : "0px")};
  }
`;
const SidebarWrap = styled.div`
  transition: ease-in-out 350ms;
  margin-top: "100px";
  padding-top: "100px";
`;

const Sidebar = memo(({ children }) => {
  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state?.commonslice?.sidebar);
  const [sidebarData, setSidebarData] = useState([]);
  const loggedUser = JSON.parse(localStorage.getItem("userData"));

  const showSideBar = () => dispatch(setSidebar(!sidebar));
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "https://sailorshub.us.tempcloudsite.com/";
  };
  const { user_type } = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    const { user_type } = JSON.parse(localStorage.getItem("userData"));
    if (
      user_type === "super_admin" ||
      loggedUser?.user_type === "super_admin"
    ) {
      setSidebarData((prev) => (prev = sidebarDataAdmin));
    } else if (
      user_type === "institute" ||
      loggedUser?.user_type === "institute"
    ) {
      setSidebarData((prev) => (prev = sidebarDataInstitute));
    } else if (user_type === "company" || loggedUser?.user_type === "company") {
      setSidebarData((prev) => (prev = sidebarDataCompany));
    }
  }, []);

  return (
    <div className="sidebarContainer">
      <Nav
        style={{
          position: "fixed",
          zIndex: "10",
          boxShadow:
            " 0 4px 5px 0 rgba(0, 0, 0, -0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.12)",
        }}
        className="px-3"
      >
        <NavIcon style={{ cursor: "default" }}>
          <div className="Navbar-logoCollector">
            {/* <FaBars  color='black' onClick={showSideBar} className='headerSidebarbutton'/> */}
            {!sidebar ? (
              <img
                loading="lazy"
                onClick={showSideBar}
                src="/assets/sidebarAssets/menu-bar.png"
                style={{
                  maxWidth: "40px",
                  maxHeight: "40px",
                  marginRight: "10px",
                  marginLeft: "11px",
                  cursor: "pointer",
                  marginTop: "3px",
                }}
                alt="&equiv;"
              />
            ) : (
              <img
                loading="lazy"
                onClick={showSideBar}
                src="/assets/sidebarAssets/delete.png"
                style={{
                  maxWidth: "40px",
                  maxHeight: "40px",
                  marginRight: "10px",
                  marginLeft: "11px",
                  cursor: "pointer",
                  marginTop: "3px",
                }}
                alt="&equiv;"
              />
            )}
            {/* <img src='/assets/commonAssets/sailors-hub new logo.png' alt='logo' color='rgba(42, 115, 122, 1)' style={{ maxWidth: "50px", maxHeight: "50px",marginRight:"20px",marginLeft:"8px", height:"50px",borderRadius:"10px" }} className='navSvg'  /> */}

            <img
              loading="lazy"
              className="navBarLogo navBarLogo-one"
              src="/assets/authAssets/Sailors-Hub-logo.gif"
              alt="logo"
              style={{ height: "50px", marginLeft: "10px" }}
            />
          </div>
        </NavIcon>
        <div className="userAvatar">
          <div className="userName-avatar">
            <p
              loading="lazy"
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "21px",
                letterSpacing: "0.01em",
                textAlign: "right",
                color: "#005A80",
                marginBottom: "0px",
              }}
            >
              {/* GoodsBearer International transport */}
              {loggedUser?.first_name || "User"}
              {" " + loggedUser?.last_name !== null
                ? loggedUser?.last_name
                : ""}
            </p>
            <div>
              {loggedUser?.user_type === "super_admin"
                ? "Super Admin"
                : loggedUser?.user_type === "institute"
                ? "Institute"
                : "Company"}
            </div>
          </div>
          <div
            className="userAvatar-picture"
            data-toggle="modal"
            data-target="#exampleModalCenter"
          >
            <img
              className="object-contain"
              src={
                loggedUser?.profile_pic ||
                "/assets/commonAssets/user.png" ||
                "https://t3.ftcdn.net/jpg/02/73/28/46/360_F_273284658_w8mcoqsz5uEG4DMDKrdBL81oKyRzvg9i.jpg"
              }
              onError={(e) => {
                e.target.src =
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnANOMTOAk33gU_TFBjI5RpjVu3Jk_MUq6pvqf0zP8fg&s";
              }}
              alt="userImage"
            />
          </div>
          {/* <span className='hrLine'></span>
                    <div style={{ maxWidth: "32px", marginRight: "25px" }}>
                        <FaBell style={{ width: "50px", marginLeft: "10px" }} className='navBar-notification' />
                    </div> */}
        </div>
      </Nav>
      <div className="horizontal-container">
        <SidebarNav
          className="Sidebarnav-container"
          sidebar={sidebar}
          style={{
            maxHeight: "100%",
            overflowY: "scroll",
            overflowX: "none",
            boxShadow: "10px 4px 26px -23px rgba(0, 0, 0, 0.75)",
            zIndex: "10",
            marginTop: "70px",
          }}
        >
          <SidebarWrap>
            {/* <div className='sidebar-logoCollector'>
                            <img src='/assets/sidebarAssets/delete.png' style={{ maxWidth: "50px", maxHeight: "50px", cursor: "pointer" }} onClick={showSideBar} className='sidebar-close-icon' alt='close'/>
                             <img className='navBarLogo' src='/assets/authAssets/Sailors-Hub-logo.gif' alt='logo' style={{height:"70px"}}/>
                        </div> */}
            {sidebarData?.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
        '
        <div
          className={
            sidebar ? "childSpaceOpen childSpace" : "childSpaceClose childSpace"
          }
        >
          {children}
        </div>
      </div>
      {/* For modal */}
      <div
        className="animate__animated animate__fadeIn modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        style={{ maxHeight: "350px", zIndex: "10" }}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ maxHeight: "315px" }}
        >
            <div
            className="modal-content"
            style={{
              borderRadius: "15px",
              maxWidth: "430px",
              height: "auto",
              paddingBottom: "41px",
            }}
          >
            <div
              className="modal-header w-100 h-auto font-serif"
              // style={{
              //   maxHeight: "85px",
              //   display: "flex",
              //   justifyContent: "space-between",
              //   alignItems: "center",
              // }}
            >
              <div className="modal-title" id="exampleModalLongTitle">
                <div className="userAvatar flex flex-row items-center">
                  <div
                    className="userAvatar-picture flex flex-row items-center"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    <img
                      src={
                        loggedUser?.profile_pic ||
                        "/assets/commonAssets/user.png" ||
                        "https://t3.ftcdn.net/jpg/02/73/28/46/360_F_273284658_w8mcoqsz5uEG4DMDKrdBL81oKyRzvg9i.jpg"
                      }
                      className="object-contain"
                      onError={(e) => {
                        e.target.src =
                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnANOMTOAk33gU_TFBjI5RpjVu3Jk_MUq6pvqf0zP8fg&s";
                      }}
                      alt="userImage"
                    />
                  </div>
                  {/* <div className="userName-avatar-modal ms-2 "> */}
                    <div className="text-ellipsis text-sm text-wrap">
                      {loggedUser?.first_name || "UserName"}
                      {" " + loggedUser?.last_name ? loggedUser?.last_name : ""}
                    </div>
                  {/* </div> */}
                </div>
              </div>
              <button
                type="button"
                className="close w-5 h-5 me-2"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{ maxHeight: "340px", height: "auto" }}
            >
              <div className="modalEmail" style={{ paddingTop: "0px" }}>
                <label className="fw-bolder" style={{ fontWeight: 800 }}>
                  Email:{" "}
                </label>
                <span style={{ fontWeight: "400" }}>
                  {loggedUser?.email || "NA"}
                </span>
              </div>
              {/* {user_type==='institute' | user_type==='company' ? <div className='modalEmail' style={{paddingTop:"10px"}}>
                                        <label className='fw-bolder' style={{fontWeight:800}}>Official Site</label>
                                        
                                        <span style={{fontWeight:"400"}}>{loggedUser?.email}</span>
                            </div> : <></>} */}
              {(user_type === "institute") | (user_type === "company") ? (
                <button
                  onClick={() => {
                    navigate(
                      user_type === "institute"
                        ? "/institute-details"
                        : "/company/details"
                    );
                  }}
                  className="logOutButton"
                  style={{ marginBottom: "30px", marginTop: "40px" }}
                  data-dismiss="modal"
                >
                  <FaUserEdit
                    style={{
                      width: "30px",
                      height: "35px",
                      marginLeft: "20px",
                    }}
                  />
                  <span>Edit Profile</span>
                </button>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <hr />
              <button
                style={{ marginBottom: "-20px" }}
                className="logOutButton d-flex align-items-center"
                onClick={handleLogout}
                data-dismiss="modal"
              >
                <MdOutlineLogout
                  style={{ width: "30px", height: "30px", marginLeft: "20px" }}
                />
                <span className="mt-1">Log out</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="my-tooltip" />
    </div>
  );
});

export default Sidebar;
