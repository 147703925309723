import React, { useEffect, useState } from "react";
import { FaCaretDown, FaPen, FaRegListAlt } from "react-icons/fa";
import { MdOutlineFileUpload } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BallTriangle } from "react-loader-spinner";
import ReactQuill from "react-quill";
import MainEditorfile from "../../../components/editor/MainEditorfile";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "../../../../store/actions/commonSlice";
import {
  getAllNewsAndAnnouncements,
  getCountries,
} from "../../../../Apis/getApis";
import { saveNewsAndAnnouncement } from "../../../../Apis/postApis";
import { setNews } from "../../../../store/actions/AdminSlices";
import { updateNewsAndAnnouncement } from "../../../../Apis/updateApis";
import { deleteNewsAndAnnouncement } from "../../../../Apis/deleteApis";
import Swal from "sweetalert2";
import { allowedTypes } from "../../../../utils/variables/folderStructureFiles";
import {
  errorToast,
  successToast,
  uploadFile,
} from "../../../../utils/helperFunctions/HelperFunctions";
import useToken from "../../../../customHook/useToken";
const NewsAndAnnouncement = () => {
  const access = useToken();
  const [course, setCourse] = useState("Select Content type");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [isEdit, setIsEdit] = useState(false);
  const [newsAnnouncementList, setNewsAnnouncementList] = useState([]);
  const [content, setContent] = useState();
  const [contentType, setContentType] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const dispatch = useDispatch();
  const news = useSelector((state) => state?.adminSlice?.news);

  useEffect(() => {
    setTimeout(() => {
      if (search !== "") {
        let arr = [];
        for (let i = 0; i < countryList.length; i++) {
          if (
            countryList[i].name.toLowerCase().includes(search.toLowerCase())
          ) {
            const isThere = (data) => {
              for (let j = 0; j < arr.length; j++) {
                if (arr[j].id === data.id) {
                  return true;
                }
              }
              return false;
            };
            if (!isThere(countryList[i])) {
              arr.push(countryList[i]);
            }
          }
        }
        setSearchData([...new Set(arr)]);
      } else {
        setSearchData([]);
      }
    }, 1000);
  }, [search]);

  const editData = (item) => {
    setIsEdit(true);
    setSelectedItem(item);
    setTitle(item?.title);
    dispatch(setNews(item?.description));
    setSelectedCountries(item?.countries);
    setContent(item?.content);
    setCourse(item?.type_of_content);
  };
  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You can't revert the changes",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteNewsAndAnnouncement(id,access)
          .then((res) => {
            console.log(res);
            allNewsAnnouncement();
          })
          .catch((err) => {
            console.log(err);
          });
        successToast("News/Announcement Deleted Successfully");
      }
    });
  };

  const addCountry = (countryData) => {
    const isThere = selectedCountries.some((item) =>
      isEdit ? item.id == countryData.id : item == countryData.id
    );
    if (!isThere) {
      if (isEdit) {
        setSelectedCountries([...selectedCountries, countryData.id]);
      } else {
        setSelectedCountries([...selectedCountries, countryData.id]);
      }
    }
  };

  const findById = (id) => {
    for (let i = 0; i < countryList.length; i++) {
      if (countryList[i].id === id) {
        console.log(countryList[i], "indeed");
        return countryList[i];
      }
    }
  };

  const removeCountry = (data) => {
    const countries = [...selectedCountries];
    const newCountry = countries.filter((item) => item !== data);
    setSelectedCountries(newCountry);
  };

  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile.size > 5000000) {
      errorToast("Files size can't be greater than 5MB");
      return;
    }
    if (allowedTypes.includes(selectedFile?.type)) {
      setFile(selectedFile);
      let res = uploadFile(selectedFile);
      res.then((data) => {
        console.log(data, "uploadedImage");
        setContent(data);
      });
    } else {
      alert("Invalid file type. Only images, videos and PDFs are allowed.");
      return;
    }
  };

  const handleCancle = () => {
    setTitle("");
    setCourse("");
    setContent("");
    dispatch(setNews(""));
    setSelectedCountries([]);
    setIsEdit(false);
  };

  const handleSubmit = () => {
    if (!isEdit) {
      saveNewsAndAnnouncement({
        title: title,
        type_of_content: course,
        content: content,
        description: news,
        countries: selectedCountries,
        data_type: contentType,
      },access)
        .then((res) => {
          console.log(res);
          allNewsAnnouncement();
          handleCancle();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      updateNewsAndAnnouncement(selectedItem?.id, {
        title: title,
        type_of_content: course,
        content: content,
        description: news,
        countries: selectedCountries,
        data_type: contentType,
      },access)
        .then((res) => {
          console.log(res);
          allNewsAnnouncement();
          handleCancle();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function allNewsAnnouncement() {
    setLoading(true);
    getAllNewsAndAnnouncements()
      .then((res) => {
        console.log(res);
        setLoading(false);
        setNewsAnnouncementList(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    getCountries()
      .then((res) => {
        setCountryList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });

    allNewsAnnouncement();

    dispatch(setPage("news"));
    dispatch(setNews(""));

    return () => {
      dispatch(setNews(""));
    };
  }, []);
  return (
    <div className="institute-container animate__animated animate__fadeIn">
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4 className="d-flex">
            <FaRegListAlt style={{ width: "30px", marginRight: "10px" }} />
            News And Announcement
          </h4>
        </div>
      </div>
      <div className="table-container">
        <div className="allDropDown allField-learning-courses position-relative">
          <span
            className="mustStar"
            style={{
              textAlign: "end",
              fontSize: "15px",
              position: "absolute",
              top: "-20px",
              left: "-16px",
            }}
          >
            Fields marked (*) are mandatory.
          </span>
          <span
            className="flex add-course-container add-course-country-container justify-content-center"
            style={{ maxWidth: "100%", paddingRight: "25px" }}
          >
            <div className="dropdown dropDown-locality">
              <button
                type="button"
                className="w-auto h-10 border-[1px] border-slate-600 rounded-md flex flex-row items-center justify-center px-2 gap-x-2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ backgroundColor: "white", maxWidth: "200px", border:"1px solid rgb(207, 196, 196)" }}
              >
                Country
                <FaCaretDown className="size-4 w-4" />
              </button>
              <div
                className="dropdown-menu"
                style={{
                  boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)",
                  width: "300px",
                  height: "200px",
                  overflowY: "scroll",
                }}
              >
                <div
                  className="searchBar-Container"
                  style={{ maxHeight: "50px" }}
                >
                  <div
                    className="input-group position-sticky"
                    style={{ marginBottom: "-100px", paddingLeft: "10px" }}
                  >
                    <input
                      type="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control position-static"
                      placeholder="Search ......"
                      aria-label="Recipient's username"
                    />
                  </div>
                </div>
                {search === "" && (
                  <React.Fragment>
                    {countryList.length > 0 ? (
                      countryList?.map((item, index) => {
                        return (
                          <div
                            className="dropdown-item"
                            style={{ height: "50px", cursor: "pointer" }}
                            onClick={() => addCountry(item)}
                          >
                            {item?.name}
                          </div>
                        );
                      })
                    ) : (
                      <center style={{ marginTop: "10%" }}>
                        !Opps check the Internet
                      </center>
                    )}
                  </React.Fragment>
                )}

                {search !== "" && (
                  <>
                    {searchData.length > 0 ? (
                      searchData.reverse()?.map((item, index) => {
                        return (
                          <div
                            className="dropdown-item"
                            style={{ height: "50px", cursor: "pointer" }}
                            onClick={() => addCountry(item)}
                          >
                            {item.name}
                          </div>
                        );
                      })
                    ) : (
                      <center style={{ marginTop: "10%" }}>No Match</center>
                    )}
                  </>
                )}
              </div>
            </div>
            <div
              className={
                selectedCountries.length > 0
                  ? "selectedCountries-container pb-2 rounded-md"
                  : "selectedCountries-container noDataContainer rounded-md"
              }
            >
              {selectedCountries.length > 0 ? (
                <>
                  {selectedCountries.map((item) => {
                    return (
                      <div className="selectedCountry">
                        <p style={{ marginTop: "37px" }}>
                          {findById(item)?.name}
                        </p>
                        <button
                          onClick={() => removeCountry(item)}
                          type="button"
                          className="close"
                          style={{ width: "15px", height: "15px" }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    );
                  })}
                </>
              ) : (
                <center style={{ marginTop: "15px" }}>All Countries</center>
              )}
            </div>
          </span>

          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Title</p>
            <input value={title} maxLength={120} onChange={(e) => setTitle(e.target.value)} type='text' className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
          </span> */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              Title<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                maxLength={120}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="px-2 sailors-Input-field"
              />
            </div>
          </div>

          {/* <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
            <p style={{ maxWidth: "231px" }}>Type Of content</p>
            <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
              <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                {course == "news" ? "News" : course == "announcement" ? "Announcement" : "Select Type"}<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "120px", overflowY: 'scroll' }}>

                {[{ lable: "Announcement", value: 'announcement' }, { lable: "News", value: "news" }].map((item, index) => {
                  return (
                    <div className="dropdown-item" onClick={() => { setCourse(item.value) }} style={{ height: "50px", cursor: "pointer" }} >{item.lable}</div>
                  )
                })}

              </div>
            </div>
          </span> */}

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              User type<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <select
                className="form-select sailors-Input-field"
                value={course}
                onChange={(e) => setCourse(e.target.value)}
              >
                <option>Select User Type</option>
                {[
                  { lable: "Announcement", value: "announcement" },
                  { lable: "News", value: "news" },
                ].map((item) => {
                  return <option value={item?.value}>{item.lable}</option>;
                })}
              </select>
            </div>
          </div>

          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}> Choose File</p>
            <div>
              <input type='file' onChange={(e) => { handleFileChange(e) }} />
              <span style={{ fontSize: "12px", color: 'red' }}>Upload Images and Docs under 5MB</span>
            </div>
          </span> */}

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Upload</label>
            <div className="col-sm-9">
              <input
                required
                type="file"
                onChange={(e) => {
                  handleFileChange(e);
                }}
                className="sailors-Input-field p-2"
              />
              <span style={{ fontSize: "12px", color: "red" }}>
                Upload Images and Docs under 5MB
              </span>
            </div>
          </div>

          <span
            className="d-flex add-course-container justify-content-center"
            style={{ maxWidth: "100%", padding: "10px", height: "auto" }}
          >
            {/* <ReactQuill theme="snow" value={editor} onChange={setEditor}/> */}
            <MainEditorfile />
          </span>
        </div>
        <div className="form-btns" style={{ marginBottom: "10px" }}>
          <button onClick={handleSubmit} type="submit" className="btn">
            {isEdit ? "Update" : "Save"}
          </button>
          <button onClick={handleCancle} className="btn btn-OnboardForm" style={{border:"1px solid #005A80"}}>
            Cancel
          </button>
        </div>
      </div>
      <div
        className="instituteOnboarding-list-view mt-5"
        style={{ marginTop: "10px" }}
      >
        <div className="headerPart">
          <h4>News/Announcements List</h4>
        </div>
      </div>
      {loading ? (
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>
          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>
      ) : (
        <div className="table-container">
          {course === "Choose Course" ? (
            <center>
              <h3>Course Not Selected</h3>
            </center>
          ) : (
            <React.Fragment>
              {newsAnnouncementList?.length > 0 ? (
                <div className="table-Data">
                  <table className="table">
                    <thead>
                      <tr
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          borderBottom: "1px solid black",
                          height: "50px",
                        }}
                      >
                        <th style={{ fontWeight: "700", maxWidth: "100px" }}>
                          Sr No.
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          Title
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          Type
                        </th>
                        <th style={{ fontWeight: "700" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {newsAnnouncementList.map((item, index) => {
                        return (
                          <tr
                            className=""
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td style={{ textAlign: "start" }}>
                              {item?.title}
                            </td>
                            <td style={{ textAlign: "start" }}>
                              {item?.type_of_content}
                            </td>
                            <td>
                              <div
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "start",
                                }}
                              >
                                <FaPen
                                  onClick={() => editData(item)}
                                  data-toggle="modal"
                                  data-target="#instituteDetailsModal"
                                  title="Details"
                                  style={{
                                    width: "30px",
                                    color: "#0CB1C0",
                                    border: "1px solid gray",
                                    padding: "5px",
                                    borderTopLeftRadius: "10px",
                                    borderBottomLeftRadius: "10px",
                                  }}
                                />{" "}
                                <RiDeleteBin6Line
                                  onClick={() => deleteData(item.id)}
                                  title="Delete"
                                  style={{
                                    width: "30px",
                                    color: "#F84F31",
                                    border: "1px solid gray",
                                    padding: "5px",
                                    borderTopRightRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <center>No Data found</center>
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default NewsAndAnnouncement;
