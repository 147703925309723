import React, { useEffect, useState } from 'react'
import { FaCaretDown, FaPen } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BallTriangle } from 'react-loader-spinner';
import { GetLearningCourses,  getLearningCourseSearch, getScheduledCourses, loadMoreData } from '../../../Apis/getApis';
import { monthsData } from '../../../utils/variables/folderStructureFiles';
import { saveInstituteCourse } from '../../../Apis/postApis';
import Swal from 'sweetalert2';
import { deleteCourseDetails } from '../../../Apis/deleteApis';
import Popup from 'reactjs-popup';
import { updateCourseData } from '../../../Apis/updateApis';
import "../../../css/bootstrap.min.css"
import { deleteToast, errorToast } from '../../../utils/helperFunctions/HelperFunctions';
import useToken from '../../../customHook/useToken';
import LoaderSmall from '../../../common/loader/LoaderSmall';
const ScheduleCourse = () => {
    const access = useToken();
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [scheduledCourseList, setScheduledCourseList] = useState([]);
    const [courseData, setCourseData] = useState([]);
    const [course, setCourse] = useState('');
    const [seats, setSeats] = useState(0);
    const [availableSeats, setAvailableSeats] = useState('');
    const width = window.innerWidth;
    const [start_date, setStartTime] = useState('');
    const [end_date, setEndTime] = useState('');
    const [batchTimingList, setBatchTimingList] = useState([]);
    const [subscriptionType, setSubscriptionType] = useState('online');
    const [selectedTimings, setSelectedTimings] = useState([]);
    const [selectedItem, setSelectedItem] = useState();
    const [courseFees, setCourseFees] = useState('');
    const [courseSearch, setCourseSearch] = useState('');
    const [courseSearchData, setCourseSeatchData] = useState([]);
    const [courseLoading,setCourseLoading] = useState(false);


    const logInDetails = JSON.parse(localStorage.getItem('userData'));
    const editCourse = (item) => {
        setIsEdit(true)
        setSelectedItem(item);
        setBatchTimingList(item?.batch_durations);
        setCourse(item?.course);
        setCourseFees(item?.amount);
        setSubscriptionType(item?.course_mode);
        setSeats(item?.total_seats);
        setAvailableSeats(item?.available_seats);
    }

    const handleCancel = () => {
        setIsEdit(false)
        setSelectedItem("");
        setBatchTimingList([]);
        setCourse('');
        setCourseFees(0)
        setSubscriptionType('');
        setSeats(0);
        setAvailableSeats('');
    }

    const deleteCourse = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You can revert Changes once you delete!",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete Course!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCourseDetails(id,access)
                    .then((res) => {
                        console.log(res);
                        getAllScheduledCourses();
                        deleteToast("Course Deleted Successfully");
                    })
                    .catch((err) => {
                        console.log(err)
                        errorToast("Please check the internet Or try after some Time");
                    })

            }
        });
    }

    useEffect(() => {
        let timer;
        if (courseSearch !== '') {
            timer = setTimeout(() => {
                getLearningCourseSearch(courseSearch)
                    .then((res) => {
                        console.log(res)
                        setCourseSeatchData(res?.data?.data?.results)
                    })
                    .catch((err) => {
                        console.log(err)
                    })

            }, 1000)
        } else {
            setCourseSeatchData([]);
        }
        return (() => {
            clearTimeout(timer);
        })
    }, [courseSearch])

    const getCourseById = (id) => {
        if (courseSearch === '') {
            for (let i = 0; i < courseData.length; i++) {
                if (courseData[i]?.id === id) {
                    return courseData[i]?.course_name
                }
            }
        } else {
            for (let i = 0; i < courseSearchData.length; i++) {
                if (courseSearchData[i]?.id === id) {
                    return courseSearchData[i]?.course_name
                }
            }
        }

        return "Learning Course"
    }


    const saveCourse = () => {
        
        if(course===""){
            errorToast("Please Select Course");
            return;
        }
        if(subscriptionType===""){
            errorToast("Please Select Course Mode");
            return;
        }
        if(batchTimingList.length === 0){
            errorToast("Please  Batch Dates");
            return;
        }
        if(seats===0){
            errorToast("Please Give Total Seats");
            return;
        }


        if ( batchTimingList.length === 0) {
            errorToast("Some Data is Missing");
            return;
        } else {
            setCourseLoading(true);
            if (!isEdit) {
                saveInstituteCourse({
                    institute: logInDetails?.id,
                    course: course,
                    course_mode: subscriptionType,
                    batch_durations: batchTimingList,
                    total_seats: seats,
                    available_seats: seats,
                    amount:courseFees
                }, access)
                    .then((res) => {
                        console.log(res);
                        handleCancel();
                        getAllScheduledCourses();
                        setCourseLoading(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        errorToast(err?.response?.data?.message)
                        setCourseLoading(false);
                    })
            } else {
                updateCourseData(selectedItem?.id, {
                    institute: logInDetails?.id,
                    course: course,
                    course_mode: subscriptionType,
                    batch_durations: batchTimingList,
                    total_seats: seats,
                    available_seats: availableSeats,
                    amount: courseFees
                },access)
                    .then((res) => {
                        console.log(res);
                        handleCancel();
                        getAllScheduledCourses();
                        setCourseLoading(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setCourseLoading(false);
                    })
            }
        }

    }


    const addTiming = () => {

        if (start_date !== "" && end_date !== "") {
            let start = new Date(start_date);
            let end = new Date(end_date);
            if (end < start) {
                errorToast("Batch start date cannot be later than the end date.");
                return;
            }
        }else {
            errorToast("Please select batch dates");
            return;
        }

        const d = new Date(start_date);
        const month = d.getMonth(); 
            let obj = {
                start_date: start_date,
                end_date: end_date,
                month: month+1
            }
            setBatchTimingList([...batchTimingList, obj])
            setStartTime('');
            setEndTime('');
    }

    async function getAllLearningCourses() {

        async function loadData(resNext) {
            if (resNext == null) {
                return;
            }
            await loadMoreData(resNext)
                .then((res) => {
                    console.log(res);
                    setCourseData(prev => [...prev, ...res?.data?.data?.results]);
                    loadData(res?.data?.data?.next)
                })
                .catch((err) => {
                    console.log(err);
                })
        }

        await GetLearningCourses('accepted')
            .then((res) => {
                setCourseData(res?.data?.data?.results);
                loadData(res?.data?.data?.next);
            })
            .catch(err => {
                console.log(err)
            })
    }

    function getAllScheduledCourses() {
        setLoading(true);
        getScheduledCourses()
            .then((res) => {
                setLoading(false);
                setScheduledCourseList(res?.data?.data?.results)
            })
            .catch((err) => {
                setLoading(false);
                console.log(err)
            })
    }

    useEffect(() => {
        getAllLearningCourses();
        getAllScheduledCourses();
    }, [])

    const deleteTiming = (data) => {
        let arr = [...batchTimingList];
        let newArr = arr.filter((item) => {
            return item !== data;
        });
        setBatchTimingList(newArr)
    }


    return (
        <div className='institute-container animate__animated animate__fadeIn gap-y-4' style={{ transition: 'ease-in-out 250ms' }}>
            <div className='instituteOnboarding-list-view'>
                <div className='headerPart'>
                    <h4>Schedule Course</h4>
                </div>
            </div>
            <div className='table-container' style={{ transition: 'ease-in-out 250ms' }}>
                <div className='allDropDown allField-learning-courses' style={{ transition: 'ease-in-out 250ms' }}>

                    <span className='flex flex-row justify-between' style={{ maxWidth: "100%" }}>
                        <p className='col-form-label' style={{ maxWidth: "231px" }}>Learning Course Name</p>
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses col-sm-9">
                            <button type="button"
                            style={{ backgroundColor: "white",display: "flex",border:"1px solid gray",flexDirection: "row"}}
                            className={width < 500 ? "btn drdpbtn form-control col rounded-md" : "rounded-md btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                {getCourseById(course)} <FaCaretDown style={{ width: "50px", height:"25px",color: "black" }} />
                            </button>
                            <div className="dropdown-menu" id='scrollableDiv' style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "90%", height: "200px", overflowY: 'scroll' }}>
                                <div className='searchBar-Container position-sticky' style={{ maxHeight: "50px", top: "0" }}>
                                    <div className="input-group" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                                        <input type="text" value={courseSearch} onChange={(e) => setCourseSearch(e.target.value)} className="form-select form-control" placeholder="Search ......" aria-label="Recipient's username" />
                                    </div>
                                </div>

                                {courseSearch === '' && <React.Fragment>{courseData.length > 0 ? courseData?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => { setCourse(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{getCourseById(item?.id)}</div>
                                    )
                                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}</React.Fragment>}

                                {courseSearch !== '' && <>{courseSearchData.length > 0 ? courseSearchData.reverse()?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => { setCourse(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{getCourseById(item?.id)}</div>
                                    )
                                }) : <center style={{ marginTop: "10%", maxHeight: "30px" }}>No Match</center>}</>}
                            </div>
                        </div>
                    </span>

                    <span className='flex flex-row justify-between' style={{ maxWidth: "100%"}}>
                        <p  className="col-sm-3 col-form-label" style={{ maxWidth: "231px" }}>Course Mode</p>
                        <div className='col-sm-9 flex flex-row'>
                            <div className='subCheckDiv'>
                                <label>Online</label>
                                <input type='radio' style={{ accentColor: '#005A80' }} name='subscription' value='online' onChange={() => setSubscriptionType('online')} checked={subscriptionType === 'online' ? true : false} />
                            </div>
                            <div className='subCheckDiv'>
                                <label>Offline</label>
                                <input type="radio" style={{ accentColor: '#005A80' }} name='subscription' value='offline' checked={subscriptionType === 'offline' ? true : false} onChange={() => setSubscriptionType('offline')} />
                            </div>
                        </div>
                    </span>

                    <span className='flex flex-row' style={{ maxWidth: "100%" }}>
                        <p className='col-sm-3 col-form-label' >Batch Timing <span className='mustStar'>*</span></p>
                        <div className='sailors-Input-field py-1 gap-x-2 px-2 col-sm-9' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <input type='date' className='h-auto border-[1px] border-slate-500 shadow-lg px-2 rounded-md'  value={start_date} onChange={(e) => setStartTime(e.target.value)} />
                            <span className='flex flex-row justify-center items-center' style={{ maxWidth: "30px", textAlign: 'center' }}>To</span>
                            <input type='date' className='h-auto border-[1px] border-slate-500 shadow-lg px-2 rounded-md' value={end_date} onChange={(e) => setEndTime(e.target.value)} />
                            <button onClick={addTiming} className='w-auto px-2 rounded-md' style={{background: '#005A80', color: 'white' }}>Add</button>
                        </div>
                    </span>

                    {batchTimingList.length > 0 && <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px", transition: 'ease-in-out 700ms' }} >
                        <div className='table-container' style={{ marginTop: "10px" }}>
                            <div className='table-Data  animate__animated animate__fadeInDown '>
                                <table className='table'>
                                    <thead>
                                        <tr style={{ display: 'flex', justifyContent: "flex-start",borderBottom:"1px solid #94a3b8" }}>
                                            <th style={{ fontWeight: "700", maxWidth: "60px" }}>Sr No.</th>
                                            <th style={{ fontWeight: "700", textAlign: 'start' }}>Start Time</th>
                                            <th style={{ fontWeight: "700", textAlign: 'start' }}>End Time</th>
                                            <th style={{ fontWeight: "700", textAlign: 'start' }}>Month</th>
                                            <th style={{ fontWeight: "700" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <div style={{ maxHeight: "400px", height: "auto", overflowY: 'auto', textAlign: 'start', display: 'flex', justifyContent: 'center' }} >

                                        <tbody className='animate_animated animate__fadeInUp' style={{ height: 'auto', textAlign: 'center' }}>
                                            {batchTimingList.length > 0 ? batchTimingList.map((item, index) => {
                                                return (
                                                    <tr className='' style={{ display: 'flex', justifyContent: "space-around", maxHeight: "60px" }}>
                                                        <th scope="row" style={{ width: "120px" }}>{index + 1}</th>
                                                        <td style={{ textWrap: 'wrap', textAlign: 'start' }}>{item.start_date}</td>
                                                        <td style={{ textWrap: 'wrap', textAlign: 'start' }}>{item?.end_date}</td>
                                                        <td style={{ textWrap: 'wrap', textAlign: 'start' }}>{monthsData[item?.month - 1].month}</td>
                                                        <td>
                                                            <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'start' }}>
                                                                <RiDeleteBin6Line onClick={() => deleteTiming(item)} title='Delete' style={{ width: "40px", height: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderRadius: "10px" }} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : <center>No data found</center>}

                                        </tbody>
                                    </div>
                                </table>
                            </div>
                        </div>
                    </span>}

                    {/* <div className="flex flex-row">
                        <label className="col-sm-3 col-form-label">Course duration<span className='mustStar'>*</span></label>
                        <div className="w-full flex flex-row flex-wrap align-items-center gap-y-2">
                            <input type="number" value={expiryValue} onChange={(e) => setExpiryValue(e.target.value)} className="sailors-Input-field" placeholder='Enter Value' style={{minWidth:"200px"}}/>
                            <select className='w-100 sailors-Input-field' value={expiryUnit} onChange={(e) => setExpiryUnit(e.target.value)} style={{minWidth:"220px"}}>
                                <option>Select Month/year</option>
                                <option value={'months'}>Month</option>
                                <option value={'years'}>year</option>
                                <option value={'weeks'}>Week</option>
                                <option value={'days'}>Day</option>
                            </select>
                        </div>
                    </div> */}

                    <div className="flex flex-row">
                        <label className="col-sm-3 col-form-label">Course Fees</label>
                        <div className="col-sm-9">
                            <input type="number" value={courseFees} onChange={(e) => setCourseFees(e.target.value)} className="sailors-Input-field" />
                        </div>
                    </div>

                    {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}>Course Fees</p>
                        <input type='number' value={courseFees} onChange={(e) => setCourseFees(e.target.value)} className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
                    </span> */}

                    <div className="flex flex-row">
                        <label className="col-sm-3 col-form-label">Total seats<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <input type="number" value={seats} onChange={(e) => setSeats(e.target.value)} className="sailors-Input-field" />
                        </div>
                    </div>

                    {/* <div className="flex flex-row">
                        <label className="col-sm-3 col-form-label">Available seats<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <input type="text" maxLength={10} minLength={10} value={availableSeats} onChange={(e) => setAvailableSeats(e.target.value)} className=" sailors-Input-field" />
                        </div>
                    </div> */}


                </div>
                <div className='form-btns' style={{ marginBottom: "10px" }}>
                    <button type="submit" onClick={()=>{
                     if(!courseLoading){
                        saveCourse();
                     }   
                    }} style={{
                        border:"1px solid #0a66c2", 
                        background:"#0a66c2", 
                        display:"flex",
                        flexDirection:"row",
                        justifyContent:"center",
                        alignItems:"center",
                    }} className="btn">{ courseLoading ? <LoaderSmall/> : isEdit ? "Update" : "Save"}</button>
                    <button onClick={handleCancel} className="btn btn-OnboardForm border-[1px] border-l-slate-500" style={{border:"1px solid black"}}>Cancel</button>
                </div>
            </div>
            <div className='instituteOnboarding-list-view' style={{ marginTop: "30px" }}>
                <div className='headerPart'>
                    <h4>All Scheduled Courses</h4>
                </div>
            </div>

            {loading ?
                <div style={{ maxWidth: "100px", maxHeight: "100px" }}>

                    <BallTriangle
                        height={20}
                        width={20}
                        radius={5}
                        color="red"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                    <span>Loading...</span>
                </div>

                : <div className='table-container'>

                    <div className='table-Data'>
                    {scheduledCourseList.length > 0 ? <table className='table'>
                            <thead>
                                <tr style={{ display: 'flex', justifyContent: "flex-start" }} className="border-b-[1px] border-b-[#2A737A66] text-[#005A80]">
                                    <th style={{ fontWeight: "700", maxWidth: "90px",color:"#005A80" }}>Sr No.</th>
                                    <th style={{ fontWeight: "700", textAlign: 'start',color:"#005A80" }}>Course Name</th>
                                    <th style={{ fontWeight: "700", textAlign: 'center',color:"#005A80" }}>Course Mode</th>
                                    <th style={{ fontWeight: "700", textAlign: 'center',color:"#005A80" }}>Batch time</th>
                                    <th style={{ fontWeight: "700",color:"#005A80" }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {scheduledCourseList.map((item, index) => {
                                    return (
                                        <tr className='border-b-[1px]' style={{ display: 'flex', justifyContent: "space-around" }}>
                                            <th scope="row">{index + 1}</th>
                                            <td style={{ textWrap: 'wrap', textAlign: 'start' }}>{getCourseById(item.course)}</td>
                                            <td style={{ textWrap: 'wrap', textAlign: 'center' }}>{item?.course_mode}</td>
                                            <td style={{ textWrap: 'wrap', textAlign: 'center' }}>
                                                {/* <button className='btn btn-primary' onClick={()=>showTimings(item)} >See Timings</button> */}
                                                <Popup
                                                    trigger={<button className="button" style={{background:'#005A80',maxWidth:"150px", height:"30px",borderRadius:"5px",color:'white'}}> See Timings </button>}
                                                    modal
                                                    onOpen={() => {
                                                        setSelectedTimings(item.batch_durations);
                                                    }}
                                                >

                                                    <div className="modal" style={{ width: "500px", height: "500px", display: "contents" }}>
                                                        <div className="content" style={{ overflowY: "scroll" }}>
                                                            <center style={{ height: "40px", marginBottom: "30px" }}>
                                                                <h3 >Batch Timings</h3>
                                                            </center>
                                                            {selectedTimings.map((item, index) => {
                                                                return (
                                                                    <center className='my-1' style={{ display: "flex", justifyContent: 'space-between', maxHeight: "40px", background: "#F5F5F5" }}>
                                                                        <span>{item.start_date}</span>
                                                                        <span>To</span>
                                                                        <span>{item.end_date}</span>
                                                                        <span>{monthsData[item.month].month}</span>
                                                                    </center>
                                                                )
                                                            })}
                                                        </div>

                                                    </div>
                                                </Popup>
                                            </td>
                                            <td>
                                                <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'start' }}>
                                                    <FaPen onClick={() => editCourse(item)} title='Details' style={{ width: "40px", height: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /><RiDeleteBin6Line onClick={() => deleteCourse(item.id)} title='Delete' style={{ width: "40px", height: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table> : <center>No data found</center>}
                    </div>
                </div>}
                
        </div>
    )
}

export default ScheduleCourse
