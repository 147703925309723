import React, { useEffect, useLayoutEffect, useState } from 'react'
import { FaCaretDown, FaCross, FaPen } from 'react-icons/fa'
import { MdOutlineClose, MdOutlineFileUpload } from 'react-icons/md'
import { GetLearningCourses, getQuestionByCourseModule, getSubCourseUploadDocuments } from '../../../../Apis/getApis';
import { BallTriangle } from 'react-loader-spinner';
import { v4 as uuidV4 } from 'uuid';
import { RiDeleteBin6Line } from 'react-icons/ri';
import "./addQuestions.css";
import Swal from 'sweetalert2';
import { saveRankQuestions } from '../../../../Apis/postApis';
import { deleteAddedQuestion } from '../../../../Apis/deleteApis';
import { updateAddedQuestion } from '../../../../Apis/updateApis';
import { errorToast, successToast } from '../../../../utils/helperFunctions/HelperFunctions';
import useToken from '../../../../customHook/useToken';

const AddQuestions = () => {
  const access = useToken();
  const [course, setCourse] = useState('Learning Course');
  const [courseData, setCourseData] = useState([]);
  const [subCourse, setSubCourse] = useState('Sub-Course');
  const [subCourseData, setSubCourseData] = useState('');
  const [width, setWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [question, setQuestion] = useState('');
  const [updateOption, setUpdateOption] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [selectOption, setSelectOption] = useState();
  const [options, setOptions] = useState([]);
  const [optionText, setOptionText] = useState('');
  const [allQuestion, setAllQuestions] = useState([]);

  const createOption = () => {
    if (optionText !== "") {
      setOptions([...options, { option: optionText, is_correct: false, unique_id: uuidV4() }]);
      setOptionText('');
    }
  }

  const handleCorrectOption = (id) => {
    let newOpt = [...options];

    for (let i = 0; i < newOpt.length; i++) {
      if (newOpt[i].unique_id === id) {
        newOpt[i].is_correct = !newOpt[i].is_correct;
        break;
      }
    }
    setOptions(newOpt);
  }

  const handleCancel = () => {
    setIsEdit(false);
    setOptions([]);
    setCourse('Learning Course')
    setSubCourse('Sub-Course')
    setQuestion('');
    setAllQuestions([])
  }

  const handleSubmit = () => {

    if (course === "Learning Course") {
      errorToast("Please select the course");
      return;
    }
    if (subCourse == "Sub-Course") {
      errorToast("Please select the Sub-Course");
      return;
    }
    if (question == "") {
      errorToast("Please Fill the question field");
      return;
    }
    if (options.length < 2) {
      errorToast("Please Add Atleast Two Options");
      return;
    }

    function checkCorrectOption() {
      for (let i = 0; i < options.length; i++) {
        if (options[i].is_correct == true) {
          return true;
        }
      }
      return false;
    }
    if (!checkCorrectOption()) {
      errorToast("Please select one Correct Option");
      return;
    }

    if (!isEdit) {
      saveRankQuestions({
        course: course,
        module: subCourse,
        question_type: 'mcq',
        question: question,
        options: options
      },access)
        .then((res) => {
          console.log(res)
          successToast('Question Saved Successfully')
          getAllQuestionsByModule();
          handleCancel()
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      updateAddedQuestion(selectedItem?.id, {
        course: course,
        module: subCourse,
        question_type: 'mcq',
        question: question,
        options: options
      },access)
        .then((res) => {
          setSelectedItem();
          getAllQuestionsByModule();
          handleCancel()
        })
        .catch((err) => {
          console.log(err)
        })
    }

  }

  const getCourseById = (id) => {
    for (let i = 0; i < courseData.length; i++) {
      if (courseData[i]?.id === id) {
        return courseData[i]?.course_name
      }
    }

    return "Learning Course";
  }

  const getModuleNameById = (id) => {
    for (let i = 0; i < subCourseData.length; i++) {
      if (subCourseData[i]?.id === id) {
        return subCourseData[i]?.module_name;
      }
    }
    return "Sub-Course";
  }

  function getAllLearningCourses() {
    GetLearningCourses('accepted')
      .then((res) => {
        setCourseData(res?.data?.data?.results);
      })
      .catch(err => {
        console.log(err)
      })
  }

  const editData = (item) => {
    setSelectedItem(item);
    setQuestion(item?.question);
    setOptions(item?.options)
    setIsEdit(true);
  }
  const deleteModule = (id) => {

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete Question ?!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes,Delete Question!"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAddedQuestion(id,access)
          .then((res) => {
            console.log(res);
            successToast("Question Deleted Successfully");
            getAllQuestionsByModule();
          })
          .catch(err => {
            console.log(err)
          })

      }
    });
  }

  useEffect(() => {
    getAllQuestionsByModule();
  }, [course, subCourse])

  function getAllQuestionsByModule() {
    if (course !== "Learning Course" && subCourse !== "Sub-Course") {
      getQuestionByCourseModule(course, subCourse)
        .then((res) => {
          console.log(res, "quesRes")
          setAllQuestions(res?.data?.data?.results)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  useLayoutEffect(() => {
    getAllLearningCourses();
  }, []);

  const editOption = (item) => {
    setSelectOption(item);
    setUpdateOption(true);
    setOptionText(item?.option)
  }
  const deleteOption = (id) => {
    let opt = [...options];
    let newOpt = opt.filter((item) => item.unique_id !== id);
    setOptions(newOpt);
  }


  useEffect(() => {
    for (let i = 0; i < courseData.length; i++) {
      if (courseData[i].id == course) {
        setSubCourseData(courseData[i].modules_details)
      }
    }
  }, [course])

  return (
    <div className='institute-container animate__animated animate__fadeIn'>
      <div className='instituteOnboarding-list-view'>
        <div className='headerPart'>
          <h4 className='d-flex'>Add Questions</h4>
        </div>
      </div>
      <div className='table-container position-relative'>
        <span className='mustStar' style={{ textAlign: 'end', fontSize: "15px", position: 'absolute', top: "-20px", left: "-16px" }}>Fields marked (*) are mandatory.</span>
        <div className='allDropDown allField-learning-courses'>
          <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
            <p style={{ maxWidth: "231px", margin: "0px 11px 0px -11px" }}>Learning Course Name<span className='mustStar'>*</span></p>
            <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
              <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                {getCourseById(course)}<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>

                {courseData.length > 0 ? courseData?.map((item, index) => {
                  return (
                    <div className="dropdown-item" onClick={() => { setCourse(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{getCourseById(item?.id)}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}

              </div>
            </div>
          </span>

          <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
            <p style={{ maxWidth: "231px", margin: "0px 11px 0px -11px" }}>Learning (Sub-Course) Name<span className='mustStar'>*</span></p>
            <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
              <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                {getModuleNameById(subCourse)}<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>

                {subCourseData.length > 0 ? subCourseData?.map((item, index) => {
                  return (
                    <div className="dropdown-item" onClick={() => { setSubCourse(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{item?.module_name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>{course == "Learning Course" ? "Select the learning Course" : "No Module Available"}</center>}


              </div>
            </div>
          </span>

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Question<span className='mustStar'>*</span></label>
            <div className="col-sm-9">
              <input required value={question} onChange={(e) => setQuestion(e.target.value)} type='text' className="form-control sailors-Input-field" />
            </div>
          </div>

          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
          <p style={{ maxWidth: "231px" }}>Question<span className='mustStar'>*</span></p>
          <input required value={question} onChange={(e)=>setQuestion(e.target.value)} type='text' className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
        </span> */}

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Question<span className='mustStar'>*</span></label>
            <div className="col-sm-9">
              <input required value={optionText} onChange={(e) => setOptionText(e.target.value)} type='text' className="form-control sailors-Input-field" />
              <button className='btn position-absolute h-75' type='submit' style={{ width: 'auto',top:"5px",right:"15px" }} onClick={createOption}>Add Option</button>
            </div>
            <div className='optionsMapDiv'>
                <ol>
                  {options.map((item, index) => {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center', backgroundColor: selectOption?.unique_id == item.unique_id ? "#F6F5F5" : "#FFFFFF" }}>
                        <li style={{
                          maxWidth: "300px",
                          minWidth: "300px"
                        }}>
                          {item?.option}
                        </li>

                        <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'center', margin: "5px 5px 0px 20px" }}>
                          <MdOutlineClose onClick={() => deleteOption(item?.unique_id)} title='Delete' style={{ width: "25px", color: "#005A80", padding: "2px 4px 2px 4px" }} />
                          <input name='option' type='radio' style={{ width: "30px", height: '20px' }} checked={item?.is_correct} onChange={() => { handleCorrectOption(item?.unique_id) }} />
                        </div>
                      </div>
                    )
                  })}
                </ol>
              </div>
          </div>

          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Options<span className='mustStar'>*</span></p>
            <div>
              <div className='optionDiv'>
                <input type="text" className='sailors-Input-field' style={{ paddingLeft: "10px" }} value={optionText} onChange={(e) => setOptionText(e.target.value)} />
                <button className='btn' type='submit' style={{ width: 'auto' }} onClick={createOption}>Add Option</button>
              </div>
              <div className='optionsMapDiv'>
                <ol>
                  {options.map((item, index) => {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center', backgroundColor: selectOption?.unique_id == item.unique_id ? "#F6F5F5" : "#FFFFFF" }}>
                        <li style={{
                          maxWidth: "300px",
                          minWidth: "300px"
                        }}>
                          {item?.option}
                        </li>

                        <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'center', margin: "5px 5px 0px 20px" }}>
                          <MdOutlineClose onClick={() => deleteOption(item?.unique_id)} title='Delete' style={{ width: "25px", color: "#005A80", padding: "2px 4px 2px 4px" }} />
                          <input name='option' type='radio' style={{ width: "30px", height: '20px' }} checked={item?.is_correct} onChange={() => { handleCorrectOption(item?.unique_id) }} />
                        </div>
                      </div>
                    )
                  })}
                </ol>
              </div>
            </div>
          </span> */}

        </div>
        <div className='form-btns' style={{ marginBottom: "10px" }}>
          <button type="submit" onClick={handleSubmit} className="btn">{isEdit ? "Update" : "Save"}</button>
          <button type='button' onClick={handleCancel} className="btn btn-OnboardForm">Cancel</button>
        </div>
      </div>
      <div className='instituteOnboarding-list-view' style={{ marginTop: "20px" }}>
        <div className='headerPart'>
          <h4>Question List</h4>
        </div>
      </div>
      {loading ?
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>

          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>

        : <div className='table-container'>

          {
            course === "Choose Course" ? <center><h3>Course Not Selected</h3></center> :
              <React.Fragment>
                {allQuestion?.length > 0 ?
                  <div className='table-Data'>
                    <table className='table'>
                      <thead>
                        <tr style={{ display: 'flex', justifyContent: "start" }}>
                          <th style={{ fontWeight: "700", maxWidth: "80px" }}>Sr No.</th>
                          <th style={{ fontWeight: "700", textAlign: 'start' }}>Question</th>
                          <th style={{ fontWeight: "700" }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allQuestion.map((item, index) => {
                          return (
                            <tr className='' style={{ display: 'flex', justifyContent: "space-around" }}>
                              <th scope="row">{index + 1}</th>
                              <td style={{ textAlign: 'start' }} >{item?.question}</td>
                              <td >
                                <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                                  <FaPen onClick={() => editData(item)} data-toggle="modal" data-target="#instituteDetailsModal" title='Edit' style={{ width: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /> <RiDeleteBin6Line onClick={() => deleteModule(item.id)} title='Delete' style={{ width: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>


                    </table>
                  </div>
                  :
                  <center>No Data found</center>}
                {/* {
              openCourse?.modules_details.length > 0 ? openCourse?.modules_details.length.map((mod) => {
                return (
                  <>

                  </>
                )
              })
                :
                <center><h3>Selected Subject has No modules</h3></center>
            } */}
              </React.Fragment>
          }


        </div>}

    </div>
  )
}

export default AddQuestions;
