import React, { useEffect, useState } from "react";
import { allowedTypesImages } from "../../../../utils/variables/folderStructureFiles";
import { getCities, getCountries, getStates } from "../../../../Apis/getApis";
import {
  FaCaretDown,
  FaCloudUploadAlt,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { toast } from "react-toastify";
import { v4 as uuidV4 } from "uuid";
import {
  saveInstituteOnBoarding,
  verifiedEmailCheck,
  verifyEmail,
} from "../../../../Apis/postApis";
import Swal from "sweetalert2";
import {
  isValidEmail,
  successToast,
  uploadFile,
  validatePassword,
} from "../../../../utils/helperFunctions/HelperFunctions";
import validator from "validator";
import useToken from "../../../../customHook/useToken";
const InstituteOnboardingForm = () => {
  const access = useToken();
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cities, setCitiesList] = useState([]);
  const [loading, setLoading] = useState(false);
  // form fields
  const [buttonName, setButtonName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [entityName, setEntityName] = useState("");
  const [email, setEmail] = useState("");
  const [websiteName, setWebsiteName] = useState("");
  const [countryName, setCountryName] = useState("Country");
  const [city, setCity] = useState("City");
  const [state, setState] = useState("State");
  const [address, setAddress] = useState("");
  const [otherAddress, setOtherAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [otherPhone, setOtherPhone] = useState("");
  const [image, setImage] = useState("");
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState();
  const [cityId, setCityId] = useState();
  const [file, setFile] = useState(null);
  const [showPassword, setShowpassword] = useState(false);
  const [phoneCode, setPhoneCode] = useState("");
  const errorToast = (msg) => toast.error(msg);

  const checkEmail = () => {
    if (email !== "") {
      if (isValidEmail(email)) {
        verifyEmail({ email: email })
          .then((resData) => {
            verifiedEmailCheck({ email: email })
              .then((res) => {
                console.log(res, "resEmail");
                if (res?.data?.data == 1) {
                } else if (res?.data?.data == 0) {
                  errorToast("User with this email Id Already exist");
                  setEmail("");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        errorToast("Invalid Email id");
        setEmail("");
      }
    }
  };

  const passwordMatch = () => {
    if (password !== "" && !validatePassword(password)) {
      errorToast("Password with 1 digit, 1 upper and 1 special");
      setPassword("");
      return;
    }

    if (confirmPassword !== "" && !validatePassword(confirmPassword)) {
      errorToast("Password with 1 digit, 1 upper and 1 special");
      setConfirmPassword("");
      return;
    }

    if ((password !== "" && password.length <= 3) || password.length >= 16) {
      setPassword("");
      errorToast(
        "Password Must be more than 3 characters and less than 15 characters"
      );
      return;
    }
    if (
      (confirmPassword !== "" && confirmPassword.length <= 3) ||
      confirmPassword.length >= 16
    ) {
      setConfirmPassword("");
      errorToast(
        "ConfirmPassword Must be more than 3 characters and less than 15 characters"
      );
      return;
    }

    if (
      password !== "" &&
      confirmPassword !== "" &&
      password !== confirmPassword
    ) {
      errorToast("Password and confirm Password not matching");
      setPassword("");
      setConfirmPassword("");
      return;
    }
  };
  const [uploading, setUploading] = useState(false);

  function validUrl() {
    if (websiteName !== "") {
      if (!validator.isURL(websiteName)) {
        errorToast("Please enter valid url");
        setWebsiteName("");
        return;
      }
    }
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (allowedTypesImages.includes(selectedFile?.type)) {
      setFile(selectedFile);
      let res = uploadFile(selectedFile);
      res.then((data) => {
        setImage(data);
      });
    } else {
      errorToast("Invalid file type. Only images are allowed.");
    }
  };

  const getCountryById = (id) => {
    for (let i = 0; i < countriesList.length; i++) {
      if (countriesList[i]?.id == id) {
        return countriesList[i];
      }
    }
  };

  const getStateById = (id) => {
    for (let i = 0; i < stateList.length; i++) {
      if (stateList[i]?.id == id) {
        return stateList[i];
      }
    }
  };

  const getCityById = (id) => {
    for (let i = 0; i < cities.length; i++) {
      if (cities[i]?.id == id) {
        return cities[i];
      }
    }
  };

  const makeCountry = (item) => {
    if (item == undefined) {
      return;
    }
    setPhoneCode(item?.phone_code);
    setCountryName((prev) => (prev = item.name));
    setCountryId(item?.id);
    getStates(item.id)
      .then((res) => {
        setStateList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const makeState = (item) => {
    if (item == undefined) {
      return;
    }
    setState(item.name);
    setStateId(item?.id);
    setState((prev) => (prev = item.name));
    getCities(item.id)
      .then((res) => {
        setCitiesList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const makeCity = (item) => {
    if (item == undefined) {
      return;
    }
    setCityId(item?.id);
    setCity((prev) => (prev = item?.name));
  };
  const handleCancel = () => {
    setEmail("");
    setContactPerson("");
    setAddress("");
    setEntityName("");
    setPassword("");
    setCitiesList([]);
    setCity("");
    setCityId();
    setConfirmPassword("");
    setWebsiteName("");
    setPhone("");
    setOtherPhone("");
    setOtherAddress("");
    setStateList([]);
    setStateId();
    setState("");
  };
  const saveForm = (e) => {
    e.preventDefault();
    if (buttonName == "") {
      errorToast("Please Select Institute Or College");
      return;
    }
    if (contactPerson == "") {
      errorToast("Please Enter Contact person name");
      return;
    }
    if (password == "") {
      errorToast("Please Enter password");
      return;
    }
    if (confirmPassword == "") {
      errorToast("Please Select Confirm the password");
      return;
    }
    if (entityName == "") {
      errorToast(`Please Enter ${buttonName} Name`);
      return;
    }
    if (email == "") {
      errorToast("Please enter email Id");
      return;
    }
    if (websiteName == "") {
      errorToast("Please Enter Website URL");
      return;
    }
    if (countryId == "") {
      errorToast("Please Select the country");
      return;
    }
    if (address == "") {
      errorToast("Please Give address");
      return;
    }
    if (phone == "") {
      errorToast("Please Enter the phone number");
      return;
    }
    let object = {
      email: email,
      first_name: entityName,
      contact_person: contactPerson,
      website: websiteName,
      mobile_number1: phone,
      mobile_number2: otherPhone,
      address1: address,
      address2: otherAddress,
      state: stateId,
      city: cityId,
      country: countryId,
      user_type: "institute",
      password: password,
      status: "pending",
      profile_pic: image,
      pin_code: "string",
    };
    saveInstituteOnBoarding(object,access)
      .then((res) => {
        console.log(res);
        successToast("Institute Saved Successfully");
        handleCancel();
      })
      .catch((err) => {
        console.log(err);
        errorToast("Something Went wrong");
      });
  };

  useEffect(() => {
    setLoading(true);
    getCountries()
      .then((res) => {
        setLoading(false);
        setCountriesList(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <>
      {!uploading ? (
        <div
          className="boarding-form fluid-container"
          style={{ marginBottom: "20px", paddingLeft: "0px" }}
        >
          <div className="instituteOnboarding-list-view">
            <div className="headerPart">
              <h4>Add Institute / College</h4>
            </div>
          </div>
          {/* <form className='animate__animated animate__fadeIn main-form' style={{ maxWidth: "100%", margin: "auto" }}>

                <div className={width < 500 ? " col" : " row"} style={{ width: "100%" }}>
                    <div className={width < 500 ? 'form-group selectedField-row row' : 'form-group selectedField col'} style={{ paddingTop: "7px" }}>                        
                        <button type="button" className={width < 500 ? "btn drdpbtn sailors-Input-field col" : "btn drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
                        {buttonName}<FaCaretDown style={{ width: "15px" }} />
                        </button>
                        <div className="dropdown-menu" style={{ width: "95%", height: "100px" }}>
                            <span onClick={(e) => { setButtonName((prev) => prev = "Institute") }} className="dropdown-item" style={{ height: "50px" }}>Institute</span>
                            <span onClick={(e) => { setButtonName((prev) => prev = "College") }} className="dropdown-item" >College</span>
                        </div>
                    </div>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input value={contactPerson} onChange={(e) => setContactPerson(e.target.value)} type="text" className="form-control sailors-Input-field sailors-Input-field" placeholder="Contact Person" style={{ margin: "5px 0px 0px 5px" }} />
                    </div>
                </div>

                <div className={width < 500 ? "form-group col" : "form-group row"} style={{ marginRight: "20px", gap: "25px" }}>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input value={password} onBlur={()=>passwordMatch()} onChange={(e) => setPassword(e.target.value)} type="password" className="form-control sailors-Input-field sailors-Input-field" placeholder="Password" />
                    </div>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input value={confirmPassword} onBlur={()=>passwordMatch()} onChange={(e) => setConfirmPassword(e.target.value)} type="password" className="form-control sailors-Input-field sailors-Input-field" placeholder="Confirm Password" />
                    </div>
                </div>

                <div className={width < 500 ? "form-group col" : "form-group row"} style={{ marginRight: "20px", gap: "25px" }}>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input type="text" value={entityName} onChange={(e) => setEntityName(e.target.value)} className="form-control sailors-Input-field sailors-Input-field" placeholder={`${buttonName} Name`} />
                    </div>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input type="text" value={email} onBlur={checkEmail} onChange={(e) => setEmail(e.target.value)} className="form-control sailors-Input-field sailors-Input-field" placeholder="Email" />
                    </div>
                </div>

                <div className={width < 500 ? "form-group col" : "form-group row"} style={{ marginRight: "20px", gap: "30px" }}>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input value={websiteName} onChange={(e) => setWebsiteName(e.target.value)} type="text" className="form-control sailors-Input-field sailors-Input-field" placeholder='Website' />
                    </div>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'} style={{marginRight:"-16px"}}>
                        <button type="button" className={width < 500 ? "btn drdpbtn col sailors-Input-field" : "btn sailors-Input-field drdpbtn row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
                            {countryName}<FaCaretDown style={{width:"15px"}}/>
                        </button>
                        <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
                            {countriesList?.map((item) => {
                                return (
                                    <div key={uuidV4()} className="dropdown-item" style={{ height: "50px" }} onClick={() => makeCountry(item)}>{item?.name}</div>
                                )
                            })}
                            {
                                countriesList.length===0 && <h4>!Opps check the Internet </h4>
                            }
                        </div>
                    </div>
                </div>

                <div className={width < 600 ? "form-group col" : "form-group row"} style={{gap:"25px"}}>
                    <div className={width < 600 ? 'form-group selectedField-row row' : 'form-group selectedField col'}>
                    <button type="button" className={width < 500 ? "btn drdpbtn col sailors-Input-field" : "btn sailors-Input-field drdpbtn row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
                            {state}<FaCaretDown style={{width:"15px"}}/>
                        </button>
                        <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
                            {stateList?.map((item) => {
                                return (
                                    <div key={uuidV4()} className="dropdown-item" style={{ height: "50px" }} onClick={() => makeState(item)}>{item?.name}</div>
                                )
                            })}
                            {
                                stateList.length===0 && countryName==="Country" && <h5>Please select The Country</h5> 
                            }
                        </div>
                    </div>
                    <div className={width < 600 ? 'form-group row' : 'form-group col'} style={{marginRight:'-16px'}}>
                        <button type="button" className={width < 500 ? "btn drdpbtn col sailors-Input-field" : "btn sailors-Input-field drdpbtn row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
                            {city}<FaCaretDown style={{width:"15px"}}/>
                        </button>
                        <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
                            {cities?.map((item) => {
                                return (
                                    <div key={uuidV4()} className="dropdown-item" style={{ height: "50px" }} onClick={() => makeCity(item)}>{item?.name}</div>
                                )
                            })}
                            {
                                cities.length===0 && state==="State" && <h5>Please select The State</h5> 
                            }
                        </div>
                    </div>

                    <div className={width < 600 ? "form-group col" : "form-group row"}>
                        <div className={width < 600 ? 'form-group selectedField-row  row' : 'form-group selectedField ntb col'}>
                            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} className="form-control sailors-Input-field sailors-Input-field" placeholder="Address" style={{ padding: "0px 10px 0px 10px", margin: "0px -12px 0px -21px" }} />
                        </div>
                        <div className={width < 600 ? 'form-group row' : 'form-group col'} style={{marginRight:"-16px"}}>
                            <input type="text" value={otherAddress} onChange={(e) => { setOtherAddress(e.target.value) }} className="form-control sailors-Input-field sailors-Input-field" placeholder="Other Address" />
                        </div>
                    </div>
                

                    <div className={width < 600 ? "form-group col" : "form-group row"}>
                        <div className={width < 600 ? 'form-group selectedField-row row' : 'form-group selectedField col'}>
                            <form className="form-inline">
                                <div className="input-group">
                                    <div className="input-group-prepend" style={{ maxWidth: "70px",height:"46px" }}>
                                        <div className="input-group-text"> {phoneCode!==""?phoneCode:"📞"}</div>
                                    </div>
                                    <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control sailors-Input-field numberField sailors-Input-field" id="inlineFormInputGroupUsername2" placeholder="Contact Number" style={{ padding: "0px 10px 0px 10px", margin: "0px -12px 0px -21px" }}/>
                                </div>
                            </form>
                        </div>
                        <div className={width < 600 ? 'form-group row' : 'form-group col'}>
                            <form className="form-inline">
                                <div className="input-group">
                                <div className="input-group-prepend" style={{ maxWidth: "70px",height:"46px" }}>
                                        <div className="input-group-text"> {phoneCode!==""?phoneCode:"📞"}</div>
                                    </div>
                                    <input type="text" value={otherPhone} onChange={(e) => setOtherPhone(e.target.value)} className="form-control sailors-Input-field sailors-Input-field numberField" id="inlineFormInputGroupUsername2" placeholder="Other Contact Number" style={{ padding: "0px 10px 0px 10px", margin: "0px -12px 0px -21px" }}/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className={width < 600 ? 'form-group row' : 'form-group col'}>
                            <form className="form-inline">
                                <div className="input-group">
                                    <input type="file" className='uploadLogo-Input' placeholder='Images' title='Upload Logo' onChange={(e)=>handleFileChange(e)}/>
                                </div>

                            </form>
                        </div>


                </div>
                <div className='form-btns'>
                    <button onClick={(e)=>saveForm(e)} type="submit" className="btn">Save</button>
                    <button type='button' onClick={handleCancel} className="btn btn-OnboardForm">Cancel</button>
                </div>

            </form> */}

          {/* Bootstrap Form */}

          <form
            className="fluid-container mt-2 p-4 bg-white position-relative"
            style={{
              boxShadow: "-1px 3px 20px 0px #0000001A",
              borderRadius: "10px",
            }}
          >
            {/* <span className='mustStar' style={{textAlign:'end', fontSize:"15px", position:'absolute',top:"-20px",left:"-16px"}}>Fields marked (*) are mandatory.</span> */}
            <div className="row mb-4 d-flex flex-col gap-y-4 md:gap-y-0 md:flex-row justify-content-around">
              <div className="col-md-5">
                {/* <label className="form-label" htmlFor="institute">
                                Institute/College<span className='mustStar'>*</span>
                            </label> */}
                <select
                  style={{
                    boxShadow: "-1px 3px 20px 0px #0000001A",
                    minHeight: "40px",
                  }}
                  id="institute"
                  value={buttonName}
                  onChange={(e) => setButtonName(e.target.value)}
                  className="form-select sailors-Input-field"
                >
                  <option value="">Select Institute/College </option>
                  <option value={"Institute"}>Institute</option>
                  <option value={"College"}>College</option>
                </select>
              </div>
              <div className="col-md-5">
                {/* <label className="form-label" htmlFor="contactPerson">
                                Contact Person<span className='mustStar'>*</span>
                            </label> */}
                <input
                  style={{ boxShadow: "-1px 3px 20px 0px #0000001A" }}
                  placeholder="Contact Person"
                  required
                  id="contactPerson"
                  type="text"
                  value={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                  className="sailors-Input-field pl-2"
                />
              </div>
            </div>

            <div className="row mb-0 d-flex flex-row justify-content-around">
              <div className="col-md-5">
                
                <input
                  style={{ boxShadow: "-1px 3px 20px 0px #0000001A" }}
                  placeholder="Password"
                  required
                  id="password"
                  value={password}
                  onBlur={() => passwordMatch()}
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  className="pl-2 sailors-Input-field"
                />
                {!showPassword ? (
                  <FaEyeSlash
                    onClick={() => setShowpassword(!showPassword)}
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      color: "#0000004D",
                      width: "25px",
                      height: "25px",
                      right: "22",
                      top: "10",
                    }}
                  />
                ) : (
                  <FaEye
                    onClick={() => setShowpassword(!showPassword)}
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      color: "#0000004D",
                      width: "25px",
                      height: "25px",
                      right: "22",
                      top: "10",
                    }}
                  />
                )}
                <p
                  className="mt-0"
                  style={{ fontSize: "10px", color: "#76767680" }}
                >
                  Password with 1 digit, 1 upper and 1 special
                </p>
              </div>
              <div className="col-md-5">
                {/* <label className="form-label" htmlFor="confirmPassword">
                                Confirm Password<span className='mustStar'>*</span>
                            </label> */}
                <input
                  style={{ boxShadow: "-1px 3px 20px 0px #0000001A" }}
                  placeholder="Confirm Password"
                  required
                  id="confirmPassword"
                  value={confirmPassword}
                  onBlur={() => passwordMatch()}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  className="pl-2 sailors-Input-field"
                />
                {!showPassword ? (
                  <FaEyeSlash
                    onClick={() => setShowpassword(!showPassword)}
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      color: "#0000004D",
                      width: "25px",
                      height: "25px",
                      right: "22",
                      top: "10",
                    }}
                  />
                ) : (
                  <FaEye
                    onClick={() => setShowpassword(!showPassword)}
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      color: "#0000004D",
                      width: "25px",
                      height: "25px",
                      right: "22",
                      top: "10",
                    }}
                  />
                )}
                <p
                  className="mt-0"
                  style={{ fontSize: "10px", color: "#76767680" }}
                >
                  Password with 1 digit, 1 upper and 1 special
                </p>
              </div>
            </div>

            <div className="row mb-4 d-flex flex-col md:flex-row gap-y-4 md:gap-y-0 justify-content-around">
              <div className="col-md-5">
                {/* <label className="form-label" htmlFor="instituteName">
                                Institute/College Name<span className='mustStar'>*</span>
                            </label> */}
                <input
                  style={{ boxShadow: "-1px 3px 20px 0px #0000001A" }}
                  placeholder="Institute/College Name"
                  required
                  id="instituteName"
                  value={entityName}
                  onChange={(e) => setEntityName(e.target.value)}
                  type="text"
                  className="pl-2 sailors-Input-field"
                />
              </div>
              <div className="col-md-5">
                {/* <label className="form-label" htmlFor="email">
                                Email<span className='mustStar'>*</span>
                            </label> */}
                <input
                  style={{ boxShadow: "-1px 3px 20px 0px #0000001A" }}
                  placeholder="Email"
                  required
                  id="email"
                  value={email}
                  onBlur={checkEmail}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="pl-2 sailors-Input-field"
                />
              </div>
            </div>

            <div className="row mb-4 d-flex flex-row justify-content-around">
              <div className="col-md-5">
                {/* <label className="form-label" htmlFor="websiteName">
                                Website URL<span className='mustStar'>*</span>
                            </label> */}
                <input
                  style={{ boxShadow: "-1px 3px 20px 0px #0000001A" }}
                  placeholder="Website URL"
                  id="websiteName"
                  value={websiteName}
                  onChange={(e) => setWebsiteName(e.target.value)}
                  onBlur={validUrl}
                  type="text"
                  className="pl-2 sailors-Input-field"
                />
              </div>
              <div className="col-md-5">
                {/* <label className="form-label" htmlFor="country">
                                Country<span className='mustStar'>*</span>
                            </label> */}
                <select
                  id="country"
                  style={{
                    minHeight: "40px",
                    boxShadow: "-1px 3px 20px 0px #0000001A",
                  }}
                  value={countryId}
                  onChange={(e) => makeCountry(getCountryById(e.target.value))}
                  className="form-select sailors-Input-field"
                >
                  <option value="">Select Country</option>
                  {countriesList?.map((item) => {
                    return (
                      <option
                        value={item?.id}
                        key={uuidV4()}
                        className="dropdown-item"
                        style={{ height: "50px" }}
                      >
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row mb-4 d-flex flex-row justify-content-around">
              <div className="col-md-5">
                {/* <label className="form-label" htmlFor="state">
                                State
                            </label> */}
                <select
                  id="state"
                  style={{
                    minHeight: "40px",
                    boxShadow: "-1px 3px 20px 0px #0000001A",
                  }}
                  value={stateId}
                  onChange={(e) => makeState(getStateById(e.target.value))}
                  className="form-select sailors-Input-field"
                >
                  <option>Select State</option>
                  {stateList?.map((item) => {
                    return (
                      <option
                        value={item.id}
                        key={uuidV4()}
                        className="dropdown-item"
                        style={{ height: "50px" }}
                      >
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-5">
                {/* <label className="form-label" htmlFor="city">
                                City
                            </label> */}
                <select
                  id="city"
                  value={cityId}
                  style={{
                    minHeight: "40px",
                    boxShadow: "-1px 3px 20px 0px #0000001A",
                  }}
                  onChange={(e) => makeCity(getCityById(e.target.value))}
                  className="form-select sailors-Input-field"
                >
                  <option>Select City</option>
                  {cities?.map((item) => {
                    return (
                      <option
                        value={item?.id}
                        key={uuidV4()}
                        className="dropdown-item"
                        style={{ height: "50px" }}
                      >
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row mb-4 d-flex flex-row justify-content-around">
              <div className="col-md-5">
                {/* <label className="form-label" htmlFor="address">
                                Address<span className='mustStar'>*</span>
                            </label> */}
                <textarea
                  rows="4"
                  placeholder="Address"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                  className="p-2 text-base sailors-Input-field"
                />
              </div>
              <div className="col-md-5">
                {/* <label className="form-label" htmlFor="otherAddress">
                                Other Address
                            </label> */}
                <textarea
                  rows="4"
                  placeholder="Enter Description"
                  id="otherAddress"
                  value={otherAddress}
                  onChange={(e) => {
                    setOtherAddress(e.target.value);
                  }}
                  type="text"
                  className="form-control sailors-Input-field"
                />
              </div>
            </div>

            <div className="row mb-4 d-flex flex-row justify-content-around">
              <div className="col-md-5">
                {/* <label className="form-label" htmlFor="contactNumber">
                                Contact Number<span className='mustStar'>*</span>
                            </label> */}
                <div className="input-group">
                  <div
                    className="input-group-text sailors-Input-field"
                    style={{ maxWidth: "70px", textAlign: "center" }}
                  >
                    {" "}
                    {phoneCode !== "" ? phoneCode : "📞"}
                  </div>
                  <input
                    placeholder="Phone 1"
                    id="contactNumber"
                    type="number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="form-control sailors-Input-field"
                  />
                </div>
              </div>
              <div className="col-md-5">
                {/* <label className="form-label" htmlFor="otherContactNumber">
                                Other Contact Number
                            </label> */}
                <div className="input-group">
                  <div
                    className="input-group-text sailors-Input-field"
                    style={{ maxWidth: "70px", textAlign: "center" }}
                  >
                    {" "}
                    {phoneCode !== "" ? phoneCode : "📞"}
                  </div>
                  <input
                    value={otherPhone}
                    placeholder="Phone 2"
                    onChange={(e) => setOtherPhone(e.target.value)}
                    id="otherContactNumber"
                    type="number"
                    className="form-control sailors-Input-field"
                  />
                </div>
              </div>
            </div>

            <div className="mb-4 d-flex flex-row justify-content-around ">
              <label className="form-label flex w-auto" htmlFor="uploadLogo">
                Upload Logo{" "}
                <FaCloudUploadAlt
                  style={{ width: "30px", marginLeft: "10px" }}
                />
              </label>
              <input
                id="uploadLogo"
                type="file"
                onChange={(e) => handleFileChange(e)}
                className="col-md-5 w-50 p-2 px-5 sailors-Input-field"
              />
            </div>

            <div
              className="form-btns"
              style={{ marginBottom: "20px", marginTop: "50px" }}
            >
              <button
                type="submit"
                onClick={(e) => saveForm(e)}
                className="btn"
              >
                Save
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="btn btn-OnboardForm"
                style={{ border: "1px solid #005A80" }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%", height: "100%" }}
        >
          <img
            loading="lazy"
            src="/assets/commonAssets/yelloShip.gif"
            alt="loader"
            style={{ width: "200px", height: "200px", borderRadius: "50%" }}
          />
        </div>
      )}
    </>
  );
};

export default InstituteOnboardingForm;
