import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { searchStudent } from "../../../../Apis/getApis";
import LoaderSmall from "../../../../common/loader/LoaderSmall";

const DashboardCardsInstitute = ({ profileVisits, totalCourses }) => {
  const [searchBy, setSearchBy] = useState("");
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let timer;
    if (search !== "") {
      setLoading(true);
      setSearchData([]);
      timer = setTimeout(() => {
        searchStudent(searchBy, search)
          .then((res) => {
            setSearchData(res?.data?.data?.results);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }, 1000);
    } else {
      setLoading(false);
      setSearchData([]);
    }

    return () => {
      clearTimeout(timer);
      setLoading(false);
    };
  }, [search]);

  return (
    <div className="flex flex-col md:flex-row flex-1 gap-2 w-full" style={{ margin: "auto" }}>
      <div
        className="card w-full md:w-1/3 h-[28rem]"
        style={{
          borderTop: "4px solid #63c3ec",
          boxShadow: "-1px 3px 20px 0px #0000001A",
        }}
      >
        <div className="card-body">
          <div className="d-flex flex-column h-50 mb-5">
            <div
              className="d-flex justify-content-center align-items-center m-auto mb-4"
              style={{
                width: "50px",
                height: "80px",
                background: "#2A737A14",
                borderRadius: "50%",
              }}
            >
              <img
                src="/assets/icon/mark.png"
                alt="img"
                style={{ width: "24px", height: "24px", margin: "8px 2px" }}
              />
            </div>
            <div className="w-auto w-50">
              <h5
                className="card-title ms-2"
                style={{
                  textDecoration: "underline 1px #2A737A66",
                  textUnderlineOffset: "10px",
                  color: "#337B99",
                }}
              >
                Courses
              </h5>
            </div>
            <h6
              className="card-subtitle mb-2 text-muted mt-2 ms-2 d-flex w-100 justify-content-between"
              style={{ fontSize: "14px", fontWeight: "400" }}
            >
              - Total Courses :
              <span className="w-25 font-weight-bold text-dark">
                {totalCourses}
              </span>{" "}
              {/*<FaEye style={{ width: "30px", height: "25px", color: "#3FBDC9", border: "1px solid #3FBDC9", padding: "2px", borderRadius: "5px", cursor: 'pointer' }} />*/}
            </h6>
          </div>
        </div>
      </div>

      <div
        className="card w-full md:w-1/3 h-[28rem]"
        style={{        
          borderTop: "4px solid #63c3ec",
          boxShadow: "-1px 3px 20px 0px #0000001A",
        }}
      >
        <div className="card-body">
          <div className="d-flex flex-column h-50 mb-5">
            <div
              className="d-flex justify-content-center align-items-center m-auto mb-4"
              style={{
                width: "50px",
                height: "80px",
                background: "#2A737A14",
                borderRadius: "50%",
                boxShadow: "-1px 3px 20px 0px #0000001A",
              }}
            >
              <img
                src="/assets/icon/mark.png"
                alt="img"
                style={{ width: "24px", height: "24px", margin: "8px 2px" }}
              />
            </div>
            <div className="w-auto w-50">
              <h5
                className="card-title ms-2"
                style={{
                  textDecoration: "underline 1px #2A737A66",
                  textUnderlineOffset: "10px",
                  color: "#337B99",
                }}
              >
                Profile Visitors
              </h5>
            </div>
            <h6
              className="card-subtitle mb-2 text-muted mt-2 ms-2 d-flex w-100 justify-content-between"
              style={{ fontSize: "14px", fontWeight: "400" }}
            >
              - Total Profile Visitors:
              <span className="w-25 font-weight-bold text-dark">
                {profileVisits}
              </span>
            </h6>
          </div>
        </div>
      </div>

      <div
        className="card md:w-1/3 h-[28rem]"
        style={{
          width: "auto",
          minWidth: "28rem",
          maxWidth: "100%",
          height: "20rem",
          borderTop: "4px solid #63c3ec",
          boxShadow: "-1px 3px 20px 0px #0000001A",
        }}
      >
        <div className="card-body">
          <div className="d-flex flex-column h-50 mb-5 gap-y-2">
            <div className="w-auto w-50">
              <h5
                className="card-title ms-2"
                style={{
                  textDecoration: "underline 1px #2A737A66",
                  textUnderlineOffset: "10px",
                  color: "#337B99",
                }}
              >
                Search Student / Seafarer
              </h5>
            </div>
            <div
              className="d-flex h-50 flex-row jusitify-content-center align-items-center rounded-md"
              style={{
                color: "#777777",
                border: "1px solid #005A80",
                borderRadius: "5px",
              }}
            >
              <BiSearch className="w-25 h-50" style={{ maxWidth: "45px" }} />
              <select
                className="h-50 outline-0 border-0"
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
              >
                <option value="" style={{ color: "#777777" }}>
                  Search By
                </option>
                <option value="indos_number">Indos Number</option>
                <option value="cdc_number">CDC Number</option>
                <option value="name">Name</option>
              </select>
            </div>
            {searchBy !== "" && (
              <input
                type="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="form-control mt-1"
                placeholder="search..."
              />
            )}
            <div>

              <div
              className={`border-[1px] border-slate-500 ${searchData.length>0 ? "pt-20 shadow-sm shadow-slate-400 rounded-md" :"pt-0"}  w-full h-full overflow-y-auto flex flex-col items-center justify-center`}
              >
                {searchData.length > 0 ? (
                  searchData.map((item) => {
                    return (
                      <div
                        style={{
                          maxHeight: "100px",
                          height: "72px",
                          display: "flex",
                          paddingLeft: "5px",
                          flexDirection: "column",
                          borderBottom: "1px solid gray",
                        }}
                      >
                        <div>
                          <strong className="text-base text-black/75">Name: </strong>
                          <span className="text-sm ml-2">{" " + item?.first_name}</span>
                        </div>
                        <div>
                          <strong className="text-base text-black/75">CDC Number: </strong>
                          <span className="text-sm ml-2">{item?.cdc_number || "NA"}</span>
                        </div>
                        <div>
                          <strong className="text-base text-black/75">Indos Number: </strong>
                          <span className="text-sm ml-2">{item?.indos_number || "NA"}</span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <center className="text-base text-slate-700 w-full">{loading ? <LoaderSmall/> : "Search Students/seafarers"}</center>
                )}
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCardsInstitute;
