import axios from 'axios';
import { base_url } from '../utils/endpoints/endpoints';
import { useSelector } from 'react-redux';
// function getAccessToken() {
//     const token = JSON.parse(localStorage.getItem('token'));
//     return token?.access || null; // Always return a value (either token or null)
// }

// const access = getAccessToken();


export const login = ( body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            // "Access-Control-Allow-Origin": "*",
            // Accept: 'application/json',
            // "Cache-Control":"no-cache"
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/login/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err);
        });
    });
}

export const verifyEmail = (body)=>{
    const option = {
        headers:{
            // 'Content-type':"application/json",
            // "Access-Control-Allow-Origin": "*",
            // Accept: 'application/json',
            // "Cache-Control":"no-cache"
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/forgot_password/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err);
        })
    })
}

export const verifyOtp = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            // "Access-Control-Allow-Origin": "*",
            // Accept: 'application/json',
            // "Cache-Control":"no-cache"
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/email-verification/otp/verify/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveCourse = (body, access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/course/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const resetPassword = (body,access)=>{
    
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/password_reset/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const saveUserShip = (body,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/users/ship_types/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const saveJobCategory = (body,access)=>{
    
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/job/job_category/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const saveSubject = (body,access)=>{
    
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/course/subjects/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const saveInstituteOnBoarding = (body,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/users/register/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveCourseModule = (body,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/course/course_modules/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveJobRank = (body,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/job/job_roles/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveCreatedCertificate = (body,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/users/certificate_types/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveCertificateExpiry = (body, access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/utilities/todo_notification_schedule/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveRankQuestions = (body,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/utilities/questions/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveModuleContentData = (body,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/course/modules/course_module_docs/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveNewsAndAnnouncement = (body,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/utilities/news_announcements/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveAd = (body,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/utilities/ads/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveInstituteCourse = (body,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/course/course_details/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const RegisterNewUser = (body,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/register/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const verifiedEmailCheck =(body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/check_email/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
} 

export const sendUserOtpEmail = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/email-onboarding/otp/send/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const verifyUserOtpEmail = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/email-onboarding/otp/verify/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
    
}

export const postJob = (body,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/job/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveAdminScheduleCourse = (body,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/course/course_details/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveShip = (body, access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/users/ship/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


export const saveFollowup = (body, access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/users/follow_up/`,body,option)
        .then((res)=>{
            resolve(res);
        })
        .catch((err)=>{
            reject(err);
        })
    })
}