import React, { useEffect, useState } from "react";
import "./adminReportStyle.css";
import { getInstituteList, loadMoreData } from "../../../../../Apis/getApis";
import InstituteFollowCard from "../../../../../Institute/Components/DashboardComponents/instituteFinalCard/InstituteFollowCard";
import { followpDummyData } from "../../../../../utils/variables/folderStructureFiles";
import { FaEye } from "react-icons/fa";
import { errorToast } from "../../../../../utils/helperFunctions/HelperFunctions";
const AdminInstituteReport = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [institutesData, setInstitutesData] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [fromDate1, setFromDate1] = useState("");
  const [toDate1, setToDate1] = useState("");
  const [range1, setRange1] = useState("");
  useEffect(() => {
    console.log(selectedInstitute, "selectedInstitute");
  }, [selectedInstitute]);

  const dateErrorFunction = () => {
    if (fromDate > toDate && fromDate !== "" && toDate !== "") {
      errorToast("From Date Should be smaller than To Date");
      setFromDate1("");
      setToDate1("");
    }
  };

  function getAllInstitutes() {
    function loadData(resNext) {
      if (resNext == null) {
        return;
      }

      loadMoreData(resNext)
        .then((res) => {
          setInstitutesData((prev) => [...prev, ...res?.data?.data?.results]);
          loadData(res?.data?.data?.next);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    getInstituteList("accepted")
      .then((res) => {
        setTotalCount(res?.data?.data?.count);
        setInstitutesData(res?.data?.data?.results);
        loadData(res?.data?.data?.next);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getAllInstitutes();
  }, []);

  return (
    <div className="w-100 mt-5 container">
      <div
        className="w-auto max-w-52 justify-between flex flex-row items-center h-auto rounded-lg py-2 px-3"
        style={{ background: "#005A80", color: "white" }}
      >
          <span>
            Total Institutes
          </span>
          <span className="text-base w-auto">
          {totalCount} 
          </span>
             </div>
      <div className="table-container" style={{ marginLeft: "-17px" }}>
        <div className="allDropDown allField-learning-courses">
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Period<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9 d-flex justify-content-start">
              <input
                type="text"
                style={{ maxWidth: "180px" }}
                placeholder="From date 🗓️"
                className="dateInput sailors-Input-field"
                onFocus={(e) => (e.target.type = "date")}
                value={fromDate}
                onBlur={(e) => {
                  if (fromDate == "") {
                    e.target.type = "text";
                  }
                }}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <p
                className="w-25 m-auto"
                style={{ maxWidth: "30px", textAlign: "center" }}
              >
                To
              </p>
              <input
                type="text"
                style={{ maxWidth: "180px" }}
                placeholder="To date 🗓️"
                className="dateInput sailors-Input-field"
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => {
                  if (toDate == "") {
                    e.target.type = "text";
                  }
                }}
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Range</label>
            <div className="col-sm-9">
              <select className="form-select sailors-Input-field bg-white">
                <option>Select Range</option>
                <option value="one_week">1W</option>
                <option value="one_month">1M</option>
                <option value="three_month">3M</option>
                <option value="six_month">6M</option>
                <option value="one_year">1Y</option>
                <option value="three_year">3Y</option>
                <option value="five_year">5Y</option>
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Select Institute</label>
            <div className="col-sm-9">
              <select
                value={JSON.stringify(selectedInstitute)}
                onChange={(e) =>
                  setSelectedInstitute(JSON.parse(e.target.value))
                }
                className="form-select sailors-Input-field bg-white"
              >
                <option value="">Select Institute</option>
                {institutesData.length > 0 ? (
                  institutesData?.map((item, index) => {
                    return (
                      <option value={JSON.stringify(item)}>
                        {item?.first_name}
                      </option>
                    );
                  })
                ) : (
                  <center style={{ marginTop: "10%" }}>
                    !Opps check the Internet
                  </center>
                )}
              </select>
            </div>
          </div>

          <div className="form-btns">
            <button type="submit" className="btn">
              Search
            </button>
            <button
              type="button"
              className="btn btn-OnboardForm"
              style={{ border: "1px solid #005A80" }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      {selectedInstitute && (
        <div
          className="table-container animate__animated animate__fadeIn"
          style={{ marginLeft: "-17px" }}
        >
          <div className="allDropDown d-flex flex-row justify-content-between w-100 allField-learning-courses">
            <div
              className="w-50 rounded row"
              style={{ background: "#005A80", color: "white" }}
            >
              <h4 className="col-lg-12 col-12 col-md-12 col-sm-6 col-xs-4 mt-2">
                {selectedInstitute?.first_name}
              </h4>
            </div>
            <div
              className="col-lg-3 rounded row"
              style={{ background: "#005A80", color: "white" }}
            >
              <h6
                className=" text-center mt-2 text-sm"
                style={{ textAlign: "center" }}
              >
                <span>Available Courses</span> <span>{totalCount}</span>
              </h6>
            </div>
          </div>

          <div className="allDropDown allField-learning-courses">
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">
                Period<span className="mustStar">*</span>
              </label>
              <div className="col-sm-9 d-flex justify-content-start">
                <input
                  type="text"
                  style={{ maxWidth: "180px" }}
                  placeholder="From date 🗓️"
                  className="dateInput sailors-Input-field"
                  onFocus={(e) => (e.target.type = "date")}
                  value={fromDate}
                  onBlur={(e) => {
                    if (fromDate == "") {
                      e.target.type = "text";
                    }
                  }}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <p
                  className="w-25 m-auto"
                  style={{ maxWidth: "30px", textAlign: "center" }}
                >
                  To
                </p>
                <input
                  type="text"
                  style={{ maxWidth: "180px" }}
                  placeholder="To date 🗓️"
                  className="dateInput sailors-Input-field"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                  }}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => {
                    if (toDate == "") {
                      e.target.type = "text";
                    }
                  }}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">Range</label>
              <div className="col-sm-9">
                <select className="form-select sailors-Input-field bg-white">
                  <option>Select Range</option>
                  <option value="one_week">1W</option>
                  <option value="one_month">1M</option>
                  <option value="three_month">3M</option>
                  <option value="six_month">6M</option>
                  <option value="one_year">1Y</option>
                  <option value="three_year">3Y</option>
                  <option value="five_year">5Y</option>
                </select>
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">Select course</label>
              <div className="col-sm-9">
                <select
                  value={JSON.stringify(selectedCourse)}
                  onChange={(e) =>
                    setSelectedCourse(JSON.parse(e.target.value))
                  }
                  className="form-select sailors-Input-field bg-white"
                >
                  <option value="">Select Course</option>
                  {institutesData.length > 0 ? (
                    institutesData?.map((item, index) => {
                      return (
                        <option value={JSON.stringify(item)}>
                          {item?.first_name}
                        </option>
                      );
                    })
                  ) : (
                    <center style={{ marginTop: "10%" }}>
                      !Opps check the Internet
                    </center>
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedCourse && (
        <div
          className="h-100 m-2 md-row-2 xs-row-1 w-auto mb-6 mt-5 allDropDown allField-learning-courses"
          style={{
            minWidth: "calc(100%/2.3)",
            maxWidth: "auto",
            borderRadius: "20px",
            borderTop: "4px solid #0000001A",
            background: "#FFFFFF",
            boxShadow: "-1px 3px 20px 0px #0000001A",
          }}
        >
          <div
            className="d-flex w-100 justify-content-between flex-row flex-wrap"
            style={{ maxHeight: "350px" }}
          >
            <p
              className="ms-4 mt-2 w-auto"
              style={{
                fontWeight: "500",
                fontSize: "18px",
                color: "#337B99",
                textDecoration: "underline 1px #2A737A66",
                textUnderlineOffset: "10px",
              }}
            >
              Candidates Data{" "}
            </p>
            <div className="w-50 ">
              <input
                type="text "
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => {
                  if (fromDate1 == "") {
                    e.target.type = "text";
                  }
                  dateErrorFunction();
                }}
                value={fromDate1}
                onChange={(e) => {
                  setFromDate1(e.target.value);
                  setRange1("");
                }}
                className="w-50 dateBox"
                placeholder="From Date"
              />
              <input
                type="text"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => {
                  if (toDate1 == "") {
                    e.target.type = "text";
                  }
                  dateErrorFunction();
                }}
                value={toDate1}
                onChange={(e) => {
                  setToDate1(e.target.value);
                  setRange1("");
                }}
                className="w-50 dateBox"
                placeholder="To Date"
              />

              <select
                className="w-50 m-1 h-50 p-1 rounded position-relative top-0 end-25"
                value={range1}
                onChange={(e) => {
                  setRange1(e.target.value);
                  setFromDate1("");
                  setToDate1("");
                }}
                style={{ border: "1px solid #005A80", maxHeight: "35px" }}
              >
                <option>Select Range</option>
                <option value="one_day">1D</option>
                <option value="one_week">1W</option>
                <option value="one_month">1M</option>
                <option value="three_month">3M</option>
                <option value="six_month">6M</option>
                <option value="one_year">1Y</option>
                <option value="three_year">3Y</option>
                <option value="five_year">5Y</option>
              </select>
            </div>
          </div>

          <div className="w-100 p-4">
            {followpDummyData.map((item) => {
              return (
                <div
                  className="w-100 m-auto d-flex justify-content-around flex-row pe-3 pt-2"
                  style={{ borderRight: "1px solid #2A737A66" }}
                >
                  <p
                    className="w-25 me-2"
                    style={{
                      borderRight: "1px solid black",
                      padding: "0px 5px",
                      height: "100%",
                    }}
                  >
                    {item?.name}
                  </p>
                  <div
                    className="colorDivWrapper w-75 me-5"
                    style={{ border: "none", height: "25px" }}
                  >
                    <div
                      className="colorDiv"
                      style={{
                        height: "100%",
                        width: `${item?.percent?.length}%`,
                        background: `${item?.color}`,
                        textAlign: "end",
                        paddingRight: "5px",
                      }}
                    >
                      {item.percent?.length}
                    </div>
                  </div>
                  <FaEye
                    style={{
                      width: "30px",
                      height: "25px",
                      color: "#3FBDC9",
                      border: "1px solid #3FBDC9",
                      padding: "2px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminInstituteReport;
