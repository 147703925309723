
import React, { useEffect, useState } from 'react'
import { BallTriangle, TailSpin } from 'react-loader-spinner';
import { FaCaretDown, FaEye, FaEyeSlash, FaPen } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Swal from 'sweetalert2';
import ResponsivePagination from 'react-responsive-pagination';
import axios from 'axios';
import { toast } from 'react-toastify';
import { courseCategoryApi, courseTypeApi, getCountries, GetLearningCourses, getRolesAndRank, loadMoreData, requestCourseListInstitute } from '../../../Apis/getApis';
import { saveCourse } from '../../../Apis/postApis';
import { UpdateLearningCourse } from '../../../Apis/updateApis';
import { DeleteLearningCourse } from '../../../Apis/deleteApis';
import { allowedTypesImages } from '../../../utils/variables/folderStructureFiles';
import { errorToast, uploadFile } from '../../../utils/helperFunctions/HelperFunctions';
import { searchCourseApi } from '../../../Apis/searchApis';
import { base_url } from '../../../utils/endpoints/endpoints';
import useToken from '../../../customHook/useToken';
const CourseRequest = () => {
    const access = useToken();
    const [courseData, setCourseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [usersToShow, setUsersToShow] = useState('');
    const [courseName, setCourseName] = useState('');
    const [country, setCountries] = useState([]);
    const [forSeafarer, setForSeafarer] = useState(false);
    const [forFresher, setForFresher] = useState(false);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [courseDescription, setCourseDescription] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [courseCategory, setCourseCategory] = useState('Select Course Category');
    const [courseCategoryList, setCourseCategoryList] = useState([]);
    const [courseType, setCourseType] = useState('Select Course type');
    const [courseTypeList, setCourseTypeList] = useState([]);
    const [image, setImage] = useState('');
    const [updateData, setUpdateData] = useState();
    const [jobRoleList, setJobRoleList] = useState([]);
    const [selectedJobRoles, setSelectedJobRoles] = useState([]);
    const [courseSearch, setCourseSearch] = useState('');
    const [searchCourseData, setSearchCourseData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [searchJob,setSearchJob] = useState('');
    const [searchJobData,setSearchJobData] = useState([]);
    const [next, setNext] = useState(null);
    const [prev, setPrev] = useState(null);
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const [isCet, setIsCet] = useState(false);
    const [isCoc, setIsCoc] = useState(false);
    // const learningCourses =useSelector((state)=>state?.adminSlice?.learningCourse);
    const [search, setSearch] = useState('');
    const [searchData, setSearchData] = useState([])
    const addCountry = (countryData) => {
      const isThere = selectedCountries.some((item) => isEdit ? item.id == countryData.id : item == countryData.id);
      if (!isThere) {
        if (isEdit) {
          setSelectedCountries([...selectedCountries, { id: countryData.id, name: countryData.name }])
        } else {
          setSelectedCountries([...selectedCountries, countryData.id])
        }
      }
    }
  
    const pagination = (e) => {
  
      loadMoreData(`${base_url}/api/v1/web/course/?page=${e}`)
        .then((res) => {
          setCourseData(res?.data?.data?.results);
        })
        .catch((err) => {
          console.log(err)
        })
  
      setCurrentPage(e);
    }
  
    const addJobRole = (jobData) => {
      const isThere = selectedJobRoles.some((item) => isEdit ? item.id == jobData.id : item == jobData.id);
      if (!isThere) {
        if (isEdit) {
          setSelectedJobRoles([...selectedJobRoles, jobData.id])
        } else {
          setSelectedJobRoles([...selectedJobRoles, jobData.id])
        }
      }
    }
  
    const getJobRoleById = (id) => {
      for (let i = 0; i < jobRoleList.length; i++) {
        if (jobRoleList[i]?.id == id) {
          return jobRoleList[i].name
        }
      }
    }
  
  
    const editData = (item) => {
      console.log(item);
      setIsEdit(true);
      setCourseDescription(item.course_description);
      setCourseName(item.course_name)
      setUpdateData(item);
      setCourseType(item?.course_type)
      setCourseCategory(item?.course_category)
      setSelectedCountries(item.countries)
      setForFresher(item?.for_fresher);
      setForSeafarer(item?.for_seafarer);
      setIsCet(item?.is_cet);
      setIsCoc(item?.is_coc);
    }
  
    // const getCourseCategoryById = (id) => {
    //   for (let i = 0; i < courseCategoryList.length; i++) {
    //     if (courseCategoryList[i].id == id) {
    //       return courseCategoryList[i].name
    //     }
    //   }
  
    //   return "All"
    // }
  
    // const getCourseTypeById = (id) => {
    //   for (let i = 0; i < courseTypeList.length; i++) {
    //     if (courseTypeList[i].id == id) {
    //       return courseTypeList[i].name
    //     }
    //   }
  
    //   return "Select Course Type"
    // }
    // const [file, setFile] = useState(null);
    // const [uploading, setUploading] = useState(false);
  
  
    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      if (allowedTypesImages.includes(selectedFile?.type)) {
        // setFile(selectedFile);
        let res = uploadFile(selectedFile);
        res.then((data) => {
          console.log(data, "uploadedImage")
          setImage(data);
        })
      } else {
        toast.error('Invalid file type. Only images are allowed.');
      }
    };
  
    useEffect(() => {
      let timer;
      if (courseSearch !== '') {
        timer = setTimeout(() => {
  
          function getAllData(next) {
            if (next == null) {
              return;
            }
  
            loadMoreData(next)
              .then((res) => {
                setSearchCourseData(prev => [...prev, ...res?.data?.data?.results])
                getAllData(res?.data?.data?.next)
              })
              .catch((err) => {
                console.log(err)
              })
          }
  
  
          searchCourseApi(courseSearch)
            .then((res) => {
              console.log(res)
              setSearchCourseData(res?.data?.data?.results)
              if (res?.data?.data?.next !== null) {
                getAllData(res?.data?.data?.next)
              }
            })
            .catch((err) => {
              console.log(err)
            })
  
        }, 1000)
  
      } else {
        setSearchCourseData([])
      }
  
      return (() => {
        clearTimeout(timer);
      })
  
    }, [courseSearch])
  
  
  
  
    useEffect(() => {
      setTimeout(() => {
  
        if (search !== '') {
          let arr = [];
          for (let i = 0; i < country.length; i++) {
            if (country[i].name.toLowerCase().includes(search.toLowerCase())) {
              const isThere = (data) => {
                for (let j = 0; j < arr.length; j++) {
                  if (arr[j].id === data.id) {
                    return true;
                  }
                }
                return false;
              }
              if (!isThere(country[i])) {
                arr.push(country[i]);
              }
            }
          }
          setSearchData([...searchData, ...new Set(arr)])
        } else {
          setSearchData([])
        }
      }, 1000)
    }, [search])
  
    useEffect(() => {
      setTimeout(() => {
  
        if (searchJob !== '') {
          let arr = [];
          for (let i = 0; i < jobRoleList.length; i++) {
            if (jobRoleList[i].name.toLowerCase().includes(searchJob.toLowerCase())) {
              const isThere = (data) => {
                for (let j = 0; j < arr.length; j++) {
                  if (arr[j].id === data.id) {
                    return true;
                  }
                }
                return false;
              }
              if (!isThere(jobRoleList[i])) {
                arr.push(jobRoleList[i]);
              }
            }
          }
          setSearchJobData([...searchData, ...new Set(arr)])
        } else {
          setSearchJobData([])
        }
      }, 1000)
    }, [searchJob])
  
    const findById = (id) => {
      console.log(id, "userId")
      if (isEdit) {
        for (let i = 0; i < country.length; i++) {
          if (country[i].id === id.id) {
            return country[i]
          }
        }
      } else {
        for (let i = 0; i < country.length; i++) {
          if (country[i].id === id) {
            return country[i]
          }
        }
      }
    }
  
    const findJobRoleById = (id) => {
      if (isEdit) {
        for (let i = 0; i < selectedJobRoles.length; i++) {
          if (selectedJobRoles[i].id === id.id) {
            return selectedJobRoles[i]
          }
        }
      } else {
        for (let i = 0; i < selectedJobRoles.length; i++) {
          console.log(selectedJobRoles[i], "Info")
          if (selectedJobRoles[i].id === id) {
            return selectedJobRoles[i]
          }
        }
      }
    }
  
    const handleCancel = () => {
        setIsEdit(false);
        setSelectedCountries([]);
        setCourseName('');
        setCourseDescription('');
        setSelectedJobRoles([]);
        setCourseCategory('');
        setCourseType('');
    }
  
    const removeCountry = (data) => {
      const countries = [...selectedCountries]
      const newCountry = countries.filter((item) => item !== data);
      setSelectedCountries(newCountry);
    }
  
    const removeJobRole = (data) => {
      const jobRoles = [...selectedJobRoles]
      const newCountry = jobRoles.filter((item) => item !== data);
      setSelectedJobRoles(newCountry);
    }
    const submitCourse = () => {
      if (!isEdit) {
        if (courseName !== "" && courseDescription !== "") {
          saveCourse({
            course_name: courseName,
            countries: selectedCountries?.length > 0 ? selectedCountries : [],
            course_description: courseDescription,
            course_image: image,
            course_type: courseType,
            course_category: courseCategory,
            rank: selectedJobRoles,
            for_fresher: forFresher,
            for_seafarer: forSeafarer,
            is_coc: isCoc == 'yes' ? true : false,
            is_cet: isCet == 'yes' ? true : false
          },access)
            .then((res) => {
              console.log(res, "courseData")
              setCourseData([...courseData, res?.data?.data])
            })
            .catch((err) => {
              console.log(err)
            })
          setCourseDescription('');
          setSelectedCountries([]);
          setCourseName('')
        } else {
          errorToast("Course Name and description is mandatory")
        }
      } else {
        const modCountry = () => {
          let ans = [];
          if (selectedCountries.length == 0) {
            return [];
          } else {
  
            for (let i = 0; i < selectedCountries.length; i++) {
              ans.push(selectedCountries[i].id)
            }
  
            return ans;
  
          }
        }
        UpdateLearningCourse({
          course_name: courseName,
          countries: selectedCountries.length > 0 ? modCountry() : [],
          course_description: courseDescription,
          course_image: image,
          course_type: courseType,
          course_category: courseCategory,
          rank: selectedJobRoles,
          for_fresher: forFresher,
          for_seafarer: forSeafarer,
          is_coc: isCoc == 'yes' ? true : false,
          is_cet: isCet == 'yes' ? true : false
        }, updateData.id, access)
          .then((res) => {
            console.log(res);
            handleCancel();
          })
          .catch(err => {
            console.log(err)
          })
      }
  
      GetLearningCourses('accepted')
        .then((res) => {
          console.log(res?.data?.data?.results, "institute Data")
          setCourseData(res?.data?.data?.results);
        })
        .catch(err => {
          console.log(err)
        })
  
  
    }
  
    const deleteCourse = (id) => {
  
      DeleteLearningCourse(id,access)
        .then((res) => {
          console.log(res)
          getAllCourseData()
  
        })
        .catch(err => {
          console.log(err)
        })
  
      GetLearningCourses('accepted')
        .then((res) => {
          console.log(res?.data?.data?.results, "institute Data")
          setCourseData((prev) => prev = res?.data?.data?.results);
        })
        .catch(err => {
          console.log(err)
        })
    }
    function getAllCourseData(type) {
      setUsersToShow(type);
      if (type == 'pending') {
        requestCourseListInstitute(type)
          .then((res) => {
            console.log(res)  
            setCourseData(res?.data?.data?.results)
            setTotalCount(res?.data?.data?.count);
            setNextPageUrl(res?.data?.data?.next);
            setPrev(res?.data?.data?.previous);
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
  
        requestCourseListInstitute(type)
          .then((res) => {
            console.log(res)
            setCourseData(res?.data?.data?.results);
            setTotalCount(res?.data?.data?.count);
            setNextPageUrl(res?.data?.data?.next);
            setPrev(res?.data?.data?.previous);
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  
    function getAllJobRanks() {
      getRolesAndRank()
        .then((res) => {
          setJobRoleList(res?.data?.data?.results)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  
    useEffect(() => {
      getAllJobRanks()
      setLoading(true);
      getCountries()
        .then((res) => {
          setLoading(false);
          setCountries(res?.data?.data)
        })
        .catch((err) => {
          setLoading(false);
          console.log(err)
        })
  
      getAllCourseData('accepted');
  
      courseCategoryApi()
        .then((res) => {
          console.log(res, "course Category")
          setCourseCategoryList(res?.data?.data);
        })
        .catch((err) => {
          console.log(err)
        })
  
      courseTypeApi()
        .then((res) => {
          console.log(res, "course type")
          setCourseTypeList(res?.data?.data);
        })
        .catch((err) => {
          console.log(err)
        })
  
    }, [])

    return (
        <div className='institute-container animate__animated animate__fadeIn'>
            <div className='instituteOnboarding-list-view'>
                <div className='headerPart'>
                    <h4>Request Learning course</h4>
                </div>
            </div>
            
            <div className='table-container position-relative'>
      <span className='mustStar' style={{textAlign:'end', fontSize:"15px", position:'absolute',top:"-20px",left:"-16px"}}>Fields marked (*) are mandatory.</span>
        <div className='allDropDown allField-learning-courses'>
          <span className='d-flex add-course-container add-course-country-container justify-content-center' style={{ maxWidth: "100%", padding: "20px" }}>
            <div className="dropdown dropDown-locality">
              <button type="button"
                className="btn drdpbtn sailors-Input-field flex flex-row rounded-md"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ backgroundColor: "white", maxWidth: "200px",display: "flex",border:"1px solid gray" }}>
                Country<FaCaretDown style={{ width: "50px", height:"25px",color: "black" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>
                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                  <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control position-static" placeholder="Search ......" aria-label="Recipient's username" />

                  </div>

                </div>
                {search == '' && <React.Fragment>{country.length > 0 ? country?.map((item, index) => {
                  return (
                    <div className="dropdown-item" style={{ height: "50px", cursor: "pointer" }} onClick={() => addCountry(item)}>{item?.name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}</React.Fragment>}

                {search !== '' && <>{searchData.length > 0 ? searchData.reverse()?.map((item, index) => {
                  return (
                    <div className="dropdown-item" style={{ height: "50px", cursor: "pointer" }} onClick={() => addCountry(item)}>{item.name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>No Match</center>}</>}

              </div>
            </div>

            <div className={selectedCountries.length > 0 ? 'selectedCountries-container' : 'selectedCountries-container noDataContainer'}>

              {selectedCountries.length > 0 ? <>{selectedCountries.map((item) => {
                return (
                  <div className='selectedCountry' style={{ maxHeight: "50px" }}>
                    <p style={{ marginTop: "37px", }}>{findById(item)?.name}</p>
                    <button onClick={() => removeCountry(item)} type="button" className="close" style={{ width: "15px", height: "15px" }}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )
              })}</> : <center style={{ marginTop: "15px" }}>All Countries</center>}
            </div>
          </span>

          <span className='d-flex add-course-container add-course-country-container justify-content-center' style={{ maxWidth: "100%", padding: "20px" }}>
            <div className="dropdown dropDown-locality">
              <button type="button"
                className="btn drdpbtn sailors-Input-field flex flex-row rounded-md"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ backgroundColor: "white", maxWidth: "200px",display: "flex",border:"1px solid gray" }}>
                Select Job Role<FaCaretDown style={{ width: "50px", height:"25px",color: "black" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>
                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                  <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                    <input type="text" value={searchJob} onChange={(e) => setSearchJob(e.target.value)} className="form-control position-static" placeholder="Search ......" aria-label="Recipient's username" />
                  </div>

                </div>
                {searchJob == '' && <React.Fragment>{jobRoleList.length > 0 ? jobRoleList?.map((item, index) => {
                  return (
                    <div className="dropdown-item" style={{ height: "50px", cursor: "pointer" }} onClick={() => addJobRole(item)}>{item?.name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}</React.Fragment>}

                {searchJob !== '' && <>{searchJobData.length > 0 ? searchJobData.reverse()?.map((item, index) => {
                  return (
                    <div className="dropdown-item" style={{ height: "50px", cursor: "pointer" }} onClick={() => addJobRole(item)}>{item.name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>No Match</center>}</>}

              </div>
            </div>

            <div className={selectedCountries.length > 0 ? 'selectedCountries-container' : 'selectedCountries-container noDataContainer'}>

              {selectedJobRoles.length > 0 ? <>{selectedJobRoles.map((item) => {
                return (
                  <div className='selectedCountry' style={{ maxHeight: "50px" }}>
                    <p style={{ marginTop: "37px", }}>{getJobRoleById(item)}</p>
                    <button onClick={() => removeJobRole(item)} type="button" className="close" style={{ width: "15px", height: "15px" }}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )
              })}</> : <center style={{ marginTop: "15px" }}>All Job Ranks</center>}
            </div>
          </span>

          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Course Name<span className='mustStar'>*</span></p>
            <input type='text' value={courseName} onChange={(e) => setCourseName(e.target.value)} className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
          </span> */}

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Course Name<span className='mustStar'>*</span></label>
            <div className="col-sm-9">
              <input type="text" value={courseName} onChange={(e) => setCourseName(e.target.value)} className="sailors-Input-field" />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Course Category<span className='mustStar'>*</span></label>
            <div className="col-sm-9">
              <select className="form-select sailors-Input-field bg-white" value={courseCategory} onChange={(e) => { setCourseCategory(e.target.value) }}>
                {courseCategoryList.length > 0 && <option>Select Course Category</option>}
                {courseCategoryList.length > 0 ? courseCategoryList?.map((item, index) => {
                  return (
                    <option value={item?.id}>{item?.name}</option>
                  )
                }) : <option >!Opps check the Internet</option>}

              </select>
            </div>
          </div>

          {/* <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
            <p style={{ maxWidth: "231px", margin: "0px 11px 0px -11px" }}>Course Category<span className='mustStar'>*</span></p>

            <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "978px", marginRight: "-23px", marginLeft: "5px" }}>
              <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                {getCourseCategoryById(courseCategory)}<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>

                {courseCategoryList.length > 0 ? courseCategoryList?.map((item, index) => {
                  return (
                    <div className="dropdown-item" onClick={() => { setCourseCategory(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{item?.name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}


              </div>
            </div>
          </span> */}

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Course Type<span className='mustStar'>*</span></label>
            <div className="col-sm-9">
              <select className="form-select sailors-Input-field bg-white" value={courseType} onChange={(e) => { setCourseType(e.target.value) }}>
                {courseTypeList.length > 0 && <option>Select Course Type</option>}
                {courseTypeList.length > 0 ? courseTypeList?.map((item, index) => {
                  return (
                    <option value={item?.id}>{item?.name}</option>
                  )
                }) : <option >!Opps check the Internet</option>}

              </select>
            </div>
          </div>

          {/* <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
            <p style={{ maxWidth: "231px", margin: "0px 11px 0px -11px" }}>Course Type<span className='mustStar'>*</span></p>

            <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "978px", marginRight: "-23px", marginLeft: "5px" }}>
              <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                {getCourseTypeById(courseType)}<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>

                {courseTypeList.length > 0 ? courseTypeList?.map((item, index) => {
                  return (
                    <div className="dropdown-item" onClick={() => { setCourseType(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{item?.name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}


              </div>
            </div>
          </span> */}

          <div className="row mb-3 ">
            <label className="col-sm-3 col-form-label">Who Can Apply?<span className='mustStar'>*</span></label>
            <div className="col-sm-4 d-flex align-items-center justify-content-start" >
              <label className="col-sm-6 form-label" >Seafarer</label>
              <input type="checkbox" checked={forSeafarer} onChange={(e) => setForSeafarer(!forSeafarer)} className="" style={{ width: "35px" }} />
            </div>
            <div className="col-sm-5 col-xl-3 col-xs-5 d-flex  align-items-center">
              <label className="col-sm-5 form-label">Fresher</label>
              <input type="checkbox" checked={forFresher} onChange={(e) => setForFresher(!forFresher)} className="" style={{ width: "35px" }} />
            </div>
          </div>

          {/* <span className='d-flex add-course-container justify-content-center align-items-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px", paddingLeft: "11px" }}>Who Can Apply?<span className='mustStar'>*</span></p>
            <div className='form-control sailors-Input-field' style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'start' }}>
              <label style={{ maxWidth: "150px" }}>Seafarer</label>
              <input type='checkbox' checked={forSeafarer} onChange={(e) => setForSeafarer(!forSeafarer)} style={{ maxWidth: "24px" }} />
              <label style={{ maxWidth: "150px", marginLeft: "20px" }}>Fresher</label>
              <input type='checkbox' checked={forFresher} onChange={(e) => setForFresher(!forFresher)} style={{ maxWidth: "24px" }} />
            </div>
          </span> */}

          <div className="row mb-3 ">
            <label className="col-sm-3 col-form-label">For CET<span className='mustStar'>*</span></label>
            <div className="col-sm-4 d-flex align-items-center justify-content-start align-items-center" >
              <label className="col-sm-6 form-label" style={{ maxWidth: "130px" }}>Yes</label>
              <input type="radio" name='cet' checked={isCet == 'yes' ? true : false} onChange={(e) => setIsCet('yes')} className="" style={{ width: "25px" }} />
            </div>
            <div className="col-sm-4 d-flex align-items-center justify-content-start  align-items-center">
              <label className="col-sm-6 form-label">No</label>
              <input type="radio" name='cet' checked={isCet == 'no' ? true : false} onChange={(e) => setIsCet('no')} className="" style={{ width: "25px" }} />
            </div>
          </div>

          {/* <span className='d-flex add-course-container justify-content-center align-items-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px", paddingLeft: "11px" }}>For CET?<span className='mustStar'>*</span></p>
            <div className='form-control sailors-Input-field' style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'start' }}>
              <label style={{ maxWidth: "150px" }}>Yes</label>
              <input type='radio' name='cet' checked={isCet == 'yes' ? true : false} onChange={(e) => setIsCet('yes')} style={{ maxWidth: "24px" }} />
              <label style={{ maxWidth: "150px", marginLeft: "20px" }}>No</label>
              <input type='radio' name='cet' checked={isCet == 'no' ? true : false} onChange={(e) => setIsCet('no')} style={{ maxWidth: "24px" }} />
            </div>
          </span> */}
          <div className="row mb-3 ">
            <label className="col-sm-3 col-form-label">For COC<span className='mustStar'>*</span></label>
            <div className="col-sm-4 d-flex align-items-center justify-content-start align-items-center" >
              <label className="col-sm-6 form-label" style={{ maxWidth: "130px" }}>Yes</label>
              <input type="radio" name='coc' checked={isCoc == 'yes' ? true : false} onChange={(e) => setIsCoc('yes')} className="" style={{ width: "25px" }} />
            </div>
            <div className="col-sm-4 d-flex align-items-center justify-content-start  align-items-center">
              <label className="col-sm-6 form-label">No</label>
              <input type="radio" name='coc' checked={isCoc == 'no' ? true : false} onChange={(e) => setIsCoc('no')} className="" style={{ width: "25px" }} />
            </div>
          </div>

          {/* <span className='d-flex add-course-container justify-content-center align-items-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px", paddingLeft: "11px" }}>For COC?<span className='mustStar'>*</span></p>
            <div className='form-control sailors-Input-field' style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'start' }}>
              <label style={{ maxWidth: "150px" }}>Yes</label>
              <input type='radio' name='coc' checked={isCoc == 'yes' ? true : false} onChange={(e) => setIsCoc('yes')} style={{ maxWidth: "24px" }} />
              <label style={{ maxWidth: "150px", marginLeft: "20px" }}>No</label>
              <input type='radio' name='coc' checked={isCoc == 'no' ? true : false} onChange={(e) => setIsCoc('no')} style={{ maxWidth: "24px" }} />
            </div>
          </span> */}
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Course Description<span className='mustStar'>*</span></label>
            <div className="col-sm-9">
              <textarea value={courseDescription} onChange={(e) => setCourseDescription(e.target.value)} type="text" rows="3" className="form-control sailors-Input-field" placeholder="Enter Description" />
            </div>
          </div>
          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}> Course Description<span className='mustStar'>*</span></p>

            <textarea
              placeholder='Here is a Course description'
              size='sm'
              resize={'vertical'}
              className='form-control sailors-Input-field'
              value={courseDescription}
              rows='3'
              onChange={(e) => setCourseDescription(e.target.value)}
            />
          </span> */}
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Course Image</label>
            <div className="col-sm-5 col-xl-5 col-xs-3 d-flex flex-wrap align-items-center">
              <input type="file" onChange={handleFileChange} />
              <span style={{ fontSize: "12px", color: 'red' }}>Upload Images and Docs under 5MB</span>
            </div>
          </div>

          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}> Course Image</p>
            <input type='file' onChange={handleFileChange} />
          </span> */}

        </div>
        <div className='form-btns' style={{ marginBottom: "10px" }}>
          <button onClick={submitCourse} type="submit" className="btn">{isEdit ? "Update" : "Save"}</button>
          <button onClick={handleCancel} className="border-[1px] border-slate-500 w-auto px-2 py-1.5 rounded-md" style={{border:"1px solid #64748b"}}>Cancel</button>
        </div>
      </div>
      <div className='instituteOnboarding-list-view' style={{ marginTop: "20px" }}>
        <div className='headerPart'>
          <h4>Requested Courses List</h4>
        </div>
      </div>
      <div className='input-group positon-sticky d-flex flex-row w-100'>
        <button className={usersToShow !== "accepted" ? 'candidate-sort-button candidate-sort-button-active w-auto' : 'w-auto candidate-sort-button'} onClick={() => getAllCourseData('accepted')} style={{ cursor: 'pointer', margin: "3px", border: usersToShow == 'pending' ? '1px solid #005A80' : 'none', borderRadius: "5px", color: usersToShow == 'accepted' ? "white" : "#005A80", background: usersToShow == 'accepted' ? "#005A80" : "white" }}>Accepted</button>
        <button className={usersToShow !== "pending" ? 'candidate-sort-button candidate-sort-button-active w-auto' : 'w-auto candidate-sort-button'} onClick={() => getAllCourseData('pending')} style={{ cursor: 'pointer', margin: "3px", border: usersToShow == 'accepted' ? '1px solid #005A80' : 'none', borderRadius: "5px", color: usersToShow == 'pending' ? "white" : "#005A80", background: usersToShow == 'pending' ? "#005A80" : "white" }}>Pending</button>
      </div>
      {usersToShow==='accepted'&&<div className="input-group position-sticky" style={{ marginBottom: "20px", paddingLeft: "10px" }}>
        <input type="text" value={courseSearch} onChange={(e) => setCourseSearch(e.target.value)} className="form-control position-static" placeholder="Search Course ......🔍" aria-label="Recipient's username" />
      </div>}
      {loading ?
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>

          <TailSpin
            height={20}
            width={20}
            radius={5}
            color="#005A80"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>

        : <div className='table-container'>

          {courseData?.length > 0 ?
            <div className='table-Data'>
              <table className='table'>
                <thead>
                  <tr style={{ display: 'flex', justifyContent: "flex-start",borderBottom:"1px solid #2A737A",color:"#005A80" }}>
                    <th style={{ fontWeight: "700", maxWidth: "90px" }}>Sr No.</th>
                    <th style={{ textAlign: "start", fontWeight: "700" }}>Course Name</th>
                    <th style={{ fontWeight: "700" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>

                  {courseSearch == "" && courseData?.map((item, index) => {
                    return (
                      <tr key={item.id} className='' style={{ display: 'flex', justifyContent: "space-around",borderBottom:"1px solid #2A737A" }}>
                        <th scope="row">{(currentPage-1)*20+index + 1}</th>
                        <td style={{ textAlign: "start" }}>{item?.course_name}</td>
                        {usersToShow == "accepted" && <td>
                          <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: 'start' }}>
                            <FaPen onClick={() => editData(item)} title='Details' style={{ width: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /><RiDeleteBin6Line onClick={() => deleteCourse(item.id)} title='Delete' style={{ width: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                          </div>
                        </td>}
                        {usersToShow == "pending" && <td>
                          <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: 'start' }}>
                          <RiDeleteBin6Line onClick={() => deleteCourse(item.id)} title='Delete' style={{ width: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderRadius: "10px" }} />
                          </div>
                        </td>}
                      </tr>
                    )
                  })}
                  {courseSearch !== "" && searchCourseData?.map((item, index) => {
                    return (
                      <tr key={item.id} className='' style={{ display: 'flex', justifyContent: "space-around" }}>
                        <th scope="row">{index + 1}</th>
                        <td style={{ textAlign: "start" }}>{item?.course_name}</td>
                        {usersToShow == "accepted" && <td>
                          <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: 'start' }}>
                            <FaPen onClick={() => editData(item)} title='Details' style={{ width: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /><RiDeleteBin6Line onClick={() => deleteCourse(item.id)} title='Delete' style={{ width: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                          </div>
                        </td>}
                        {usersToShow == "pending" && <td>
                          <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: 'start' }}>
                            <FaEyeSlash onClick={() => editData(item)} title='Details' style={{ width: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} />
                          </div>
                        </td>}
                      </tr>
                    )
                  })}
                </tbody>


              </table>
            </div>
            :
            <center>No Data found</center>}

        </div>}

      {totalCount > 20 && courseSearch == '' && <ResponsivePagination
        current={currentPage}
        total={Math.ceil(totalCount / 20)}
        onPageChange={(e) => { pagination(e) }}
      />}

        </div>
    )
}

export default CourseRequest;
