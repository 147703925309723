import React, { useEffect, useState } from "react";
import "./jobPost.css";
import "../../../StyleHub/main.css";
import { BallTriangle } from "react-loader-spinner";
import { FaCaretDown, FaPen } from "react-icons/fa";
import LazyLoad from "react-lazy-load";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  getAllCompanies,
  getAllCompaniesSearch,
  getAllPostedJobByAdmin,
  getCities,
  getCountries,
  getJobCategoryList,
  getRolesAndRank,
  getShipTypeList,
  getStates,
  loadMoreData,
} from "../../../Apis/getApis";
import { deleteJobPostByCompany } from "../../../Apis/deleteApis";
import ResponsivePagination from "react-responsive-pagination";
import Swal from "sweetalert2";
import { postJob } from "../../../Apis/postApis";
import { updatePostedJobcompany } from "../../../Apis/updateApis";
import {
  allowedTypes,
  jobTypeList,
} from "../../../utils/variables/folderStructureFiles";
import {
  errorToast,
  successToast,
  uploadFile,
} from "../../../utils/helperFunctions/HelperFunctions";
import InfiniteScroll from "react-infinite-scroll-component";
import { base_url } from "../../../utils/endpoints/endpoints";
import useToken from "../../../customHook/useToken";
const JobPost = React.memo(() => {
  const access = useToken();
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState(null);
  const [country, setCountry] = useState("Country");
  const [countryList, setCountryList] = useState([]);
  const [state, setState] = useState("");
  const [stateList, setStateList] = useState([]);
  const [city, setCity] = useState("");
  const [cityList, setCityList] = useState("");
  const [jobRoleList, setJobRoleList] = useState([]);
  const [jobRole, setJobRole] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [moduleDescription, setModuleDescription] = useState("");
  const [jobtype, setJobType] = useState("Select Job type");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [openings, setOpenings] = useState();
  const [image, setImage] = useState("");
  const [jobCategoryList, setJobCategoryList] = useState([]);
  const [jobCategory, setJobCategory] = useState("");
  const [skills, setSkills] = useState("");
  const [requirements, setRequirements] = useState("");
  const [responsibilities, setResponsibilities] = useState("");
  const [jobStatus, setJobStatus] = useState(true);
  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [forSeafarer, setForSeafarer] = useState(false);
  const [forFresher, setForFresher] = useState(false);
  const [minValidityUnit, setMinValidityUnit] = useState(
    "Select Min-Experience"
  );
  const [minValidityValue, setMinValidityValue] = useState("");
  const [maxValidityValue, setMaxValidityValue] = useState("");
  const [maxValidityUnit, setMaxValidityUnit] = useState(
    "Select Max-Experience"
  );
  const [envType, setEnvType] = useState("");
  const [otherCompanyName, setOtherCompanyName] = useState("");
  const [dataType, setDataType] = useState("");
  const [allPostedJobs, setAllPostedJobs] = useState([]);
  const [allShipsData, setShipsData] = useState([]);
  const [selectedShips, setSelectedShips] = useState("");
  const [companiesData, setCompaniesData] = useState([]);
  const [department, setDepartment] = useState("");
  const [next, setNext] = useState("");
  const [count, setCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  function loadData() {
    loadMoreData(next)
      .then((res) => {
        console.log(res);
        setCompaniesData([...companiesData, ...res?.data?.data?.results]);
        setNext(res?.data?.data?.next);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    let isLoader = localStorage.getItem("reloader");
    if (!isLoader) {
      window.location.reload();
      localStorage.setItem("reloader", true);
    }
  }, []);

  const pagination = (e) => {
    setCurrentPage(e);
    let url = `${base_url}/api/v1/web/users/?user_type=institute&status=pending&page=${e}`;
    loadMoreData(url)
      .then((res) => {
        setAllPostedJobs([...res?.data?.data?.results]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [companySearch, setCompanySearch] = useState("");
  const [companySearchData, setCompanySearchData] = useState([]);

  useEffect(() => {
    console.log(country, "states");
    setTimeout(() => {
      if (country !== "Country") {
        getStates(country)
          .then((res) => {
            console.log(res, "states");
            setStateList(res?.data?.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, 500);
  }, [country]);

  useEffect(() => {
    setTimeout(() => {
      if (state) {
        getCities(state)
          .then((res) => {
            console.log(res, "states");
            setCityList(res?.data?.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, 500);
  }, [state]);

  function getAlljobs() {
    getAllPostedJobByAdmin()
      .then((res) => {
        setAllPostedJobs(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const editData = (item) => {
    setIsEdit(true);
    if (item?.company === null) {
      setOtherCompanyName(item?.other_company_name);
    }
    setSelectedItem(item);
    setCourse(item?.company_id);
    setOpenings();
    setJobTitle(item?.job_title);
    setModuleDescription(item?.description);
    setJobRole(item?.job_role);
    setJobCategory(item?.job_category);
    setJobType(item?.job_type);
    setSkills(item?.skills);
    setRequirements(item?.requirements);
    setResponsibilities(item?.responsibilities);
    setCountry(item?.country);
    setImage(item?.file);
    setFromDate(item?.from_date);
    setToDate(item?.to_date);
    setJobStatus(item?.is_active);
    setForFresher(item?.for_fresher);
    setForSeafarer(item?.for_seafarer);
    setMinValidityUnit(item?.min_experience?.unit);
    setMinValidityValue(item?.min_experience?.value);
    setMaxValidityUnit(item?.max_experience?.unit);
    setMaxValidityValue(item?.max_experience?.value);
    setEnvType(item?.work_environment_type);
  };

  const getCourseById = (val) => {
    if (isNaN(val)) {
      return "Choose Company";
    } else {
      for (let i = 0; i < companiesData.length; i++) {
        if (val === companiesData[i].id) {
          return companiesData[i].name;
        }
      }
    }
    return "Choose Company";
  };

  function showJobType(type) {
    let typeArr = type.split("_");
    let str = typeArr.join(" ").toUpperCase();

    return str;
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (allowedTypes.includes(selectedFile?.type)) {
      let res = uploadFile(selectedFile);
      res.then((data) => {
        setImage(data);
      });
    } else {
      errorToast(
        "Invalid file type. Only images, videos, audios and PDFs are allowed."
      );
    }
  };

  useEffect(() => {
    setSearchData([]);
    setTimeout(() => {
      if (search !== "") {
        let arr = [];
        for (let i = 0; i < countryList.length; i++) {
          if (
            countryList[i].name.toLowerCase().includes(search.toLowerCase())
          ) {
            const isThere = (data) => {
              for (let j = 0; j < arr.length; j++) {
                if (arr[j].id == data.id) {
                  return true;
                }
              }
              return false;
            };
            if (!isThere(countryList[i])) {
              arr.push(countryList[i]);
            }
          }
        }
        setSearchData([...searchData, ...[...new Set(arr)]]);
      } else {
        setSearchData([]);
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    let timer;
    if (companySearch !== "") {
      timer = setTimeout(async () => {
        async function getAllData(resNext, arr) {
          if (resNext == null) {
            return arr;
          }

          await loadMoreData(resNext, arr)
            .then((res) => {
              setTimeout(() => {
                setCompanySearchData((prev) => [
                  ...prev,
                  ...res?.data?.data?.results,
                ]);
                getAllData(res?.data?.data?.next, arr);
              }, 200);
            })
            .catch((err) => {
              console.log(err);
              return;
            });
        }
        await getAllCompaniesSearch(companySearch)
          .then((res) => {
            setCompanySearchData([...res?.data?.data?.results]);
            getAllData(res?.data?.data?.next, []);
          })
          .catch((err) => {
            console.log(err);
          });
        setCompanySearchData([]);
      }, 1000);
    } else {
      setSearchData([]);
    }

    return () => {
      return clearTimeout(timer);
    };
  }, [companySearch]);

  const handleCancel = () => {
    setIsEdit(false);
    setJobTitle("");
    setCourse("");
    setModuleDescription("");
    setJobRole("");
    setJobCategory("");
    setJobType("Select Job type");
    setSkills("");
    setRequirements("");
    setResponsibilities("");
    setCountry("");
    setImage("");
    setFromDate("");
    setToDate("");
    setJobStatus(true);
    setForFresher(false);
    setForSeafarer(false);
    setMinValidityUnit("Select Min-Experience");
    setMinValidityValue("");
    setMaxValidityUnit("Select Max-Experience");
    setMaxValidityValue("");
    setDataType("");
    setEnvType("");
  };

  const deleteModule = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete Job",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete Job!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteJobPostByCompany(id,access)
          .then((res) => {
            getAlljobs();
            handleCancel();
          })
          .catch((err) => {
            console.log(err);
          });
        successToast("Job is deleted.");
      } else {
        setSelectedItem();
      }
    });
  };
  let previousLength = 0;

  const handleInput = (event) => {
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }

    previousLength = newLength;
  };

  const submitCourse = (e) => {
    e.preventDefault();
    if (country === "Country") {
      errorToast("Please Choose the Country");
      return;
    } else if (jobTitle === "") {
      errorToast("Please give Job Title");
      return;
    } else if (jobRole === "") {
      errorToast("Please select Proper job Role");
      return;
    } else if (moduleDescription === "") {
      errorToast("Please Give The Crew Well-Fare facilities");
      return;
    } else if (jobCategory === "") {
      errorToast("Please Choose the jobCategory");
      return;
    } else if (forSeafarer === false && forFresher === false) {
      errorToast("Please Select Who can Apply?");
    } else if (!isEdit) {
      postJob({
        company_id: course,
        job_title: jobTitle,
        job_role: jobRole,
        job_category: jobCategory,
        job_type: jobtype,
        skills: skills,
        requirements: requirements,
        responsibilities: responsibilities,
        country: country,
        description: moduleDescription,
        file: image,
        from_date: from_date,
        to_date: to_date,
        is_active: jobStatus,
        for_seafarer: forSeafarer,
        for_fresher: forFresher,
        min_experience: {
          value: minValidityValue,
          unit: minValidityUnit,
        },
        max_experience: {
          value: maxValidityValue,
          unit: maxValidityUnit,
        },
        data_type: dataType,
        other_company_name: otherCompanyName,
        work_environment_type: envType,
      }, access)
        .then((res) => {
          handleCancel();
          getAlljobs();
          successToast("Job created Succefully");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      updatePostedJobcompany(selectedItem.id, {
        company_id: course,
        job_title: jobTitle,
        job_role: jobRole,
        job_category: jobCategory,
        job_type: jobtype,
        skills: skills,
        requirements: requirements,
        responsibilities: responsibilities,
        country: country,
        description: moduleDescription,
        file: image,
        from_date: from_date,
        to_date: to_date,
        is_active: jobStatus,
        for_seafarer: forSeafarer,
        for_fresher: forFresher,
        min_experience: {
          value: minValidityValue,
          unit: minValidityUnit,
        },
        max_experience: {
          value: maxValidityValue,
          unit: maxValidityUnit,
        },
        data_type: dataType,
        other_company_name: otherCompanyName,
        work_environment_type: envType,
      },access)
        .then((res) => {
          successToast("Job Updates Succefully");
          getAlljobs();
          handleCancel();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    getAlljobs();

    getShipTypeList()
      .then((res) => {
        setShipsData(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });

    getCountries()
      .then((res) => {
        setLoading(false);
        setCountryList(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    getRolesAndRank()
      .then((res) => {
        setJobRoleList(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });

    getJobCategoryList()
      .then((res) => {
        setJobCategoryList(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllCompanies()
      .then((res) => {
        console.log(res, "Companies data");
        setCompaniesData(res?.data?.data?.results);
        setNext(res?.data?.data?.next);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="institute-container">
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4>Post Job</h4>
        </div>
      </div>

      <div
        className="fluid-container p-4 bg-white shadow rounded-lg position-relative"
        style={{ borderRadius: "20px" }}
      >
        <span
          className="mustStar"
          style={{
            textAlign: "end",
            fontSize: "15px",
            position: "absolute",
            top: "-20px",
            left: "-16px",
          }}
        >
          Fields marked (*) are mandatory.
        </span>
        <form className="d-grid gap-3">

        <div className="row mb-3 ">
            <div className="col-sm-4 d-flex justify-content-start align-items-center w-auto flex-wrap">
              <label
                className="col-sm-6 d-flex align-items-center"
                style={{ maxWidth: "130px" }}
              >
                OnShore
              </label>
              <input
                type="radio"
                name="shore"
                checked={envType == "shore" ? true : false}
                onClick={() => setEnvType("shore")}
                className=""
                style={{ width: "15px" }}
              />
            </div>
            <div className="col-sm-4 d-flex align-items-center justify-content-around w-auto flex-wrap">
              <label className="col-sm-6 d-flex align-items-center">
                OffShore
              </label>
              <input
                type="radio"
                name="shore"
                checked={envType == "sea" ? true : false}
                onClick={() => setEnvType("sea")}
                className=""
                style={{ width: "15px" }}
              />
            </div>
            <div className="col-sm-4 d-flex align-items-center justify-content-around w-auto flex-wrap">
              <label className="col-sm-6 d-flex align-items-center">
                Ashore
              </label>
              <input
                type="radio"
                name="land"
                checked={envType == "land" ? true : false}
                onClick={() => setEnvType("land")}
                className=""
                style={{ width: "15px" }}
              />
            </div>
          </div>

          {envType==='land' && 
            <>
            <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Select Country<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <select
                className="form-select sailors-Input-field bg-white"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                {countryList.length > 0 && <option>Select Country</option>}
                {countryList.length > 0 ? (
                  countryList?.map((item, index) => {
                    return <option value={item?.id}>{item?.name}</option>;
                  })
                ) : (
                  <option>!Opps check the Internet</option>
                )}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Select State<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <select
                className="form-select sailors-Input-field bg-white"
                value={state}
                onChange={(e) => setState(e.target.value)}
              >
                {stateList.length > 0 && <option>Select State</option>}
                {stateList.length > 0 ? (
                  stateList?.map((item, index) => {
                    return <option value={item?.id}>{item?.name}</option>;
                  })
                ) : (
                  <option>Select State</option>
                )}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Select City<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <select
                className="form-select sailors-Input-field bg-white"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              >
                {cityList.length > 0 && <option>Select City</option>}
                {cityList.length > 0 ? (
                  cityList?.map((item, index) => {
                    return <option value={item?.id}>{item?.name}</option>;
                  })
                ) : (
                  <option>Select City</option>
                )}
              </select>
            </div>
          </div>
                </>
          }

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Select Company<span className="mustStar">*</span>
            </label>
            <div
              className="col-sm-9"
              style={{ paddingRight: "0px", paddingLeft: "29px" }}
            >
              <div
                className="dropdown ps-2 dropDown-locality dropDown-subLearning-Courses position-relative"
                style={{
                  maxWidth: "978px",
                  minWidth: "100%",
                  marginRight: "-10px",
                }}
              >
                <button
                  type="button"
                  className={
                    width < 500
                      ? "btn drdpbtn sailors-Input-field col"
                      : "btn drdpbtn sailors-Input-field row"
                  }
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    backgroundColor: "white",
                    maxWidth: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {getCourseById(course)}
                  <FaCaretDown style={{ width: "50px" }} />
                </button>
                <div
                  className="dropdown-menu form-select"
                  id="scrollableDiv"
                  style={{
                    boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)",
                    width: "98%",
                    height: "200px",
                    overflowY: "scroll",
                  }}
                >
                  <div
                    className="searchBar-Container position-sticky"
                    style={{ maxHeight: "50px", top: "0" }}
                  >
                    <div
                      className="input-group"
                      style={{ marginBottom: "-100px", paddingLeft: "10px" }}
                    >
                      <input
                        type="text"
                        value={companySearch}
                        onChange={(e) => setCompanySearch(e.target.value)}
                        className=""
                        placeholder="Search ......"
                        aria-label="Recipient's username"
                      />
                    </div>
                  </div>
                  <InfiniteScroll
                    dataLength={
                      search == "" ? companiesData.length : searchData.length
                    }
                    next={loadData}
                    hasMore={
                      next !== null && companySearch == "" ? true : false
                    }
                    loader={<h6>Loading...</h6>}
                    scrollableTarget="scrollableDiv"
                  >
                    {companySearch == "" && (
                      <React.Fragment>
                        {companiesData.length > 0 ? (
                          companiesData?.map((item, index) => {
                            return (
                              <div
                                className="dropdown-item"
                                onClick={() => {
                                  setCourse(item.id);
                                }}
                                style={{ height: "50px", cursor: "pointer" }}
                              >
                                {item?.name}
                              </div>
                            );
                          })
                        ) : (
                          <center
                            style={{ marginTop: "10%", maxHeight: "30px" }}
                          >
                            !Opps check the Internet
                          </center>
                        )}
                      </React.Fragment>
                    )}

                    {companySearch !== "" && (
                      <>
                        {companySearchData.length > 0 ? (
                          companySearchData?.map((item, index) => {
                            return (
                              <div
                                className="dropdown-item"
                                onClick={() => {
                                  setCourse(item.course_name);
                                }}
                                style={{ height: "50px", cursor: "pointer" }}
                              >
                                {item.name}
                              </div>
                            );
                          })
                        ) : (
                          <center
                            style={{ marginTop: "10%", maxHeight: "30px" }}
                          >
                            No Match
                          </center>
                        )}
                      </>
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Job Title<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                className=" sailors-Input-field px-2"
                placeholder="Enter Job Title"
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Job Openings</label>
            <div className="col-sm-9">
              <input
                type="number"
                value={openings}
                onChange={(e) => setOpenings(e.target.value)}
                className=" sailors-Input-field px-2"
                placeholder="Enter Job Openings"
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Job Role<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <select
                className="form-select sailors-Input-field bg-white"
                value={jobRole}
                onChange={(e) => {
                  setJobRole(e.target.value);
                }}
              >
                {jobRoleList.length > 0 && <option>Select Job Role</option>}
                {jobRoleList.length > 0 ? (
                  jobRoleList?.map((item, index) => {
                    return <option value={item?.id}>{item?.name}</option>;
                  })
                ) : (
                  <option>!Opps check the Internet</option>
                )}
              </select>
            </div>
          </div>

          {/* <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Job category<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <select
                className="form-select sailors-Input-field bg-white"
                value={jobCategory}
                onChange={(e) => {
                  setJobCategory(e.target.value);
                }}
              >
                {jobCategoryList.length > 0 && (
                  <option>Select Job Category</option>
                )}
                {jobCategoryList.length > 0 ? (
                  jobCategoryList?.map((item, index) => {
                    return (
                      <option key={index.toString()} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })
                ) : (
                  <option>!Opps check the Internet</option>
                )}
              </select>
            </div>
          </div> */}

          {/* <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Job Type<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <select
                className="form-select sailors-Input-field bg-white"
                value={jobtype}
                onChange={(e) => {
                  setJobType(e.target.value);
                }}
              >
                {jobTypeList.length > 0 && <option>Select Job Type</option>}
                {jobTypeList.length > 0 ? (
                  jobTypeList?.map((item, index) => {
                    return (
                      <option value={item.value}>
                        {item?.lable.toUpperCase()}
                      </option>
                    );
                  })
                ) : (
                  <option>!Opps check the Internet</option>
                )}
              </select>
            </div>
          </div> */}

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Department<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <select
                className="form-select sailors-Input-field bg-white"
                value={department}
                onChange={(e) => {
                  setDepartment(e.target.value);
                }}
              >
                <option>Select Job Type</option>
                <option value="deck">Deck</option>
                <option value="engine">Engine</option>
                <option value="canteen">Canteen</option>
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Select Ship Type<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <select
                className="form-select sailors-Input-field bg-white"
                value={selectedShips}
                onChange={(e) => {
                  setSelectedShips(e.target.value);
                }}
              >
                {allShipsData.length > 0 && <option>Select Ship Type</option>}
                {allShipsData.length > 0 ? (
                  allShipsData?.map((item, index) => {
                    return (
                      <option
                        value={item?.id}
                        style={{ height: "50px", cursor: "pointer" }}
                      >
                        {item?.name}
                      </option>
                    );
                  })
                ) : (
                  <option>!Opps check the Internet</option>
                )}
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Skills<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <textarea
                type="text"
                value={skills}
                onInput={(e) => handleInput(e)}
                onChange={(e) => setSkills(e.target.value)}
                rows="3"
                className="sailors-Input-field p-2"
                placeholder="Enter Skills"
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Requirements<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <textarea
                value={requirements}
                onInput={(e) => handleInput(e)}
                onChange={(e) => {
                  setRequirements(e.target.value);
                }}
                type="text"
                rows="3"
                className="sailors-Input-field p-2"
                placeholder="Enter Requirements"
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Responsibilities<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <textarea
                value={responsibilities}
                onInput={(e) => handleInput(e)}
                onChange={(e) => setResponsibilities(e.target.value)}
                type="text"
                rows="3"
                className="sailors-Input-field p-2"
                placeholder="Enter Responsibilities"
              />
            </div>
          </div>
          <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Crew Welfare & Facilities<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <textarea value={moduleDescription} onChange={(e) => setModuleDescription(e.target.value)} type="text" rows="3" className="form-control sailors-Input-field" placeholder="Enter Facilities" />
                        </div>
                    </div>

          <div className="row mb-3 d-flex align-items-center">
            <label className="col-sm-3 col-form-label d-flex align-items-center">
              Job Status<span className="mustStar">*</span>
            </label>
            <div className="col-sm-5 col-xl-3 col-xs-5 d-flex flex-wrap align-items-center">
              <label className="col-sm-6 d-flex align-items-center">
                Active
              </label>
              <input
                type="checkbox"
                className=""
                onChange={(e) => setJobStatus(!jobStatus)}
                checked={jobStatus}
                style={{ width: "25px" }}
              />
            </div>
          </div>

          <div className="row mb-3 ">
            <label className="col-sm-3 col-form-label">
              Who Can Apply?<span className="mustStar">*</span>
            </label>
            <div className="col-sm-4 d-flex align-items-center justify-content-around w-50 flex-wrap">
              <label className="col-sm-6 d-flex align-items-center">
                Seafarer
              </label>
              <input
                type="checkbox"
                checked={forSeafarer}
                onChange={(e) => setForSeafarer(!forSeafarer)}
                className=""
                style={{ width: "15px" }}
              />
            </div>
            <div className="col-sm-5 col-xl-3 col-xs-5 d-flex  align-items-center justify-content-around w-50 flex-wrap">
              <label className="col-sm-6 d-flex align-items-center">
                Fresher
              </label>
              <input
                type="checkbox"
                checked={forFresher}
                onChange={(e) => setForFresher(!forFresher)}
                className=""
                style={{ width: "15px" }}
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Period<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9 d-flex justify-content-start align-items-center h-14">
              <input
                type="text"
                style={{ maxWidth: "180px" }}
                placeholder="From date"
                className="dateInput p-2"
                onFocus={(e) => (e.target.type = "date")}
                value={from_date}
                onBlur={(e) => {
                  if (from_date == "") {
                    e.target.type = "text";
                  }
                }}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <p
                className="w-25 d-flex mx-2 justify-content-center align-items-center"
                style={{ maxWidth: "30px" }}
              >
                To
              </p>
              <input
                type="text"
                style={{ maxWidth: "180px" }}
                placeholder="From date"
                className="dateInput p-2"
                value={to_date}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => {
                  if (to_date == "") {
                    e.target.type = "text";
                  }
                }}
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Min Experience<span className="mustStar">*</span>
            </label>
            <div
              className="col-sm-5 col-xl-3 col-xs-5 w-100 d-flex flex-row align-items-center"
              style={{ width: "100%" }}
            >
              <input
                type="number"
                value={minValidityValue}
                onChange={(e) => setMinValidityValue(e.target.value)}
                className="form-control sailors-Input-field"
                placeholder="Enter Value"
                style={{ minWidth: "200px" }}
              />
              <select
                className="w-100 sailors-Input-field"
                value={minValidityUnit}
                onChange={(e) => setMinValidityUnit(e.target.value)}
                style={{ minWidth: "220px", marginLeft: "5px" }}
              >
                <option>Select Month/year</option>
                <option value={"months"}>Month</option>
                <option value={"years"}>year</option>
                <option value={"weeks"}>Week</option>
                <option value={"days"}>Day</option>
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Max Experience<span className="mustStar">*</span>
            </label>
            <div
              className="col-sm-5 col-xl-3 col-xs-5 w-100 d-flex flex-row align-items-center"
              style={{ width: "100%" }}
            >
              <input
                type="number"
                value={maxValidityValue}
                onChange={(e) => setMaxValidityValue(e.target.value)}
                className="form-control sailors-Input-field"
                placeholder="Enter Value"
                style={{ minWidth: "200px" }}
              />
              <select
                className="w-100 sailors-Input-field"
                value={maxValidityUnit}
                onChange={(e) => setMaxValidityUnit(e.target.value)}
                style={{ minWidth: "220px", marginLeft: "5px" }}
              >
                <option>Select Month/year</option>
                <option value={"months"}>Month</option>
                <option value={"years"}>year</option>
                <option value={"weeks"}>Week</option>
                <option value={"days"}>Day</option>
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Job Doc</label>
            <div className="col-sm-5 col-xl-5 col-xs-3 d-flex flex-wrap align-items-center">
              <input type="file" onChange={handleFileChange} />
              <span style={{ fontSize: "12px", color: "red" }}>
                Upload Images and Docs under 5MB
              </span>
            </div>
          </div>

          <div
            className="form-btns"
            style={{
              marginBottom: "20px",
              marginTop: "50px",
              maxHeight: "40px",
            }}
          >
            <button type="submit" onClick={submitCourse} className="btn">
              {isEdit ? "Update" : "Save"}
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-OnboardForm"
              style={{ border: "1px solid #005A80" }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>

      <div
        className="instituteOnboarding-list-view"
        style={{ marginTop: "20px" }}
      >
        <div className="headerPart">
          <h4>Job List</h4>
        </div>
      </div>
      <LazyLoad offset={5}>
        {loading ? (
          <div style={{ maxWidth: "100px", maxHeight: "100px" }}>
            <BallTriangle
              height={20}
              width={20}
              radius={5}
              color="red"
              ariaLabel="ball-triangle-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
            <span>Loading...</span>
          </div>
        ) : (
          <div className="table-container">
            {
              <React.Fragment>
                {allPostedJobs.length > 0 ? (
                  <div className="table-Data">
                    <table className="table">
                      <thead>
                        <tr
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            borderBottom: "1px solid #005A80",
                          }}
                        >
                          <th style={{ fontWeight: "700" }}>Sr No.</th>
                          <th
                            style={{ fontWeight: "700", textAlign: "center" }}
                          >
                            Job Title
                          </th>
                          <th
                            style={{ fontWeight: "700", textAlign: "center" }}
                          >
                            Country
                          </th>
                          <th
                            style={{ fontWeight: "700", textAlign: "center" }}
                          >
                            Job Type
                          </th>
                          <th
                            style={{ fontWeight: "700", textAlign: "center" }}
                          >
                            shore/OffShore
                          </th>
                          <th style={{ fontWeight: "700" }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allPostedJobs.map((item, index) => {
                          return (
                            <tr
                              className=""
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <th scope="row">{index + 1}</th>
                              <td style={{ textAlign: "center" }}>
                                {item?.job_title}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item?.country_name}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {showJobType(item?.job_type)}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item?.work_environment_type == "sea"
                                  ? "OffShore"
                                  : "onShore"}
                              </td>
                              <td>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FaPen
                                    onClick={() => editData(item)}
                                    data-toggle="modal"
                                    data-target="#instituteDetailsModal"
                                    title="Edit"
                                    style={{
                                      width: "30px",
                                      color: "#0CB1C0",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopLeftRadius: "10px",
                                      borderBottomLeftRadius: "10px",
                                    }}
                                  />{" "}
                                  <RiDeleteBin6Line
                                    onClick={() => deleteModule(item.id)}
                                    title="Delete"
                                    style={{
                                      width: "30px",
                                      color: "#F84F31",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopRightRadius: "10px",
                                      borderBottomRightRadius: "10px",
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <center>No Data found</center>
                )}
              </React.Fragment>
            }
            {/* {allPostedJobs.length < 0 && <div style={{ maxWidth: "50px", marginTop: "30px", margin: 'auto' }}> */}
            <ResponsivePagination
              current={currentPage}
              total={Math.ceil(count / 20)}
              onPageChange={(e) => pagination(e)}
            />
            {/* </div>} */}
          </div>
        )}
      </LazyLoad>
    </div>
  );
});

export default JobPost;
