import React, { useEffect, useState } from "react";
import { FaCaretDown, FaPen } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BallTriangle } from "react-loader-spinner";
import Swal from "sweetalert2";
import ResponsivePagination from 'react-responsive-pagination';
import {
  getAllAds,
  getAllCompanies,
  getCompanyList,
  getInstituteList,
  getOneInstituteData,
  loadMoreData,
} from "../../../Apis/getApis";
import { saveAd } from "../../../Apis/postApis";
import { updateAd } from "../../../Apis/updateApis";
import { deleteAdvertisement } from "../../../Apis/deleteApis";
import {
  errorToast,
  successToast,
  uploadFile,
} from "../../../utils/helperFunctions/HelperFunctions";
import { allowedTypes } from "../../../utils/variables/folderStructureFiles";
import useToken from "../../../customHook/useToken";
import { useSelector } from "react-redux";
import { base_url } from "../../../utils/endpoints/endpoints";
const Advertisement = () => {
  const loggedUserDetails = useSelector((state) => state?.commonslice?.loggedInUser);
  console.log("loggedInUser", loggedUserDetails)
  const access = useToken();
  const [adsData, setAdsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState("Choose Course");
  const [title, setTitle] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [subjectList, setSubjectList] = useState([]);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [date, setDate] = useState("");
  const [dataType, setDataType] = useState("");
  const [instituteData, setInstituteData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(loggedUserDetails?.user_type==="institute" ? loggedUserDetails?.id :"");
  const [currentUser, setCurrentUser] = useState(loggedUserDetails?.user_type==="institute" ? "institute":"");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] =useState(0);
  const editData = (item) => {
    getOneInstituteData(item?.user)
      .then((res) => {
        console.log(res);
        setCurrentUser(res?.data?.data?.user_type);
        setCurrentUserId(res?.data?.data?.id);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsEdit(true);
    setTitle(item.title);
    setDescription(item?.description);
    setDate(item?.expiry_date);
    setSelectedItem(item);
    setImage(item?.url);
    setCurrentUser(item?.user_type);
    
  };

  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setDataType(selectedFile?.type?.slice(0,5));
    if (selectedFile?.size > 5000000) {
      errorToast("Files size can't be greater than 5MB");
      return;
    }
    if (allowedTypes.includes(selectedFile?.type)) {
      setFile(selectedFile);
      let res = uploadFile(selectedFile);
      res.then((data) => {
        setImage(data);
      });
    } else {
      alert(
        "Invalid file type. Only images, videos, audios and PDFs are allowed."
      );
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    setTitle("");
    setDescription("");
    setImage("");
    setDataType("");
    setDate("");
    setCurrentUser("");
    setCurrentUserId("");
  };

  const deleteAd = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete Ad ?",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete Ad !",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAdvertisement(id,access)
          .then((res) => {
            getAllAdvertisement();
            successToast("Ad deleted");
          })
          .catch((err) => [console.log(err)]);
      } else {
        setSelectedItem();
      }
    });
  };
  const checkDate = (newDate) => {
    const todayDate = new Date();
    const selectedDate = new Date(newDate);
    if (selectedDate < todayDate) {
      errorToast("You Can't select Past Date");
      setDate("");
      return false;
    }
    return true;
  };

  const pagination = (e) => {
    
        loadMoreData(`${base_url}/api/v1/web/course/?page=${e}`)
          .then((res) => {
            setAdsData(res?.data?.data?.results);
          })
          .catch((err) => {
            console.log(err)
          })
    
        setCurrentPage(e);
      }

  const submitCourse = (e) => {
    e.preventDefault();

    if (!checkDate(date)) {
      return;
    }
    if (title === "") {
      errorToast("Kindly give Title");
      return;
    }
    if (description === "") {
      errorToast("Kindly Give The Description");
      return;
    }
    if (date == "") {
      errorToast("Kindly Give The date");
      return;
    }
    if (currentUser == "") {
      errorToast("Kindly Choose the user type");
      return;
    }
    if (
      currentUserId === "Select Institute" ||
      currentUserId === "Select Company" ||
      currentUserId === ""
    ) {
      errorToast("Kindly Choose the user");
      return;
    }

    const objInstitute = {
      title: title,
      user_type:currentUser,
      user: currentUserId,
      description: description,
      data_type: dataType,
      expiry_date: date,
      content: image,
      content_type:dataType
    }
    const objCompany = {
      title: title,
      user_type:currentUser,
      company: currentUserId,
      description: description,
      data_type: dataType,
      expiry_date: date,
      content: image,
      content_type:dataType
    }
    if (!isEdit) {
      saveAd(currentUser==="institute" ? objInstitute : objCompany,access)
        .then((res) => {
          handleCancel();
          getAllAdvertisement();
          document.getElementById("upload-files").value = "";
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      updateAd(selectedItem?.id, currentUser==="institute" ? objInstitute : objCompany, access)
        .then((res) => {
          handleCancel();
          getAllAdvertisement();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function getAllAdvertisement() {
    getAllAds()
      .then((res) => {
        setLoading(false);
        setAdsData(res?.data?.data?.results);
        setTotalCount(res?.data?.data?.count);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  function getAllUserCompanies() {
    function loadData(resNext) {
      if (resNext == null) {
        return;
      }
      loadMoreData(resNext)
        .then((res) => {
          setCompanyData((prev) => [...prev, ...res?.data?.data?.results]);
          loadData(res?.data?.data?.next);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    getAllCompanies()
      .then((res) => {
        setLoading(false);
        console.log(res, "responseData");
        setCompanyData(res?.data?.data?.results);
        loadData(res?.data?.data?.next);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  function getAllInstitutes() {
    function loadData(resNext) {
      if (resNext == null) {
        return;
      }
      loadMoreData(resNext)
        .then((res) => {
          setInstituteData((prev) => [...prev, ...res?.data?.data?.results]);
          loadData(res?.data?.data?.next);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    getInstituteList("accepted")
      .then((res) => {
        setInstituteData(res?.data?.data?.results);
        loadData(res?.data?.data?.next);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  useEffect(() => {
    setLoading(true);
    getAllAdvertisement();
    getAllInstitutes();
    getAllUserCompanies();
  }, []);

  return (
    <div className="institute-container animate__animated animate__fadeIn">
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4>Add Advertisement</h4>
        </div>
      </div>
      <div className="table-container position-relative">
        <span
          className="mustStar"
          style={{
            textAlign: "end",
            fontSize: "15px",
            position: "absolute",
            top: "-20px",
            left: "-16px",
          }}
        >
          Fields marked (*) are mandatory.
        </span>

        <div className="allDropDown allField-learning-courses">
          {loggedUserDetails?.user_type!=="institute" && <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              User type<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <select
                className="form-select sailors-Input-field"
                value={currentUser}
                onChange={(e) => setCurrentUser(e.target.value)}
              >
                <option>Select User Type</option>
                <option value={"institute"}>Institute</option>
                <option value={"company"}>Company</option>
              </select>
            </div>
          </div>}

          {currentUser && currentUser == "company" && (
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">
                Select Company<span className="mustStar">*</span>
              </label>
              <div className="col-sm-9">
                <select
                  className="form-select sailors-Input-field"
                  value={currentUserId}
                  onChange={(e) => setCurrentUserId(e.target.value)}
                >
                  <option>Select User Type</option>
                  {companyData.length > 0 &&
                    companyData.map((item) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                </select>
              </div>
            </div>
          )}
          {currentUser && currentUser === "institute" && loggedUserDetails?.user_type!=="institute" && (
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">
                Select Institute<span className="mustStar">*</span>
              </label>
              <div className="col-sm-9">
                <select
                  className="form-select sailors-Input-field"
                  value={currentUserId}
                  onChange={(e) => setCurrentUserId(e.target.value)}
                >
                  <option>Select Institute</option>
                  {instituteData.length > 0 &&
                    instituteData.map((item) => {
                      return (
                        <option value={item?.id}>{item?.first_name}</option>
                      );
                    })}
                </select>
              </div>
            </div>
          )}

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              Ad Title<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                maxLength={150}
                minLength={3}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="sailors-Input-field"
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label fs-5">
              Requirements<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9 p-2">
              <textarea
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                type="text"
                rows="3"
                className=" sailors-Input-field"
              />
            </div>
          </div>

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              Select Duration<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <input
                required
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="sailors-Input-field p-2"
              />
            </div>
          </div>

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Upload</label>
            <div className="col-sm-9">
              <input
                required
                type="file"
                accept="image/*,video/*"
                onChange={handleFileChange}
                className="p-2 sailors-Input-field"
              />
              <span style={{ fontSize: "12px", color: "red" }}>
                Upload Images and Docs under 5MB
              </span>
            </div>
          </div>

        </div>
        <div className="form-btns" style={{ marginBottom: "10px" }}>
          <button type="submit" onClick={submitCourse} className="btn">
            {isEdit ? "Update" : "Save"}
          </button>
          <button
            onClick={handleCancel}
            className="btn btn-OnboardForm"
            style={{ border: "1px solid #005A80" }}
          >
            Cancel
          </button>
        </div>
      </div>
      <div
        className="instituteOnboarding-list-view"
        style={{ marginTop: "20px" }}
      >
        <div className="headerPart">
          <h4>Advertisement List</h4>
        </div>
      </div>
      {loading ? (
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>
          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>
      ) : (
        <div className="table-container">
          {
            <React.Fragment>
              {adsData.length > 0 ? (
                <div className="table-Data">
                  <table className="table">
                    <thead>
                      <tr
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          borderBottom: "1px solid #2A737A66",
                        }}
                      >
                        <th style={{ fontWeight: "700", maxWidth: "150px" }}>
                          Sr No.
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "start" }}>
                          Ad Title
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "center" }}>
                          Duration Date
                        </th>
                        <th style={{ fontWeight: "700" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {adsData.map((item, index) => {
                        return (
                          <tr
                          key={index}
                            className=""
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              borderBottom: "1px solid #2A737A66",
                            }}
                          >
                            <th scope="row" style={{ maxWidth: "150px" }}>
                              {index + 1}
                            </th>
                            <td style={{ textAlign: "start" }}>
                              {item?.title}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item?.expiry_date}
                            </td>
                            <td>
                              <div
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "start",
                                }}
                              >
                                <FaPen
                                  onClick={() => editData(item)}
                                  data-toggle="modal"
                                  data-target="#instituteDetailsModal"
                                  title="Edit"
                                  style={{
                                    width: "30px",
                                    color: "#0CB1C0",
                                    border: "1px solid gray",
                                    padding: "5px",
                                    borderTopLeftRadius: "10px",
                                    borderBottomLeftRadius: "10px",
                                  }}
                                />{" "}
                                <RiDeleteBin6Line
                                  onClick={() => deleteAd(item.id)}
                                  title="Delete"
                                  style={{
                                    width: "30px",
                                    color: "#F84F31",
                                    border: "1px solid gray",
                                    padding: "5px",
                                    borderTopRightRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <center>No Data found</center>
              )}
            </React.Fragment>
          }
        </div>
      )}
      {totalCount > 20 && <ResponsivePagination
              current={currentPage}
              total={Math.ceil(totalCount / 20)}
              onPageChange={(e) => { pagination(e) }}
            />}
    </div>
  );
};

export default Advertisement;
