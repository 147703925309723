
import React, { useEffect, useState } from 'react'
import { FaCaretDown, FaPen } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BallTriangle } from 'react-loader-spinner';
import { deleteUserSubject } from '../../Apis/deleteApis';
import { saveSubject } from '../../Apis/postApis';
import { updateSubject } from '../../Apis/updateApis';
import { GetLearningCourses, getCountries, getSubjects } from '../../Apis/getApis';
import useToken from '../../customHook/useToken';
const AvailableCourses = () => {
  const access = useToken();
  const [courseData, setCourseData] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [course, setCourse] = useState('');
  const [courseDuration, setCourseDuration] = useState('')
  const [loading, setLoading] = useState(false);
  const [expiryUnit, setExpiryUnit] = useState('');
  const [expiryValue, setExpiryValue] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [fees, setFees] = useState('')
  const [subjectCode, setSubjectCode] = useState('');
  const [width, setWidth] = useState(window.innerWidth);
  const [updateDataId, setUpdateDataId] = useState();
  const handleCancel = () => {
    setIsEdit(false);
    setFees('');
    setExpiryUnit('');
    setExpiryValue('');
  }
  const deleteSubject = (id) => {
    deleteUserSubject(id,access)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const editSubject = (item) => {
    setIsEdit(true);
    setSubjectCode(item.code);
    setUpdateDataId(item?.id);
  }

  const getCourseById = (val) => {
    if (isNaN(val)) {
      return "Choose Course"
    } else {
      for (let i = 0; i < courseData.length; i++) {
        if (val === courseData[i].id) {
          return courseData[i].course_name
        }
      }
    }
    return "Choose Course"
  }




  const submitSubject = () => {
    if (!isEdit) {
      saveSubject({ code: subjectCode },access)
        .then((res) => {
          console.log(res)
          handleCancel();
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      updateSubject(updateDataId, { code: subjectCode }, access)
        .then((res) => {
          console.log(res)
          handleCancel();
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  function getAllLearningCourses() {
    GetLearningCourses('accepted')
      .then((res) => {
        console.log(res?.data?.data?.results, "institute Data")
        setLoading(false);
        setCourseData(res?.data?.data?.results);
      })
      .catch(err => {
        setLoading(false);
        console.log(err)
      })
  }


  useEffect(() => {
    setLoading(true);
    getAllLearningCourses();
  }, [])
  return (
    <div className='institute-container animate__animated animate__fadeIn'>
      <div className='instituteOnboarding-list-view'>
        <div className='headerPart'>
          <h4>Course Available</h4>
        </div>
      </div>
      <div className='table-container'>
        <div className='allDropDown allField-learning-courses'>

          <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
            <p style={{ maxWidth: "231px" }}>Course Name</p>
            <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "978px", marginRight: "-23px", marginLeft: "5px" }}>
              <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                {getCourseById(course)}<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>

                {courseData.length > 0 ? courseData?.map((item, index) => {
                  return (
                    <div className="dropdown-item" onClick={() => { setCourse(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{item?.course_name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}


              </div>
            </div>
          </span>

          <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "0px 20px" }}>
            <p style={{ maxWidth: "231px" }}>Course duration</p>
            <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
              <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                {expiryValue !== "" ? expiryValue : 'Select duration'}{" " + expiryUnit}<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                  <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                    <input className='position-static form-control' type="number" value={expiryValue} onChange={(e) => setExpiryValue(e.target.value)} placeholder="value..." aria-label="Recipient's username" />
                  </div>

                </div>
                {["months", "years", "weeks", "days"]?.map((item, index) => {
                  return (
                    <div className="dropdown-item" onClick={() => setExpiryUnit(item)} style={{ height: "50px", cursor: "pointer" }} >{item}</div>
                  )
                })}

              </div>
            </div>
          </span>

          <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Course Fees</p>
            <input type='number' value={subjectCode} onChange={(e) => { setSubjectCode(e.target.value) }} className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
          </span>

        </div>
        <div className='form-btns' style={{ marginBottom: "10px" }}>
          <button type="submit" onClick={submitSubject} className="btn">{isEdit ? "Update" : "Save"}</button>
          <button onClick={handleCancel} className="btn btn-OnboardForm">Cancel</button>
        </div>
      </div>
      <div className='instituteOnboarding-list-view' style={{ marginTop: "20px" }}>
        <div className='headerPart'>
          <h4>Courses List</h4>
        </div>
      </div>
      {loading ?
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>

          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>

        : <div className='table-container'>

          {courseList?.length > 0 ?
            <div className='table-Data'>
              <table className='table'>
                <thead>
                  <tr style={{ display: 'flex', justifyContent: "flex-start" }}>
                    <th scope="col">Sr No.</th>
                    <th scope="col">Subject Name</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {courseList.map((item, index) => {
                    return (
                      <tr className='' style={{ display: 'flex', justifyContent: "space-around" }}>
                        <th scope="row">{index + 1}</th>
                        <td >{item.name}</td>

                        <td>
                          <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row" }}>
                            <FaPen onClick={() => editSubject(item)} data-toggle="modal" data-target="#instituteDetailsModal" title='Details' style={{ width: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /> <RiDeleteBin6Line onClick={() => deleteSubject(item.id)} title='Delete' style={{ width: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>


              </table>
            </div>
            :
            <center>No Data found</center>}
        </div>}

    </div>
  )
}

export default AvailableCourses;
