
import React, { useEffect, useState } from 'react'
import { FaCaretDown, FaPen } from 'react-icons/fa'
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BallTriangle } from 'react-loader-spinner';
import { getAllRankQuestions, getRolesAndRank } from '../../../../Apis/getApis';
import { saveRankQuestions } from '../../../../Apis/postApis';
import Swal from 'sweetalert2';
import { deleteRankQuestion } from '../../../../Apis/deleteApis';
import { updateRankQuestion } from '../../../../Apis/updateApis';
import useToken from '../../../../customHook/useToken';

const OtherQuestions = () => {
  const access = useToken();
  const [rank,setRank] = useState('Select rank');
  const [width, setWidth] = useState(window.innerWidth);
  const [rankData,setRankData] = useState([]);
  const [question,setQuestion] = useState('');
  const [selectedQuestion,setSelectedQuestion] = useState();
  const [allQuestions,setAllQuestions] = useState([]);
  const [isEdit,setIsEdit] = useState(false);
  const [loading,setLoading] =useState(false);
  
  const getNameById =(id)=>{
    for(let i=0;i<rankData.length;i++){
      if(rankData[i].id===id){
        return rankData[i].name;
      }
    }
    return "Select Rank"
  }
  const editData = (item)=>{
    setSelectedQuestion(item);
    setIsEdit(true);
    setRank(item?.job_role)
    setQuestion(item?.question)
  }

  const handleCancel = ()=>{
    setIsEdit(false);
    setRank('Select Rank');
    setQuestion('');
  }
  const deleteQuestion = (id)=>{

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete Question" ,
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete Question!" 
  }).then((result) => {
      if (result.isConfirmed) {
        
        deleteRankQuestion(id,access)
        .then((res)=>{
          console.log(res);
          getAllRanks()
        })
        .catch((err)=>{
          console.log(err)
        })
          
      }
    })


  }
  const submitQuestion = ()=>{

    if(!isEdit){
      saveRankQuestions({question:question,job_role:rank,question_type:'text'},access)
      .then((res)=>{
        console.log(res);
        getAllRanks();
        setRank();
        setQuestion('');
      })
      .catch((err)=>{
        console.log(err)
      })
    }else{
      updateRankQuestion(selectedQuestion?.id,{question:question,job_role:rank,question_type:'text'},access)
      .then((res)=>{
        console.log(res);
        getAllRanks();
        handleCancel();
      })
      .catch((err)=>{
        console.log(err)
      })
    }


  }

  function getAllRanks (){
    getAllRankQuestions()
    .then((res)=>{
      console.log(res,"pavan");
      setAllQuestions(res?.data?.data?.results);
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  function showQuestion(question){
    Swal.fire(question)
  }


  useEffect(()=>{

    getAllRanks();

    getRolesAndRank()
    .then((res)=>{
      console.log(res,"roles")
      setRankData(res?.data?.data?.results)
    })
    .catch((err)=>{
      console.log(err)
    })

  },[])

  
  return (
    <div className='institute-container animate__animated animate__fadeIn'>
      <div className='instituteOnboarding-list-view'>
        <div className='headerPart'>
          <h4>Other Questions</h4>
        </div>
      </div>
      <div className='table-container position-relative'>
      <span className='mustStar' style={{textAlign:'end', fontSize:"15px", position:'absolute',top:"-20px",left:"-16px"}}>Fields marked (*) are mandatory.</span>
        <div className='allDropDown allField-learning-courses'>
          <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
            <p style={{ maxWidth: "231px" }}>Select Rank<span className='mustStar'>*</span></p>
            
            <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{maxWidth:"928px",marginRight:"-22px"}}>
              <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                {getNameById(rank)}<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>
                
                {rankData.length > 0 ? rankData?.map((item, index) => {
                  return (
                    <div className="dropdown-item" onClick={() => { setRank(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{item?.name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}


              </div>
            </div>
          </span>
          

          <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}> Question<span className='mustStar'>*</span></p>
            
            <textarea 
              placeholder='Write Question?'
              size='sm'
              rows='3'
              resize={'vertical'}
              className='form-control sailors-Input-field'
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </span>
         
        </div>
        <div className='form-btns' style={{ marginBottom: "10px" }}>
          <button type="submit" onClick={submitQuestion} className="btn">{isEdit ? "Update" : "Save"}</button>
          <button onClick={handleCancel} className="btn btn-OnboardForm">Cancel</button>
        </div>
      </div>
      <div className='instituteOnboarding-list-view' style={{ marginTop: "20px" }}>
        <div className='headerPart'>
          <h4>Added Questions List</h4>
        </div>
      </div>
      {loading ?
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>

          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>

        : <div className='table-container'>

          {rankData?.length > 0 ?
            <div className='table-Data'>
              <table className='table'>
                <thead>
                  <tr style={{ display: 'flex', justifyContent: "flex-start" }}>
                    <th style={{ fontWeight: "700", maxWidth: "80px" }}>Sr No.</th>
                    <th style={{ fontWeight: "700", textAlign: 'center' }}>Rank</th>
                    <th style={{ fontWeight: "700", textAlign: 'center' }}>Questions</th>
                    <th style={{ fontWeight: "700" }}>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {allQuestions.filter(item=>item.job_role_name).map((item, index) => {
                    return (
                      <tr className='' style={{ display: 'flex', justifyContent: "space-around" }}>
                        <th scope="row">{index + 1}</th>
                        <td style={{ textWrap: 'wrap', textAlign: 'center' }}>{item.job_role_name}</td>
                        <td style={{ textWrap: 'wrap', textAlign: 'center',display:'flex', justifyContent:'center' }}><button className="view-question-btn" onClick={()=>showQuestion(item?.question)}>View Question</button></td>
                        <td>
                          <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                            <FaPen onClick={() => editData(item)} data-toggle="modal" data-target="#instituteDetailsModal" title='Details' style={{ width: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /> <RiDeleteBin6Line onClick={() => deleteQuestion(item.id)} title='Delete' style={{ width: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>


              </table>
            </div>
            :
            <center>No Data found</center>}
        </div>}

    </div>
  )
}

export default OtherQuestions;
