import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { colors } from "../../../../utils/variables/folderStructureFiles";
const InstitutePieChart = React.memo(({ lableData, userData }) => {
  const [chartData, setChartData] = useState({
    labels: lableData.map((data) => data),
    datasets: [
      {
        label: "Total Applicants ",
        data: userData.map((data) => data),
        backgroundColor: colors,
        border: "none",
        borderWidth: 2,
      },
    ],
  });

  useEffect(()=>{
    setChartData({
      labels: lableData.map((data) => data),
      datasets: [
        {
          label: "Total Applicants ",
          data: userData.map((data) => data),
          backgroundColor: colors,
          border: "none",
          borderWidth: 2,
        },
      ],
    })
  },[lableData,userData]);

  const options = {
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
        padding: {
          top: 0,
          bottom: 10,
          left: 20,
          right: 20,
        },
      },
      legend: {
        position: "right",
        labels: {
          padding: 20, // Adjusts the space between the legend and the chart
        },
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 0,
        bottom: 20,
      },
    },
    // layout: {
    //     padding: {
    //         left: 50,
    //         right: 20,
    //         top: -10,
    //         bottom: 30,
    //     },
    // },
    aspectRatio: 2,
    // plugins: {
    //     legend: {
    //         position: 'right',
    //         labels: {
    //           usePointStyle: true, // Makes the labels use a point style (circle, etc.)
    //           padding: 10,      // Adjust the padding between the labels
    //         },
    //       },
    //     title: {
    //         display: false,
    //         text: "All Jobs and Candidates"
    //     },
    // }
  };

  return (
    <div className="courseChart w-100 ms-0">
      {userData.length && lableData.length ? (
        <center className="chartContainer m-auto w-full">
          <Pie data={chartData} options={options} />
        </center>
      ) : (
        <h1 className="text-base font-montserrat">Data Not Available</h1>
      )}
    </div>
  );
});

export default InstitutePieChart;
