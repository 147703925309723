import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import {
  monthsData,
} from "../../../../utils/variables/folderStructureFiles";
import { Modal } from "react-responsive-modal";
import {
  getFollowupHistory,
  InterestedStudentsData,
  loadMoreData,
} from "../../../../Apis/getApis";
import {
  errorToast,
  successToast,
} from "../../../../utils/helperFunctions/HelperFunctions";
import { saveFollowup } from "../../../../Apis/postApis";
import InfiniteScroll from "react-infinite-scroll-component";
import LoaderExtraSmall from "../../../../common/loader/LoaderExtraSmall";
import useToken from "../../../../customHook/useToken";

const InstituteFollowCard = () => {
  const access = useToken()
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [range, setRange] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [selected, setSelected] = useState();
  const [openingData,setOpeningData] = useState([]);
  const [show, setShow] = useState(false);
  const [interestedData, setInterestedData] = useState([]);
  const [filteredData,setFilteredData] = useState({followup:[],admitted:[],rejected:[]});
  const [currentFollowUpDate, setCurrentFollowUpDate] = useState(new Date());
  const [nextFollowUpDate, setNextFollowUpDate] = useState(null);
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [saveLoading,setSaveLoading] = useState(false);

  useEffect(() => {
    if (currentFollowUpDate !== "" && nextFollowUpDate !== null) {
      if (currentFollowUpDate > nextFollowUpDate) {
        errorToast(
          "Please Give next Follow Up date should be Future Date of FollowUp"
        );
        setCurrentFollowUpDate("");
        setNextFollowUpDate(null);
      }
    }
  }, [currentFollowUpDate, nextFollowUpDate]);

  const [fromDate1, setFromDate1] = useState("");
  const [toDate1, setToDate1] = useState("");
  const [range1, setRange1] = useState("");
  const [next, setNext] = useState("");
  const getUserHistory = async (id) => {
    await getFollowupHistory(id)
      .then((res) => {
        console.log(res);
        setCommentData(res?.data?.data?.results);
        setNext(res?.data?.data?.next);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function loadData() {
    loadMoreData(next)
      .then((res) => {
        console.log(res);
        setCommentData([...commentData, ...res?.data?.data?.results]);
        setNext(res?.data?.data?.next);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    if (!visible) {
      setCurrentFollowUpDate("");
      setComment("");
      setNextFollowUpDate(null);
    }
  }, [visible]);
  const dateErrorFunction = () => {
    if (fromDate > toDate && fromDate !== "" && toDate !== "") {
      errorToast("From Date Should be smaller than To Date");
      setFromDate("");
      setToDate("");
    }
  };

  function handleSubmitFollowUp(e) {
    e.preventDefault();
    if (currentFollowUpDate === "") {
      errorToast("Please Give Follow Up Date");
      return;
    }
    if (status === "") {
      errorToast("Please select the Status");
      return;
    }
    if (comment === "" && status === "pending") {
      errorToast("Please Give the Comment");
      return;
    }
    if (status === "pending") {
      if (nextFollowUpDate === null) {
        errorToast("Please give the next Follow Up Date");
        return;
      }
    }
    setSaveLoading(true);
    saveFollowup({
      comment: comment,
      followed_date: currentFollowUpDate,
      status: status,
      course_applied: selected?.id,
      next_follow_up_date: nextFollowUpDate,
    } , access)
      .then((res) => {
        console.log(res);
        successToast("Status Saved Successfully");
        setSaveLoading(false);
        setVisible(false);
      })
      .catch((err) => {
        console.log(err);
        errorToast("Something Went wrong, try again");
        setSaveLoading(false);
      });
  }

  useEffect(() => {
    function loadData(resNext) {
      if (resNext == null) {
        return;
      }

      loadMoreData(resNext)
        .then((res) => {
          setInterestedData((prev) => [...prev, ...res?.data?.data?.results]);
          loadData(res?.data?.data?.next);
          
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (
      (fromDate !== "" && toDate !== "") ||
      range !== "" ||
      (fromDate === "" && toDate === "" && range === "")
    ) {
      InterestedStudentsData(fromDate, toDate, range)
        .then((res) => {
          console.log(res, "InterestedStudents Data");
          setInterestedData(res?.data?.data?.results);
          loadData(res?.data?.data?.next);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (
      (fromDate1 !== "" && toDate1 !== "") ||
      range !== "" ||
      (fromDate1 === "" && toDate1 === "" && range1 === "")
    ) {
      InterestedStudentsData(fromDate, toDate, range)
        .then((res) => {
          console.log(res, "InterestedStudents Data");
          setInterestedData(res?.data?.data?.results);
          loadData(res?.data?.data?.next);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [fromDate, toDate, range,fromDate1,toDate1, range1]);
  useEffect(()=>{
    setFilteredData({
      followup: interestedData.filter((item)=>item.status==='pending'),
      admitted: interestedData.filter((item)=>item.status==='admitted'),
      rejected: interestedData.filter((item)=>item.status==='rejected'),
    });
  },[interestedData])
  const ChartData = [
    {name:"Total Interested",percent:interestedData,color:"#A09CE1"},
    {name:"Follow-up",percent:filteredData['followup'],color:"#E19FBA"},
    {name:"Admitted",percent:filteredData['admitted'],color:"#E59E18"},
    {name:"No Admission",percent:filteredData['rejected'],color:"#50AF95"}
  ]
  function givePercentage(num){
    return Math.round((num/interestedData.length)*100);
  }

  return (
    <div className=" mb-6 mt-2 institute-dashboard-followup">
      <div
        className="h-100 mb-6 w-100"
        style={{
          minWidth: "100%",
          maxWidth: "auto",
          borderRadius: "20px",
          borderTop: "4px solid #63c3ec",
          background: "#FFFFFF",
          boxShadow: "-1px 3px 20px 0px #0000001A",
          minHeight: "380px",
        }}
      >
        <div className="h-25 w-100" style={{ maxHeight: "350px" }}>
          <p
            className="ms-4 mt-2 h-25 w-auto"
            style={{
              fontWeight: "500",
              fontSize: "18px",
              color: "#337B99",
              textDecoration: "underline 1px #2A737A66",
              textUnderlineOffset: "10px",
            }}
          >
            Interested Students
          </p>
          <div className="h-50 ps-4 w-100 flex flex-row flex-wrap gap-2">
            <input
              type="text "
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => {
                if (fromDate === "") {
                  e.target.type = "text";
                }
                dateErrorFunction();
              }}
              value={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value);
                setRange("");
              }}
              className="w-50 dateBox"
              placeholder="From Date 🗓️"
            />
            <input
              type="text"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => {
                if (toDate === "") {
                  e.target.type = "text";
                }
                dateErrorFunction();
              }}
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value);
                setRange("");
              }}
              className="w-50 dateBox"
              placeholder="To Date 🗓️"
            />

            <select
              className="w-25 rounded position-relative top-0 end-25"
              value={range}
              onChange={(e) => {
                setRange(e.target.value);
                setFromDate("");
                setToDate("");
              }}
              style={{ border: "1px solid #005A80", maxHeight: "35px" }}
            >
              <option>Select Range</option>
              <option value="one_day">1D</option>
              <option value="one_week">1W</option>
              <option value="one_month">1M</option>
              <option value="three_month">3M</option>
              <option value="six_month">6M</option>
              <option value="one_year">1Y</option>
              <option value="three_year">3Y</option>
              <option value="five_year">5Y</option>
            </select>
          </div>
        </div>
        <div
          className=" rounded p-3 w-75 mb-2 mt-2  m-auto"
          style={{
            maxHeight: "260px",
            overflowY: "auto",
            minWidth: "94%",
            overflowX: "hidden",
          }}
        >
          {filteredData['followup'].length > 0 ? filteredData['followup'].map((item) => {
            return (
              <span
                className="d-flex flex-row justify-content-between"
                style={{ maxHeight: "50px" }}
              >
                <p
                  className="ms-2 w-75"
                  style={{
                    borderBottom: "1px solid #2A737A66",
                    color: "#535353",
                  }}
                >
                  {item.name}
                </p>
                <FaEye
                  onClick={() => {
                    setVisible(true);
                    setSelected(item);
                  }}
                  style={{
                    width: "30px",
                    height: "25px",
                    color: "#3FBDC9",
                    border: "1px solid #3FBDC9",
                    padding: "2px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                />
              </span>
            );
          }) 
        : <center>No Data Found</center>
        }
        </div>
      </div>

      <div
        className="h-100 mb-6"
        style={{
          maxWidth: "auto",
          borderRadius: "20px",
          borderTop: "4px solid #63c3ec",
          background: "#FFFFFF",
          boxShadow: "-1px 3px 20px 0px #0000001A",
        }}
      >
        

        <div className="h-25 w-100" style={{ maxHeight: "350px" }}>
          <p
            className="ms-4 mt-2 h-25 w-auto"
            style={{
              fontWeight: "500",
              fontSize: "18px",
              color: "#337B99",
              textDecoration: "underline 1px #2A737A66",
              textUnderlineOffset: "10px",
            }}
          >
            Interested Students
          </p>
          <div className="h-50 ps-4 w-100 ">
            <input
              type="text "
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => {
                if (fromDate1 === "") {
                  e.target.type = "text";
                }
                dateErrorFunction();
              }}
              value={fromDate1}
              onChange={(e) => {
                setFromDate1(e.target.value);
                setRange1("");
              }}
              className="w-50 dateBox"
              placeholder="From Date 🗓️"
            />
            <input
              type="text"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => {
                if (toDate1 === "") {
                  e.target.type = "text";
                }
                dateErrorFunction();
              }}
              value={toDate1}
              onChange={(e) => {
                setToDate1(e.target.value);
                setRange1("");
              }}
              className="w-50 dateBox"
              placeholder="To Date 🗓️"
            />

            <select
              className="w-25 p-1 rounded position-relative top-0 end-25"
              value={range1}
              onChange={(e) => {
                setRange1(e.target.value);
                setFromDate1("");
                setToDate1("");
              }}
              style={{ border: "1px solid #005A80", maxHeight: "35px" }}
            >
              <option>Select Range</option>
              <option value="one_day">1D</option>
              <option value="one_week">1W</option>
              <option value="one_month">1M</option>
              <option value="three_month">3M</option>
              <option value="six_month">6M</option>
              <option value="one_year">1Y</option>
              <option value="three_year">3Y</option>
              <option value="five_year">5Y</option>
            </select>
          </div>
        </div>

        <div className="w-100 p-4">
          {ChartData.map((item) => {
            return (
              <div
                className="w-100 m-auto d-flex justify-content-around flex-row pe-3 pt-2"
                style={{
                  borderRight: "1px solid #2A737A66",
                  maxHeight: "50px",
                }}
              >
                <p
                  className="w-25 me-2"
                  style={{
                    borderRight: "1px solid black",
                    padding: "0px 5px",
                    height: "100%",
                  }}
                >
                  {item?.name}
                </p>
                <div
                  className="colorDivWrapper w-50 me-5"
                  style={{ border: "none", height: "25px" }}
                >
                  <div
                    className="colorDiv"
                    style={{
                      height: "100%",
                      width: `${givePercentage(item?.percent?.length)}%`,
                      background: `${item?.color}`,
                      textAlign: "end",
                      paddingRight: "5px",
                    }}
                  >
                    {item.percent?.length}
                  </div>
                </div>
                <FaEye
                  onClick={() => {
                    setSelected(item.percent);
                    setOpeningData(item.percent);
                    setShow(true);
                  }}
                  style={{
                    width: "30px",
                    height: "25px",
                    color: "#3FBDC9",
                    border: "1px solid #3FBDC9",
                    padding: "2px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>

      <Modal open={visible} onClose={() => setVisible(false)} center={true}>
        <div style={{ minHeight: "450px", maxWidth: "350px", margin: "auto" }}>
          <div
            className="d-flex flex-row justify-content-around mt-3"
            style={{ borderBottom: "1px solid #2A737A66", color: "#767676" }}
          >
            <h6 className="card-lable-heading">Name - </h6>
            <h6 className="card-lable-value">{selected?.name}</h6>
          </div>
          <div
            className="d-flex flex-row justify-content-around mt-2"
            style={{ borderBottom: "1px solid #2A737A66", color: "#767676" }}
          >
            <h6 className="card-lable-heading">Course Name - </h6>
            <h6 className="card-lable-value">{selected?.course}</h6>
          </div>
          <div
            className="d-flex flex-row justify-content-around mt-2"
            style={{ borderBottom: "1px solid #2A737A66", color: "#767676" }}
          >
            <h6 className="card-lable-heading">Batch - </h6>
            <h6 className="card-lable-value">
              {monthsData[selected?.batch_details?.month - 1]?.month}
            </h6>
          </div>
          <div
            className="d-flex flex-row justify-content-around mt-2"
            style={{ borderBottom: "1px solid #2A737A66", color: "#767676" }}
          >
            <h6 className="card-lable-heading">Time - </h6>
            <h6 className="card-lable-value">
              {selected?.batch_details?.start_time} -{" "}
              {selected?.batch_details?.end_time}
            </h6>
          </div>
          {selected?.status === "pending" && (
            <div
              className="d-flex flex-row justify-content-between mt-2"
              style={{ color: "#767676" }}
            >
              <h6 className="card-lable-heading">Follow-up Date - </h6>
              <input
                className="w-50"
                type="date"
                value={currentFollowUpDate}
                onChange={(e) => setCurrentFollowUpDate(e.target.value)}
              />
            </div>
          )}

          {selected?.status === "pending" && (
            <div
              className="d-flex flex-row justify-content-between mt-2"
              style={{ borderRadius: "5px", color: "#767676" }}
            >
              <textarea
                rows="3"
                placeholder="comments"
                className="w-100 ps-1 sailors-Input-field"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
          )}
          {selected?.status === "pending" && (
            <div
              className="d-flex flex-row justify-content-between mt-2"
              style={{ color: "#767676" }}
            >
              <h6 className="card-lable-heading">Next Follow-up Date - </h6>
              <input
                className="w-50"
                type="date"
                value={nextFollowUpDate}
                onChange={(e) => setNextFollowUpDate(e.target.value)}
              />
            </div>
          )}
          {selected?.status === "pending" && (
            <div
              className="d-flex flex-row justify-content-between mt-2"
              style={{ color: "#767676" }}
            >
              <h6 className="card-lable-heading">Status - </h6>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="rounded-md"
              >
                <option value="">Select Status</option>
                <option value="pending">Follow Up</option>
                <option value="admitted">Admitted</option>
                <option value="rejected">Non-Admitted</option>
              </select>
            </div>
          )}

          <div
            className="d-flex flex-row justify-content-center mt-5"
            style={{ color: "#767676" }}
          >
            {selected?.status === "pending" && <button
              className="rounded p-1 w-25 me-2"
              onClick={handleSubmitFollowUp}
              style={{ background: "#005A80", color: "white" }}
            >
              { saveLoading ? <LoaderExtraSmall/> : "Save"}
            </button>}
            {selected?.status !== "pending" &&
              <button className="rounded p-1 w-25 me-2"
              onClick={()=>setVisible(false)}
              style={{ background: "#005A80", color: "white" }}>
                Close
              </button>
            }
            
            <button
              className="rounded p-1 w-25 ms-2"
              onClick={() => {
                setVisibleDetails(true);
                getUserHistory(selected?.id);
              }}
              style={{
                background: "white",
                color: "#005A80",
                border: "1px solid #005A80",
              }}
            >
              Details
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={visibleDetails}
        onClose={() => setVisibleDetails(false)}
        center={true}
      >
        <div
          id="scrollableDiv"
          className="min-w-60"
          // style={{ minHeight: "450px", minWidth: "750px", margin: "auto" }}
        >
          <InfiniteScroll
            dataLength={commentData.length}
            next={loadData}
            hasMore={next !== null ? true : false}
            loader={<h6>Loading...</h6>}
            scrollableTarget="scrollableDiv"
          >
            <table className="table">
              <thead style={{ borderBottom: "1px solid #2A737A66" }}>
                <tr style={{ display: "flex", justifyContent: "flex-start" }}>
                  <th
                    style={{
                      fontWeight: "700",
                      maxWidth: "120px",
                      color: "#005A80",
                    }}
                  >
                    Date
                  </th>
                  <th
                    style={{
                      textAlign: "start",
                      fontWeight: "700",
                      color: "#005A80",
                    }}
                  >
                    Comment
                  </th>
                  <th
                    style={{
                      textAlign: "start",
                      fontWeight: "700",
                      color: "#005A80",
                    }}
                  >
                    Next Follow-up Date
                  </th>
                  <th
                    style={{
                      textAlign: "start",
                      fontWeight: "700",
                      color: "#005A80",
                      maxWidth: "120px",
                    }}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {commentData?.length > 0 ?
                  commentData?.map((item, index) => {
                    return (
                      <tr
                        key={item.id}
                        className=""
                        style={{
                          display: "flex",
                          borderBottom: "1px solid #2A737A66",
                          justifyContent: "space-around",
                        }}
                      >
                        <td
                          style={{
                            textAlign: "start",
                            maxWidth: "120px",
                            color: "#767676",
                          }}
                        >
                          {item?.followed_date}
                        </td>
                        <td style={{ textAlign: "start", color: "#767676" }}>
                          {item?.comment}
                        </td>
                        <td style={{ textAlign: "start", color: "#767676" }}>
                          {item?.next_follow_up_date || "NA"}
                        </td>
                        <td
                        className="capitalize"
                          style={{
                            textAlign: "start",
                            color: "#767676",
                            maxWidth: "120px",
                          }}
                        >
                          {item?.status}
                        </td>
                      </tr>
                    );
                  }) : <strong>No Data Found</strong>}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </Modal>

      <Modal open={show} onClose={() => setShow(false)} center={true}>
        <div className="min-w-60"
        >
          <table className="table">
            <thead style={{ borderBottom: "1px solid #2A737A66" }}>
              <tr style={{ display: "flex", justifyContent: "flex-start" }}>
                <th
                  style={{
                    fontWeight: "700",
                    maxWidth: "90px",
                    color: "#005A80",
                  }}
                >
                  Sr No.
                </th>
                <th
                  style={{
                    fontWeight: "700",
                    maxWidth: "calc(100/4)",
                    color: "#005A80",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    textAlign: "start",
                    fontWeight: "700",
                    color: "#005A80",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    textAlign: "start",
                    fontWeight: "700",
                    color: "#005A80",
                    maxWidth: "100px",
                  }}
                >
                  Comments
                </th>
              </tr>
            </thead>
            <tbody>
              {openingData?.length > 0 &&
                openingData?.map((item, index) => {
                  return (
                    <tr
                      key={item.id}
                      className="text-base"
                      style={{
                        display: "flex",
                        borderBottom: "1px solid #2A737A66",
                        justifyContent: "space-around",
                      }}
                    >
                      <td
                        style={{
                          textAlign: "start",
                          maxWidth: "90px",
                          color: "#767676",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          maxWidth: "calc(100/4)",
                          color: "#767676",
                        }}
                      >
                        {item?.name}
                      </td>
                      <td
                      className="capitalize"
                        style={{
                          textAlign: "start",
                          maxWidth: "calc(100/4)",
                          color: "#767676",
                        }}
                      >
                        {item?.status}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#767676",
                          maxWidth: "100px",
                        }}
                      >
                        <FaEye
                          onClick={() => {
                            setVisibleDetails(true);
                            getUserHistory(item?.id);
                          }}
                          style={{
                            width: "30px",
                            height: "25px",
                            color: "#3FBDC9",
                            border: "1px solid #3FBDC9",
                            padding: "2px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default InstituteFollowCard;
