import axios from "axios";
import { base_url } from "../utils/endpoints/endpoints";
// const token = JSON.parse(localStorage.getItem('token'));
// let access = token?.access;

export const DeleteLearningCourse = (id, access)=>{
    
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/course/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err);
        })
    })
}
export const deleteUserShip = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/users/ship_type/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err);
        })
    })
}
export const deleteUserSubject = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/course/subject/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err);
        })
    })
}

export const deleteInstitute = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/users/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err);
        })
    })
}

export const deleteCourseModule = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/course/course_module/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err);
        })
    })
}

export const deleteJobRank = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/job/job_role/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const deleteJobCategory = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/job/job_category/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const deleteCreatedCertificate = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/users/certificate_type/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const deleteCertificateExpiry = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/utilities/todo_notification_schedule/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const deleteRankQuestion = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/utilities/question/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
            console.log(err)
        })
    })
}

export const deleteModuleDocument = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/course/course_module_doc/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const deleteNewsAndAnnouncement = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/utilities/news_announcements/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const deleteAdvertisement = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/utilities/ad/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const deleteCourseDetails = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/course/course_detail/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const deleteJobPostByCompany = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/job/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const deleteShipOfCompany = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/users/ship/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const deleteAddedQuestion = (id,access)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.delete(`${base_url}/api/v1/web/utilities/question/${id}/`,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


