import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const AgeBar = ({ ageGroupData }) => {
    const chartRef = useRef(null);
    const [gradient, setGradient] = useState(null);

    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current;
            const ctx = chart.ctx;
            const gradientFill = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
            gradientFill.addColorStop(0, '#87DBFF'); // Light Blue
            gradientFill.addColorStop(1, '#036181'); // Dark Blue
            setGradient(gradientFill);
        }
    }, [ageGroupData]); // Recalculate when data updates

    let labels = Object.keys(ageGroupData);
    let values = Object.values(ageGroupData);

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Attrition via Age',
                data: values,
                backgroundColor: gradient || '#5C95AD', // Fallback color
                barPercentage: 0.7,
                categoryPercentage: 0.8,
                stack: 'Stack 1',
            },
        ],
    };

    const options = {
        responsive: true,
        aspectRatio: 1.1,
        plugins: {
            datalabels: {
                display: true,
                color: "black",
                formatter: Math.round,
                anchor: "end",
                offset: -20,
                align: "start"
            },
            legend: {
                display: true
            },
            tooltip: {
                mode: 'index',
                intersect: true,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    return (
        <div>
            <Bar ref={chartRef} data={data} options={options} />
        </div>
    );
};

export default AgeBar;
