import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sidebar:true,
    loggedInUser:{},
    token:{},
    sidebarData:[],
    page:'Pavan'
}

export const commonslice = createSlice({
    name:'common',
    initialState,
    reducers:{
        setSidebar:(state,action)=>{
            state.sidebar= action.payload
        },
        setLoginUser:(state,action)=>{
            state.loggedInUser = action.payload
        },
        setSidebarData:(state,action)=>{
            state.sidebarData = [...action.payload];
        },
        setPage:(state,action)=>{
            state.page = action.payload
        },
        setToken:(state,action)=>{
            state.token = action.payload
        }
    }
})

export const {
    setSidebar,
    setLoginUser,
    setSidebarData,
    setPage,
    setToken
} = commonslice.actions;
export default commonslice.reducer;