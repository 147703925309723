import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { resetPassword } from "../../Apis/postApis";
import { IoMdKey } from "react-icons/io";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { successToast } from "../../utils/helperFunctions/HelperFunctions";
import useToken from "../../customHook/useToken";

const ResetPassword = () => {
  const access = useToken();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowpassword] = useState(false);
  const passwordMatchErrorToast = () => toast.error("Password Not Matching");
  const passwordEmptyErrorToast = () =>
    toast.error("Password Field cannot be empty");
  const errorMessage = (err) => toast.error(err);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword == "" || confirmPassword == "") {
      passwordEmptyErrorToast();
      return;
    }
    if (newPassword !== confirmPassword) {
      passwordMatchErrorToast();
      return;
    } else {
      resetPassword({ password: newPassword },access)
        .then((res) => {
          console.log(res);
          successToast("Password Changed Successfully");
          navigate("/login");
        })
        .catch((err) => {
          console.log(err);
          errorMessage(err?.response.data?.message);
        });
    }
  };
  return (
    <div
      className="MainContainer container-fluid d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <ToastContainer
        position="top-left"
        style={{
          maxHeight: "50px",
        }}
      />
      <form className="login-form-container max-h-[30rem] px-3">
        
          <img
            src="/assets/authAssets/Sailors-Hub-logo.png"
            alt="img"
            className="w-44 mx-auto mt-10 md:mt-2"
            style={{ filter: "drop-shadow(10px 10px 5px #999)" }}
          />
        
        <div className="flex flex-col justify-start items-center h-80 px-1">
          <div className="field-container login-email-field h-16 w-auto">
            <labal className="block h-4 text-[#767676] text-base font-semibold font-montserrat mb-2">
              New Password
            </labal>
            <div className="inputWithIcon">
              <IoMdKey
                style={{
                  margin: "2px -17px 0px 10px",
                  color: "#0000004D",
                  width: "30px",
                  height: "30px",
                }}
              />
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="inputField"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full h-10 pl-3 pr-4 shadow-custom py-2 text-sm border rounded-xl border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-400"
              />
              {!showPassword ? (
                <FaEyeSlash
                  onClick={() => setShowpassword(!showPassword)}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    color: "#0000004D",
                    width: "20px",
                    height: "20px",
                    right: "8",
                    top: "9",
                  }}
                />
              ) : (
                <FaEye
                  onClick={() => setShowpassword(!showPassword)}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    color: "#0000004D",
                    width: "20px",
                    height: "20px",
                    right: "8",
                    top: "9",
                  }}
                />
              )}
            </div>
          </div>
          <div className="field-container login-password-field mb-3 w-auto">
            <labal className="block h-4 text-[#767676] text-base font-semibold font-montserrat mb-2">
              Confirm Password
            </labal>
            <div className="inputWithIcon">
              <IoMdKey
                style={{
                  margin: "2px -17px 0px 10px",
                  color: "#0000004D",
                  width: "30px",
                  height: "30px",
                }}
              />
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="inputField"
                value={confirmPassword}
                className="w-full h-10 pl-3 pr-4 shadow-custom py-2 text-sm border rounded-xl border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-400"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {!showPassword ? (
                <FaEyeSlash
                  onClick={() => setShowpassword(!showPassword)}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    color: "#0000004D",
                    width: "20px",
                    height: "20px",
                    right: "8",
                    top: "9",
                  }}
                />
              ) : (
                <FaEye
                  onClick={() => setShowpassword(!showPassword)}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    color: "#0000004D",
                    width: "20px",
                    height: "20px",
                    right: "8",
                    top: "9",
                  }}
                />
              )}
            </div>
          </div>
          <button
            className=" mt-4 mx-auto md:mx-0  w-auto h-10 bg-[#2A737A] text-white py-2 px-3 rounded flex flex-row items-center gap-x-3"
            onClick={(e) => handleSubmit(e)}
          >
            <img
              src="./assets/authAssets/loginbtn.svg"
              className="btnsvg"
              alt="logo"
            />{" "}
            <span className="text-sm md:test-base font-montserrat">Set Password</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
