import React, { useState, useEffect } from 'react'
import { getCities, getCountries, getOneInstituteData, getStates } from '../../../Apis/getApis';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { v4 as uuidV4 } from "uuid";
import { updateInstituteDetails } from '../../../Apis/updateApis';
import { successToast, uploadFile } from '../../../utils/helperFunctions/HelperFunctions';
import { allowedTypesImages } from '../../../utils/variables/folderStructureFiles';
import validator from 'validator';
import useToken from '../../../customHook/useToken';
const InstituteDetails = () => {
    const access = useToken()
    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cities, setCitiesList] = useState([]);
    const [loading, setLoading] = useState(false);
    // form fields
    const [buttonName, setButtonName] = useState("Institute/College");
    const [contactPerson, setContactPerson] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [entityName, setEntityName] = useState('');
    const [email, setEmail] = useState('');
    const [websiteName, setWebsiteName] = useState('');
    const [countryName, setCountryName] = useState("Country");
    const [city, setCity] = useState("City");
    const [state, setState] = useState("State");
    const [address, setAddress] = useState('');
    const [otherAddress, setOtherAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [otherPhone, setOtherPhone] = useState('');
    const [image, setImage] = useState('');
    const [countryId, setCountryId] = useState();
    const [stateId, setStateId] = useState();
    const [cityId, setCityId] = useState();
    const [file, setFile] = useState(null);
    const [instituteData, setInstituteData] = useState('');
    const instituteInfo = JSON.parse(localStorage.getItem('userData'));
    const [phoneCode, setPhoneCode] = useState();

    const errorToast = (msg) => toast.error(msg);



    const [uploading, setUploading] = useState(false);


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (allowedTypesImages.includes(selectedFile?.type)) {
            setFile(selectedFile);
            let res = uploadFile(selectedFile);
            res.then((data) => {
                console.log(data, "uploadedImage")
                setImage(data);
            })
        } else {
            errorToast('Invalid file type. Only images are allowed.');
        }
    };

    const getCountryById = (id) => {
        for (let i = 0; i < countriesList.length; i++) {
            if (countriesList[i].id == id) {
                return countriesList[i]
            }
        }
        return 'Country';
    }

    const makeCountry = async (item) => {
        setCountryId(item?.country);
        setPhoneCode(item?.phone_code)
        await getStates(item?.country)
            .then((res) => {
                setStateList(res?.data?.data);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const makeState = async (item) => {
        setStateId(item)
        await getCities(item)
            .then((res) => {
                setCitiesList(res?.data?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const makeCity = (item) => {
        setCityId(item?.id);
    }

    const saveForm = async (e) => {
        e.preventDefault();
        let object = {
            first_name: entityName,
            contact_person: contactPerson,
            website: websiteName,
            mobile_number1: phone,
            mobile_number2: otherPhone,
            address1: address,
            address2: otherAddress,
            state: stateId,
            city: cityId,
            country: countryId,
            user_type: "institute",
            profile_pic: image,
            pin_code: "string"
        }
        await updateInstituteDetails(instituteInfo?.id, object, access)
            .then((res) => {
                console.log(res);
                successToast("Institute Info Updated Successfully")
                getInstituteDetails(countriesList)
            })
            .catch((err) => {
                console.log(err);
                errorToast("Something went wrong");
            })
    }

    async function getInstituteDetails(countryData) {
        await getOneInstituteData(instituteInfo?.id)
            .then((res) => {
                console.log(res);
                const data = res?.data?.data;
                setInstituteData(data);

                setEmail(data?.email);
                setButtonName(data?.user_type);
                setContactPerson(data?.contact_person || "NA");
                setAddress(data?.address1);
                setEntityName(data?.first_name);
                setCountryId(data?.country)
                setCityId(data?.city);
                setWebsiteName(data?.website);
                setPhone(data?.mobile_number1);
                setOtherPhone(data?.mobile_number2);
                setOtherAddress(data?.address2);
                setStateList([]);
                setStateId(data?.state);
                makeCountry(data)
                makeState(data?.state);

            })
            .catch((err) => {
                console.log(err)
            })
    }

    function validUrl() {
        if (websiteName !== '') {
            if (!validator.isURL(websiteName)) {
                errorToast("Please enter valid url");
                setWebsiteName('');
                return;
            }
        }
    }

    useEffect(() => {
        setLoading(true);
        getCountries()
            .then((res) => {
                setLoading(false);
                console.log(res, "countries")
                setCountriesList(res?.data?.data)
                setTimeout(() => {
                    getInstituteDetails(res?.data?.data);
                }, 200)
            })
            .catch((err) => {
                setLoading(false);
                console.log(err)
            })

            return(()=>{
                setCountriesList([])
            })

    }, [])
    return (
        <div>
            {!uploading ? <div className='boarding-form fluid-container' style={{ marginBottom: "20px" }}>
                <div className='instituteOnboarding-list-view'>
                    <div className='headerPart'>
                        <h4>Institutes /  College Details</h4>
                    </div>
                </div>
                

                <form className=" mt-5 p-4 bg-white shadow rounded-top position-relative">
                    {/* <span className='mustStar' style={{textAlign:'end', fontSize:"15px", position:'absolute',top:"-20px",left:"-16px"}}>Fields marked (*) are mandatory.</span> */}
                    <div className="row mb-4 d-flex flex-row justify-content-around gap-y-5">
                        <div className="col-md-5">
                            {/* <label className="form-label" htmlFor="institute">
                                Institute/College<span className='mustStar'>*</span>
                            </label> */}
                            <select style={{ boxShadow: "-1px 3px 20px 0px #0000001A", minHeight: "40px" }} id="institute" value={buttonName} onChange={(e) => setButtonName(e.target.value)} className="form-select sailors-Input-field">
                                <option value=''>Select Institute/College </option>
                                <option value={'institute'}>Institute</option>
                                <option value={'college'}>College</option>
                            </select>
                        </div>
                        <div className="col-md-5">
                            {/* <label className="form-label" htmlFor="contactPerson">
                                Contact Person<span className='mustStar'>*</span>
                            </label> */}
                            <input style={{ boxShadow: "-1px 3px 20px 0px #0000001A" }} placeholder='Contact Person' required id="contactPerson" type="text" value={contactPerson} onChange={(e) => setContactPerson(e.target.value)} className=" sailors-Input-field" />
                        </div>
                    </div>

                    <div className="row mb-4 d-flex flex-row justify-content-around gap-y-5">
                        <div className="col-md-5">
                            {/* <label className="form-label" htmlFor="instituteName">
                                Institute/College Name<span className='mustStar'>*</span>
                            </label> */}
                            <input style={{ boxShadow: "-1px 3px 20px 0px #0000001A" }} placeholder='Institute/College Name' required id="instituteName" value={entityName} onChange={(e) => setEntityName(e.target.value)} type="text" className=" sailors-Input-field" />
                        </div>
                        <div className="col-md-5">
                            {/* <label className="form-label" htmlFor="email">
                                Email<span className='mustStar'>*</span>
                            </label> */}
                            <div className='sailors-Input-field flex justify-start items-center opacity-80'>{email}</div>
                        </div>
                    </div>

                    <div className="row mb-4 d-flex flex-row justify-content-around gap-y-5">
                        <div className="col-md-5">
                            {/* <label className="form-label" htmlFor="websiteName">
                                Website URL<span className='mustStar'>*</span>
                            </label> */}
                            <input style={{ boxShadow: "-1px 3px 20px 0px #0000001A" }} placeholder='Website URL' id="websiteName" value={websiteName} onChange={(e) => setWebsiteName(e.target.value)} onBlur={validUrl} type="text" className="form-control sailors-Input-field" />
                        </div>
                        <div className="col-md-5">
                            {/* <label className="form-label" htmlFor="country">
                                Country<span className='mustStar'>*</span>
                            </label> */}
                            <select id="country" style={{ minHeight: "40px", boxShadow: "-1px 3px 20px 0px #0000001A" }} value={countryId} onChange={(e) => makeCountry(getCountryById(e.target.value))} className="form-select sailors-Input-field">
                                <option value=''>Select Country</option>
                                {countriesList?.map((item) => {
                                    return (
                                        <option value={item?.id} key={uuidV4()} className="dropdown-item" style={{ height: "50px" }}>{item?.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="row mb-4 d-flex flex-row justify-content-around gap-y-5">
                        <div className="col-md-5">
                            {/* <label className="form-label" htmlFor="state">
                                State
                            </label> */}
                            <select id="state" style={{ minHeight: "40px", boxShadow: "-1px 3px 20px 0px #0000001A" }} value={stateId} onChange={(e) => makeState(e.target.value)} className="form-select sailors-Input-field">
                                <option>Select State</option>
                                {stateList?.map((item) => {
                                    return (
                                        <option value={item.id} key={uuidV4()} className="dropdown-item" style={{ height: "50px" }} >{item?.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-md-5">
                            {/* <label className="form-label" htmlFor="city">
                                City
                            </label> */}
                            <select id="city" value={cityId} style={{ minHeight: "40px", boxShadow: "-1px 3px 20px 0px #0000001A" }} onChange={(e) => setCityId(e.target.value)} className="form-select sailors-Input-field">
                                <option>Select City</option>
                                {cities?.map((item) => {
                                    return (
                                        <option value={item?.id} key={uuidV4()} className="dropdown-item" style={{ height: "50px" }}>{item?.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="row mb-4 d-flex flex-row justify-content-around gap-y-5" >
                        <div className="col-md-5">
                            {/* <label className="form-label" htmlFor="address">
                                Address<span className='mustStar'>*</span>
                            </label> */}
                            <textarea rows="4" placeholder='Address' id="address" value={address} onChange={(e) => setAddress(e.target.value)} type="text" className="form-control sailors-Input-field" />
                        </div>
                        <div className="col-md-5">
                            {/* <label className="form-label" htmlFor="otherAddress">
                                Other Address
                            </label> */}
                            <textarea rows="4" placeholder='Other Address' id="otherAddress" value={otherAddress} onChange={(e) => { setOtherAddress(e.target.value) }} type="text" className="form-control sailors-Input-field" />
                        </div>
                    </div>

                    <div className="row mb-4 d-flex flex-row justify-content-around">
                        <div className="col-md-5">
                            <div className="flex flex-row items-center">
                                {/* <div className="input-group-text" style={{ maxWidth: "70px", textAlign: 'center' }}> {phoneCode !== "" ? phoneCode : "📞"}</div> */}
                                <input placeholder='Phone 1' id="contactNumber" type="number" value={phone} onChange={(e) => setPhone(e.target.value)} className=" sailors-Input-field" />
                            </div>
                        </div>
                        <div className="col-md-5">
                            
                        <div className="flex flex-row items-center">
                                {/* <div className="input-group-text" style={{ maxWidth: "70px", textAlign: 'center' }}> {phoneCode !== "" ? phoneCode : "📞"}</div> */}
                                <input value={otherPhone} placeholder='Phone 2' onChange={(e) => setOtherPhone(e.target.value)} id="otherContactNumber" type="number" className=" sailors-Input-field" />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4 d-flex flex-row justify-content-around">
                        
                        <input id="uploadLogo" type="file" onChange={(e) => handleFileChange(e)} className="col-md-5 w-50 p-2 px-5 sailors-Input-field" />
                    </div>

                    <div className='form-btns' style={{ marginBottom: "20px", marginTop: "50px" }}>
                        <button type="submit" onClick={(e) => saveForm(e)} className="bg-[#0a66c2] w-auto px-4 py-2 rounded-md">Save</button>
                        {/* <button type='button' onClick={handleCancel} className="btn btn-OnboardForm">Cancel</button> */}
                    </div>
                </form>
            </div> : <div className='d-flex justify-content-center align-items-center' style={{ width: "100%", height: "100%" }}>

                <img src="/assets/commonAssets/yelloShip.gif" alt="loader" style={{ width: "200px", height: "200px", borderRadius: "50%" }} />

            </div>}
        </div>
    )
}

export default InstituteDetails;
