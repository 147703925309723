
import React, { useEffect, useState } from 'react'
import { FaCaretDown, FaPen } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BallTriangle } from 'react-loader-spinner';
import { getAllCreateCertificate, getCountries } from '../../../../Apis/getApis';
import { saveCreatedCertificate } from '../../../../Apis/postApis';
import Swal from 'sweetalert2';
import { deleteCreatedCertificate } from '../../../../Apis/deleteApis';
import { updateCreatedcertificate } from '../../../../Apis/updateApis';
import { deleteToast, errorToast, successToast } from '../../../../utils/helperFunctions/HelperFunctions';
import useToken from '../../../../customHook/useToken';

const CreateCertificate = () => {
  const access = useToken()
  const [certificateName, setCertificateName] = useState('');
  const [width, setWidth] = useState(window.innerWidth);
  const [certificateList, setCertificateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [search, setSearch] = useState('')
  const [certificateDescription, setCertificateDescription] = useState('');
  const [certificateType, setCertificateType] = useState("Choose Cerificate Type");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const deleteCertificate = (id) => {

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete Certificate" ,
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes,Delete Certificate!" 
  }).then((result) => {
      if (result.isConfirmed) {
        deleteCreatedCertificate(id,access)
        .then((res)=>{
          console.log(res)
          getAllCreatedCertificates();
          handleCancle();
          deleteToast("Certificate is deleted.");
        })
        .catch((err)=>{
          console.log(err)
        })

          
      }
    })


   
  }
  const handleCancle = ()=>{
    setIsEdit(false);
    setCertificateName('');
    setCertificateDescription('');
    setCertificateType('Choose Cerificate Type');
    setSelectedCountries([]);
  }
  const addCountry = (countryData) => {
    const isThere = selectedCountries.some((item) => isEdit ? item.id == countryData.id : item == countryData.id);
    if (!isThere) {
      if (isEdit) {
        setSelectedCountries([...selectedCountries, { id: countryData.id, name: countryData.name }])
      } else {
        setSelectedCountries([...selectedCountries, countryData.id])
      }
    }
  }

  const editCertificate = (item) => {
    setIsEdit(true);
    setSelectedItem(item);
    setCertificateName(item?.name)
    setCertificateDescription(item?.description);
    setCertificateType(item?.type);
    setSelectedCountries(item?.countries)
  }

  const findById = (id) => {
      for (let i = 0; i < countryList.length; i++) {
        if (countryList[i].id === id) {
          return countryList[i]
        }
      }
  }

  useEffect(() => {
    setTimeout(() => {

      if (search !== '') {
        let arr = [];
        for (let i = 0; i < countryList.length; i++) {
          if (countryList[i].name.toLowerCase().includes(search.toLowerCase())) {
            const isThere = (data) => {
              for (let j = 0; j < arr.length; j++) {
                if (arr[j].id === data.id) {
                  return true;
                }
              }
              return false;
            }
            if (!isThere(countryList[i])) {
              arr.push(countryList[i]);
            }
          }
        }
        setSearchData([...searchData, ...new Set(arr)])
      } else {
        setSearchData([])
      }
    }, 1000)
  }, [search])

  const removeCountry = (data) => {
    const countries = [...selectedCountries]
    const newCountry = countries.filter((item) => item !== data);
    setSelectedCountries(newCountry);
  }

  function getAllCreatedCertificates() {
    getAllCreateCertificate()
      .then((res) => {
        console.log(res, "certificateList")
        setCertificateList(res?.data?.data?.results)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    setLoading(true);
    getAllCreatedCertificates();
    getCountries()
      .then((res) => {
        setLoading(false);
        setCountryList(res?.data?.data)
      })
      .catch((err) => {
        setLoading(false);
        console.log(err)
      })
  }, [])

  const submitCertificate=  ()=>{
    if(certificateName==""){
      errorToast("Kindly fill the Certificate Name")
      return;
    }
    if(certificateDescription ===""){
      errorToast("Kindly fill the Certificate Description");
      return;
    }
    if(certificateType==="Choose Cerificate Type"){
      errorToast("Kindly choose the certificate type");
      return;
    }
    if(!isEdit){
      saveCreatedCertificate({name:certificateName,countries:selectedCountries,description:certificateDescription,type:certificateType}, access)
      .then((res)=>{
        console.log(res)
        getAllCreatedCertificates();
        handleCancle();
        successToast("Certificate Created Successfully")
      })
      .catch((err)=>{
        console.log(err);
      })
    }else{
      updateCreatedcertificate(selectedItem.id,{name:certificateName,countries:selectedCountries,description:certificateDescription,type:certificateType}, access)
      .then((res)=>{
        console.log(res);
        getAllCreatedCertificates();
        handleCancle();
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }

  return (
    <div className='institute-container animate__animated animate__fadeIn'>
      <div className='instituteOnboarding-list-view'>
        <div className='headerPart'>
          <h4>Certificate</h4>
        </div>
      </div>
      <div className='table-container position-relative'>
        <span className='mustStar' style={{textAlign:'end', fontSize:"15px", position:'absolute',top:"-20px",left:"-16px"}}>Fields marked (*) are mandatory.</span>
        <div className='allDropDown allField-learning-courses'>
          <span className='d-flex add-course-container add-course-country-container justify-content-center' style={{ maxWidth: "100%", padding: "20px" }}>
            <div className="dropdown dropDown-locality">
              <button type="button" className={width < 500 ? "btn drdpbtn sailors-Input-field col" : "btn drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "200px" }}>
                Country<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>
                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                  <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control position-static" placeholder="Search ......" aria-label="Recipient's username" />
                  </div>

                </div>
                {search == '' && <React.Fragment>{countryList.length > 0 ? countryList?.map((item, index) => {
                  return (
                    <div className="dropdown-item" style={{ height: "50px", cursor: "pointer" }} onClick={() => addCountry(item)}>{item?.name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}</React.Fragment>}

                {search !== '' && <>{searchData.length > 0 ? searchData.reverse()?.map((item, index) => {
                  return (
                    <div className="dropdown-item" style={{ height: "50px", cursor: "pointer" }} onClick={() => addCountry(item)}>{item.name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>No Match</center>}</>}

              </div>
            </div>
            <div className={selectedCountries.length > 0 ? 'selectedCountries-container' : 'selectedCountries-container noDataContainer'}>

              {selectedCountries.length > 0 ? <>{selectedCountries.map((item) => {
                return (
                  <div className='selectedCountry' style={{ maxHeight: "50px" }}>
                    <p style={{ marginTop: "37px",height:'auto' }}>{findById(item)?.name}</p>
                    <button onClick={() => removeCountry(item)} type="button" className="close" style={{ width: "15px", height: "15px" }}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )
              })}</> : <center style={{ marginTop: "15px" }}>All Countries</center>}
            </div>
          </span>
          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Certificate Name</p>
            
            <input type='text' value={certificateName} onChange={(e) => setCertificateName(e.target.value)} className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
          </span> */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Certificate Name<span className='mustStar'>*</span></label>
            <div className="col-sm-9">
              <input value={certificateName} onChange={(e) => setCertificateName(e.target.value)} type="text" className="form-control sailors-Input-field" />
            </div>
          </div>

          <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Certificate Description<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <textarea type="text" value={certificateDescription} onChange={(e) => setCertificateDescription(e.target.value)} rows="3" className="form-control sailors-Input-field" placeholder='Here is a Certificate description' />
                        </div>
                    </div>

          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}> Certificate Description</p>
            
            <Textarea
              placeholder='Here is a Certificate description'
              size='sm'
              resize={'vertical'}
              className='form-control sailors-Input-field'
              value={certificateDescription}
              onChange={(e) => setCertificateDescription(e.target.value)}
            />
          </span> */}

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Certificate Type<span className='mustStar'>*</span></label>
            <div className="col-sm-9">
              <select className="form-select sailors-Input-field bg-white" value={certificateType} onChange={(e) => { setCertificateType(e.target.value) }}>
                 <option>Select Certificate Type</option>
                { [{ type: "medical", label: "Medical" },
                { type: "license", label: "License" },
                { type: "standard", label: "Standard" }]?.map((item, index) => {
                  return (
                    <option value={item?.type} style={{ height: "50px", cursor: "pointer" }} >{item?.label}</option>
                  )
                }) }
              </select>
            </div>
          </div>

          {/* <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
            <p style={{ maxWidth: "231px" }}>Certificate Type</p>
            
            <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "978px", marginRight: "-23px", marginLeft: "5px" }}>
              <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                {certificateType}<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "180px", overflowY: 'scroll' }}>
                {[{ type: "medical", label: "Medical" },
                { type: "license", label: "License" },
                { type: "standard", label: "Standard" }]?.map((item, index) => {
                  return (
                    <div className="dropdown-item" onClick={() => { setCertificateType(item.type) }} style={{ height: "50px", cursor: "pointer" }} >{item.label}</div>
                  )
                })}
              </div>
            </div>
          </span> */}
        </div>
        <div className='form-btns' style={{ marginBottom: "10px" }}>
          <button type="submit" onClick={submitCertificate} className="btn">{isEdit ? "Update" : "Save"}</button>
          <button className="btn btn-OnboardForm" onClick={handleCancle}>Cancel</button>
        </div>
      </div>
      <div className='instituteOnboarding-list-view' style={{ marginTop: "20px" }}>
        <div className='headerPart'>
          <h4>Certificate List</h4>
        </div>
      </div>
      {loading ?
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>

          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>

        : <div className='table-container'>

          {certificateList?.length > 0 ?
            <div className='table-Data'>
              <table className='table'>
                <thead>
                  <tr style={{ display: 'flex', justifyContent: "flex-start" }}>
                    <th style={{ fontWeight: "700", maxWidth: "150px" }}>Sr No.</th>
                    <th style={{ fontWeight: "700", textAlign: 'start' }}>Certificate Name</th>
                    <th style={{ fontWeight: "700", textAlign: 'center' }}>Certificate Type</th>
                    <th style={{ fontWeight: "700" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {certificateList.map((item, index) => {
                    return (
                      <tr className='' style={{ display: 'flex', justifyContent: "space-around" }}>
                        <th scope="row" style={{maxWidth:"150px"}}>{index + 1}</th>
                        <td style={{ textWrap: 'wrap', textAlign: 'start' }}>{item.name}</td>
                        <td style={{ textWrap: 'wrap', textAlign: 'center' }}>{item.type}</td>
                        <td>
                          <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                            <FaPen onClick={() => editCertificate(item)} title='Details' style={{ width: "40px", height: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /><RiDeleteBin6Line onClick={() => deleteCertificate(item.id)} title='Delete' style={{ width: "40px", height: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            :
            <center>No Data found</center>}
        </div>}

    </div>
  )
}

export default CreateCertificate;
