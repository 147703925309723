import React, { useState } from "react";
import "./addDepartment.css";
import { FaPen } from "react-icons/fa";
import { BallTriangle } from "react-loader-spinner";
import { RiDeleteBin6Line } from "react-icons/ri";
const CompanyDepartment = () => {
  const [departmentName, setDepartmentName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [departmentList, setDepartmentList] = useState([
    { id: 1, name: "Deck" },
    { id: 2, name: "Engine" },
    { id: 3, name: "Pantry" },
    { id: 4, name: "Office" },
  ]);
  const editDepartment = () => {};
  const deleteDepartment = () => {};
  const handleSubmit = () => {};
  const handleCancel = () => {};
  const getDepartmentById = (id) => {
    for (let i = 0; i < departmentList.length; i++) {
      if (departmentList[i].id == id) {
        return departmentList[i].name;
      }
    }
  };

  const data = [
    { id: 1, name: "hello" },
    { id: 2, name: "123" },
    { id: 3, name: "Testing 1" },
    { id: 4, name: "Chemistry(graduation)" },
    { id: 5, name: "Physics" },
    { id: 6, name: "Chemistry(12th)" },
  ];
  return (
    <div
      className="institute-container animate__animated animate__fadeIn"
      style={{ transition: "ease-in-out 250ms" }}
    >
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4>Add Department</h4>
        </div>
      </div>
      <div
        className="p-4 bg-white shadow rounded-lg table-container"
        style={{ borderRadius: "20px", width: "100%" }}
      >
        <form>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Department Name</label>
            <div className="col-sm-9">
              <input
                value={departmentName}
                onChange={(e) => setDepartmentName(e.target.value)}
                type="text"
                className="form-control sailors-Input-field"
                placeholder="Enter department name"
              />
            </div>
          </div>

          <div
            className="form-btns"
            style={{ marginBottom: "20px", marginTop: "50px" }}
          >
            <button type="submit" onClick={handleSubmit} className="btn">
              {isEdit ? "Update" : "Save"}
            </button>
            <button
              onClick={handleCancel}
              className="btn btn-OnboardForm"
              style={{ border: "1px solid #005A80" }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>

      {loading ? (
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>
          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>
      ) : (
        <div className="table-container">
          <div className="table-Data">
            <table className="table">
              <thead style={{ display: "flex", justifyContent: "flex-start" }}>
                <tr style={{ display: "flex", justifyContent: "flex-start" }}>
                  <th style={{ fontWeight: "700", maxWidth: "90px" }}>
                    Sr No.
                  </th>
                  <th style={{ fontWeight: "700", textAlign: "start" }}>
                    Department Name
                  </th>
                  <th style={{ fontWeight: "700" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {departmentList.length > 0 ? (
                  departmentList.map((item, index) => {
                    return (
                      <tr
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <th scope="row">{index + 1}</th>
                        <td style={{ textWrap: "wrap", textAlign: "" }}>
                          {getDepartmentById(item.id)}
                        </td>
                        <td>
                          <div
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                            }}
                          >
                            <FaPen
                              onClick={() => editDepartment(item)}
                              title="Edit"
                              style={{
                                width: "40px",
                                height: "30px",
                                color: "#0CB1C0",
                                border: "1px solid gray",
                                padding: "5px",
                                borderTopLeftRadius: "10px",
                                borderBottomLeftRadius: "10px",
                              }}
                            />
                            <RiDeleteBin6Line
                              onClick={() => deleteDepartment(item.id)}
                              title="Delete"
                              style={{
                                width: "40px",
                                height: "30px",
                                color: "#F84F31",
                                border: "1px solid gray",
                                padding: "5px",
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <center>No data found</center>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyDepartment;
