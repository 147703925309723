import React from 'react'
import "./loader.css";
const Loader = () => {
  return (
    <div className='loaderScreen'>
      <img className='size-10 rounded-full' src='/assets/appLoaders/web_SailorsHub_Loader.gif' alt='loading...'/>
    </div>
  )
}

export default Loader;
