import React, { useEffect, useLayoutEffect, useState } from "react";
import { FaCaretDown, FaPen } from "react-icons/fa";
import { MdOutlineFileUpload } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BallTriangle } from "react-loader-spinner";
import "./contentUpload.css";
import MainEditorfile from "../../../components/editor/MainEditorfile";
import { useDispatch, useSelector } from "react-redux";
import {
  GetLearningCourses,
  getModuleDocByModuleId,
  getSubCourseUploadDocuments,
  loadMoreData,
} from "../../../../Apis/getApis";
import Swal from "sweetalert2";
import { saveModuleContentData } from "../../../../Apis/postApis";
import { setPage } from "../../../../store/actions/commonSlice";
import { setText } from "../../../../store/actions/AdminSlices";
import { updateModuleDocument } from "../../../../Apis/updateApis";
import { deleteModuleDocument } from "../../../../Apis/deleteApis";
import {
  errorToast,
  successToast,
  uploadFile,
} from "../../../../utils/helperFunctions/HelperFunctions";
import InfiniteScroll from "react-infinite-scroll-component";
import useToken from "../../../../customHook/useToken";

const UploadContent = () => {
  const access = useToken();
  const [course, setCourse] = useState("Learning Course");
  const [courseData, setCourseData] = useState([]);
  const [subCourse, setSubCourse] = useState("Sub-Course");
  const [subCourseData, setSubCourseData] = useState("");
  const [image, setImage] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const text = useSelector((state) => state?.adminSlice?.text);
  const [sequence, setSequence] = useState();
  const [title, setTitle] = useState("");
  const [documentType, setDocumentType] = useState("");
  const dispatch = useDispatch();
  const [moduleDocsData, setModuleDocsData] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [next, setNext] = useState(null);
  const [count, setCount] = useState(0);

  function loadData() {
    if (next !== null && courseData.length < count) {
      let set = new Set();
      set.add(next);
      function getAllData(resNext) {
        loadMoreData(resNext)
          .then((res) => {
            setCourseData((prev) => [...prev, ...res?.data?.data?.results]);
            if (!set.has(res?.data?.data?.next)) {
              getAllData(res?.data?.data?.next);
              set.add(res?.data?.data?.next);
            } else {
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      getAllData(next);

      // loadMoreData(next)
      //     .then((res)=>{
      //       setCourseData(prev=>[...prev,...res?.data?.data?.results]);
      //       setNext(res?.data?.data?.next);
      //     })
      //     .catch((err)=>{
      //       console.log(err)
      //     })
    }
  }

  useEffect(() => {
    let timer;

    timer = setTimeout(() => {
      if (moduleDocsData.length > 0) {
        if (sequence && sequence < 1) {
          errorToast("Sequence should be more than 0");
          setSequence("");
          return;
        } else {
          for (let i = 0; i < moduleDocsData?.length; i++) {
            if (isEdit && selectedItem?.sequence == sequence) {
              return;
            } else {
              if (moduleDocsData[i]?.sequence === parseInt(sequence)) {
                errorToast("Sequence Number already Exist");
                setSequence("");
                return;
              }
            }
          }
        }
        return true;
      }
    }, 1200);

    return () => {
      clearTimeout(timer);
    };
  }, [sequence]);

  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setDocumentType(selectedFile?.type);
    uploadFile(selectedFile)
      .then((res) => {
        setImage(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancle = () => {
    if (!isEdit) {
      setSelectedItem();
      dispatch(setText());
      setTitle("");
      setSequence("");
      setCourse();
      setSubCourse();
      setImage();
    } else {
      setSelectedItem();
      dispatch(setText());
      setTitle("");
      setSequence("");
      setImage();
      setIsEdit(false);
    }
  };

  const handleSubmit = () => {
    if (!isEdit) {
      saveModuleContentData({
        document_name: title,
        document: image,
        sequence: sequence,
        document_description: text,
        course: course,
        course_module: subCourse,
        document_type: documentType,
      },access)
        .then((res) => {
          console.log(res);
          dispatch(setText(""));
          handleCancle();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      updateModuleDocument(selectedItem?.id, {
        document_name: title,
        document: image,
        sequence: sequence,
        document_description: text,
        course: course,
        course_module: subCourse,
        document_type: documentType,
      }, access)
        .then((res) => {
          console.log(res);
          getSubCourseData(subCourse);
          handleCancle();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getCourseById = (id) => {
    for (let i = 0; i < courseData.length; i++) {
      if (courseData[i]?.id === id) {
        return courseData[i]?.course_name;
      }
    }

    return "Learning Course";
  };

  const getModuleNameById = (id) => {
    for (let i = 0; i < subCourseData.length; i++) {
      if (subCourseData[i]?.id === id) {
        return subCourseData[i]?.module_name;
      }
    }
    return "Sub-Course";
  };

  function getSubCourseData(id) {
    getModuleDocByModuleId(id)
      .then(async (res) => {
        console.log(res?.data?.data?.results, "pavan");
        setModuleDocsData(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getAllLearningCourses() {
    GetLearningCourses("accepted")
      .then((res) => {
        setCourseData(res?.data?.data?.results);
        setNext(res?.data?.data?.next);
        setCount(res?.data?.data?.count);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const editData = (item) => {
    setSelectedItem(item);
    dispatch(setText(item?.document_description));
    setTitle(item?.document_name);
    setSequence(item?.sequence);
    setCourse(item?.course);
    setSubCourse(item?.course_module);
    setImage(item?.document);
    setIsEdit(true);
  };
  const deleteModule = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You can't revert the changes",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteModuleDocument(id,access)
          .then((res) => {
            console.log(res);
            getSubCourseData(subCourse);
          })
          .catch((res) => {
            console.log(res);
          });
        successToast("Document Deleted Successfully");
      }
    });
  };

  useLayoutEffect(() => {
    getAllLearningCourses();
    dispatch(setPage("content"));
    dispatch(setText(""));
    return () => {
      dispatch(setText(""));
    };
  }, []);

  // useEffect(() => {
  //   getSubCourseUploadDocuments(subCourse)
  //     .then((res) => {
  //       console.log(res, "test")
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  // }, [subCourse])

  useEffect(() => {
    for (let i = 0; i < courseData.length; i++) {
      if (courseData[i].id == course) {
        setSubCourseData(courseData[i].modules_details);
      }
    }
  }, [course]);

  return (
    <div className="institute-container animate__animated animate__fadeIn">
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4 className="d-flex">
            <MdOutlineFileUpload style={{ width: "30px" }} />
            Upload Content
          </h4>
        </div>
      </div>
      <div className="table-container">
        <div className="allDropDown allField-learning-courses position-relative">
          <span
            className="mustStar"
            style={{
              textAlign: "end",
              fontSize: "15px",
              position: "absolute",
              top: "-20px",
              left: "-16px",
            }}
          >
            Fields marked (*) are mandatory.
          </span>

          {/* <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
            <p style={{ maxWidth: "231px" }}>Learning Course Name</p>
            
            <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
              <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                {getCourseById(course)}<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>

                {courseData.length > 0 ? courseData?.map((item, index) => {
                  return (
                    <div className="dropdown-item" onClick={() => { setCourse(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{getCourseById(item?.id)}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}


              </div>
            </div>
          </span> */}

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              Learning Course Name<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <select
                className="form-select sailors-Input-field"
                onFocus={loadData}
                value={course}
                onChange={(e) => setCourse(e.target.value)}
              >
                {/* <InfiniteScroll
                  dataLength={courseData.length}
                  next={loadData}
                  hasMore={next!==null ? true : false}
                  loader={<h6>Loading...</h6>}
                  scrollableTarget='scrollableDiv'
                  > */}
                <option>Select Learning Course</option>
                {courseData.length > 0 ? (
                  courseData?.map((item, index) => {
                    return (
                      <option
                        value={item?.id}
                        style={{ height: "50px", cursor: "pointer" }}
                      >
                        {item?.course_name}
                      </option>
                    );
                  })
                ) : (
                  <option style={{ marginTop: "10%" }}>
                    !Opps check the Internet
                  </option>
                )}
                {/* </InfiniteScroll> */}
              </select>
            </div>
          </div>

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              Learning Sub Course<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <select
                className="form-select sailors-Input-field"
                value={subCourse}
                onChange={(e) => {
                  setSubCourse(e.target.value);
                  getSubCourseData(e.target.value);
                }}
              >
                <option>Select Sub Course</option>
                {subCourseData.length > 0 ? (
                  subCourseData?.map((item, index) => {
                    return (
                      <option value={item?.id}>{item?.module_name}</option>
                    );
                  })
                ) : (
                  <option>
                    {course === "Learning Course"
                      ? "Select the learning Course"
                      : "No Module Available"}
                  </option>
                )}
              </select>
            </div>
          </div>

          {/* <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
            <p style={{ maxWidth: "231px" }}>Learning (Sub-Course) Name</p>
            
            <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
              <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                {getModuleNameById(subCourse)}<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>

                {subCourseData.length > 0 ? subCourseData?.map((item, index) => {
                  return (
                    <div className="dropdown-item" onClick={() => {
                      setSubCourse(item.id)
                      getSubCourseData(item?.id)
                    }} style={{ height: "50px", cursor: "pointer" }} >{item?.module_name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>{course === "Learning Course" ? "Select the learning Course" : "No Module Available"}</center>}
              </div>
            </div>
          </span> */}

          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Document Title</p>
            <input value={title} onChange={(e) => { setTitle(e.target.value) }} type='text' className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
          </span> */}

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              Document Title<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                maxLength={120}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className=" sailors-Input-field"
              />
            </div>
          </div>

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              Content Sequence<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <input
                value={sequence}
                onChange={(e) => {
                  setSequence(e.target.value);
                }}
                type="number"
                className=" sailors-Input-field"
              />
            </div>
          </div>

          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Content sequence</p>
            <input value={sequence} onChange={(e) => { setSequence(e.target.value) }} type='number' className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
          </span> */}

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Upload</label>
            <div className="col-sm-9">
              <input
                required
                type="file"
                onChange={handleFileChange}
                className=" p-2 sailors-Input-field"
              />
              <span style={{ fontSize: "12px", color: "red" }}>
                Upload Images and Docs under 5MB
              </span>
            </div>
          </div>

          <span
            className="d-flex add-course-container justify-content-center"
            style={{ maxWidth: "100%", padding: "10px", height: "auto" }}
          >
            <MainEditorfile />
          </span>
        </div>
        <div className="form-btns" style={{ marginBottom: "10px" }}>
          <button type="submit" onClick={handleSubmit} className="btn">
            {isEdit ? "Update" : "Save"}
          </button>
          <button onClick={handleCancle} className="btn btn-OnboardForm" style={{border:"1px solid #005A80"}}>
            Cancel
          </button>
        </div>
      </div>
      <div
        className="instituteOnboarding-list-view"
        style={{ marginTop: "20px" }}
      >
        <div className="headerPart">
          <h4>Module Docs List</h4>
        </div>
      </div>
      {loading ? (
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>
          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>
      ) : (
        <div className="table-container">
          {course === "Choose Course" ? (
            <center>
              <h3>Course Not Selected</h3>
            </center>
          ) : (
            <React.Fragment>
              {moduleDocsData?.length > 0 ? (
                <div className="table-Data">
                  <table className="table">
                    <thead>
                      <tr
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <th style={{ fontWeight: "700", maxWidth: "50px" }}>
                          Sr No.
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "center" }}>
                          Doc title
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "center" }}>
                          Sequence
                        </th>
                        <th style={{ fontWeight: "700" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {moduleDocsData
                        .sort((a, b) => a.sequence - b.sequence)
                        .map((item, index) => {
                          return (
                            <tr
                              className=""
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <th scope="row">{index + 1}</th>
                              <td style={{ textAlign: "center" }}>
                                {item?.document_name}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item?.sequence}
                              </td>
                              <td>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FaPen
                                    onClick={() => editData(item)}
                                    data-toggle="modal"
                                    data-target="#instituteDetailsModal"
                                    title="Details"
                                    style={{
                                      width: "30px",
                                      color: "#0CB1C0",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopLeftRadius: "10px",
                                      borderBottomLeftRadius: "10px",
                                    }}
                                  />{" "}
                                  <RiDeleteBin6Line
                                    onClick={() => deleteModule(item.id)}
                                    title="Delete"
                                    style={{
                                      width: "30px",
                                      color: "#F84F31",
                                      border: "1px solid gray",
                                      padding: "5px",
                                      borderTopRightRadius: "10px",
                                      borderBottomRightRadius: "10px",
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <center>No Module Selected Or No Docs in Module</center>
              )}
              {/* {
                openCourse?.modules_details.length > 0 ? openCourse?.modules_details.length.map((mod) => {
                  return (
                    <>

                    </>
                  )
                })
                  :
                  <center><h3>Selected Subject has No modules</h3></center>
              } */}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadContent;
