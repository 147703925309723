import React from 'react'
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const HorizontalBarChart = ({selectedDesignations,empByDes}) => {

    console.log("selectedDesignations :",selectedDesignations,"empByDes :",empByDes);
    let arr = Array.from({length:selectedDesignations.length},()=>0);
    for(let i=0;i<selectedDesignations.length;i++){
        for(let j=0;j<empByDes.length;j++){
            if(selectedDesignations[i]===empByDes[j]){
                arr[i] = empByDes[j].count;
            }
        }
    }


    const data = {
        labels: selectedDesignations,
        datasets: [
            {
                label: 'Employees By Designation',
                data: arr,
                backgroundColor: [ '#DB7876', '#9CBC72', '#9DE3DA', '#A09CE1','#E19FBA','#E59E18'],
                barPercentage:0.7,
                stack: 'Stack 1',
            },
        ],
    };

    const options = {
        responsive: true,
        aspectRatio: 1.6,
        plugins: {
            datalabels: {
                display: true,
                color: "black",
                formatter: Math.round,
                anchor: "end",
                offset: -20,
                align: "start"
              },
            legend: {
                display: true
            },
            tooltip: {
                mode: 'index',
                intersect: true,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };
  return (
    <div className="p-4" style={{height:"auto"}} >
        
      <Bar data={data} options={options} />
    </div>
  )
}

export default HorizontalBarChart;
