import React, { useEffect, useState } from 'react'
import { getCandidateList, loadMoreData } from '../../../../../Apis/getApis';
import { BallTriangle } from 'react-loader-spinner';
import ResponsivePagination from 'react-responsive-pagination';
import { FaEye } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Modal } from 'react-responsive-modal';

import { RiDeleteBin6Line } from 'react-icons/ri';
import { MdOutlineLogout } from 'react-icons/md';
import { deleteInstitute } from '../../../../../Apis/deleteApis';
import { updateBlockStatus } from '../../../../../Apis/updateApis';
import { AiOutlineClose } from 'react-icons/ai';
import { base_url } from '../../../../../utils/endpoints/endpoints';
import { errorToast, successToast } from '../../../../../utils/helperFunctions/HelperFunctions';
import useToken from '../../../../../customHook/useToken';

const VerifiedCandidates = () => {
    const access = useToken();
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [count, setCount] = useState(1);

    const pageChange = (e) => {
        setTableData([])
        setCurrentPage(e);
        let url = `${base_url}/api/v1/web/users/?user_type=seafarer&status=accepted&page=${e}`
        loadMoreData(url)
        .then(res=>{
            setTableData([...res?.data?.data?.results])
        })
        .catch((err)=>{
            console.log(err)
        })
    }
    
    const showModal = (item) => {
        setSelectedUser(item);
        setVisible(true);
    }

    const updateUserBlockStatus = (item) => {
        if (selectedItem && selectedItem?.id === item?.id) {
            setSelectedItem();
        } else {
            setSelectedItem(item);
            Swal.fire({
                title: "Are you sure?",
                text: item?.is_blocked_user ? "Do you want to unblock User" : "Do you want to block User",
                icon: "error",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: item?.is_blocked_user ? "Yes, Unblock user!" : "Yes, Block user!"
            }).then((result) => {
                if (result.isConfirmed) {
                    updateBlockStatus(item.id, access)
                        .then(res => {
                            console.log(res);
                            getAllInstitutes();
                            successToast(item?.is_blocked_user ? "User is Unblocked." : "User is blocked.");
                        })
                        .catch((err) => {
                            console.log(err);
                            errorToast("Something went wrong");
                        })
                } else {
                    setSelectedItem();
                }

            });

        }
    }


    function getAllInstitutes() {
        getCandidateList('accepted')
            .then((res) => {
                setLoading(false);
                setCount(res?.data?.data?.count)
                setTableData(res?.data?.data?.results);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err)
            })
    }

    useEffect(() => {
        setLoading(true)
        getAllInstitutes();
    }, [])

    useEffect(() => {
        let timer;
        if (search !== '') {
            timer = setTimeout(()=>{
                let url = `${base_url}/api/v1/web/users/?user_type=seafarer&search=${search}&status=accepted`

                function getAllData(next){
                    if(next==null){
                        return;
                    }

                    loadMoreData(next)
                .then((res)=>{
                    setSearchData(prev=>[...prev,...res?.data?.data?.results])
                    getAllData(res?.data?.data?.next)
                })
                .catch((err)=>{
                    console.log(err)
                })

                }


                loadMoreData(url)
                .then((res)=>{
                    setSearchData([...res?.data?.data?.results])
                    getAllData(res?.data?.data?.next)
                })
                .catch((err)=>{
                    console.log(err)
                })

            },1000)
            } else {
                setSearchData([])
            }

            return (()=>{
                clearTimeout(timer);
            })
    }, [search])

    const handleDelete = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You can't revert the changes",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteInstitute(id,access)
            .then((res) => {
                console.log(res);
                getAllInstitutes();
                successToast("Candidate deleted");
            })
            .catch(err => {
                console.log(err);
                errorToast("Something went wrong");
            })
            } else {
                setSelectedItem();
            }
        });

        
    }


    return (
        <div style={{ display: 'flex', justifyContent: "center", flexDirection: 'column' }}>
            <div className="input-group position-sticky" style={{ marginBottom: "50px", paddingLeft: "10px" }}>
                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control position-static" placeholder="Search ......🔍" aria-label="Recipient's username" />
            </div>
            {loading ?
                <div style={{ maxWidth: "100px", maxHeight: "100px", margin: 'auto' }}>

                    <BallTriangle
                        height={20}
                        width={20}
                        radius={5}
                        color="red"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                    <span>Loading...</span>
                </div>

                : <div className='table-container'>

                    {tableData?.length > 0 ?
                        <div className='table-Data'>
                            <table className='table'>
                                <thead>
                                <tr style={{ display: 'flex', justifyContent: "flex-start" }} 
                                className="border-b-[1px] border-b-slate-600">
                                        <th style={{ fontWeight: "700", maxWidth: "80px" }}>Sr No.</th>
                                        <th style={{ fontWeight: "700", textAlign: 'start' }}>Candidate Name</th>
                                        <th style={{ fontWeight: "700", textAlign: 'start' }}>CDC Number</th>
                                        <th style={{ fontWeight: "700", textAlign: 'start' }}>Country</th>
                                        <th style={{ fontWeight: "700" }}>Status</th>
                                        <th style={{ fontWeight: "700" }}>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {search === '' && tableData?.map((item, index) => {
                                        return (
                                            <tr key={item.id} className='' style={{ display: 'flex', justifyContent: "center" }}>
                                                <th scope="row">{index + 1}</th>
                                                <td style={{ textWrap: 'wrap', textAlign: 'start',minWidth:"calc(100%/6)px" }}>{item?.first_name}</td>
                                                <td style={{ textWrap: 'wrap', textAlign: 'start' }}>{item?.cdc_number || "NA"}</td>
                                                <td style={{ textWrap: 'wrap', textAlign: 'start' }}>{item?.country}</td>
                                                <td style={{ display: 'flex', justifyContent: 'start' }} onClick={() => updateUserBlockStatus(item)} >
                                                    {
                                                        !item?.is_blocked_user ? <img loading="lazy" src='/assets/commonAssets/toggleTrue.svg' style={{ cursor: 'pointer' }} alt='logo' /> : <img src='/assets/commonAssets/toggleFalse.svg' style={{ cursor: 'pointer' }} alt='logo' />
                                                    }

                                                </td>
                                                <td>
                                                    <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: 'start' }}>
                                                        <FaEye onClick={() => { showModal(item); setSelectedItem(item) }} title='Details' style={{ width: "40px", height: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /><RiDeleteBin6Line onClick={() => handleDelete(item?.id)} title='Delete' style={{ width: "40px", height: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {search !== '' && searchData.length > 0 ? searchData?.map((item, index) => {
                                        return (
                                            <tr key={item.id} className='' style={{ display: 'flex', justifyContent: "center" }}>
                                                <th scope="row">{index + 1}</th>
                                                <td style={{ textWrap: 'wrap', textAlign: 'start' }}>{item?.first_name}</td>
                                                <td style={{ textWrap: 'wrap', textAlign: 'start' }}>{item?.cdc_number || "NA"}</td>
                                                <td style={{ textWrap: 'wrap', textAlign: 'start' }}>{item?.country}</td>
                                                <td style={{ display: 'flex', justifyContent: 'center' }} onClick={() => updateUserBlockStatus(item)} >
                                                    {
                                                        !item?.is_blocked_user ? <img loading="lazy" src='/assets/commonAssets/toggleTrue.svg' style={{ cursor: 'pointer' }} alt='logo' /> : <img src='/assets/commonAssets/toggleFalse.svg' style={{ cursor: 'pointer' }} alt='logo' />
                                                    }

                                                </td>
                                                <td>
                                                    <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: 'center' }}>
                                                        <FaEye onClick={() => { showModal(item); setSelectedItem(item) }} title='Details' style={{ width: "40px", height: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /><RiDeleteBin6Line onClick={() => handleDelete(item?.id)} title='Delete' style={{ width: "40px", height: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }) : <>{search !== "" && <center style={{ marginTop: "20px" }}>No Match Found</center>}</>}
                                </tbody>
                            </table>
                        </div>
                        :
                        <center>No Data found</center>}
                </div>}

            <Modal
                open={visible}
                onClose={() => setVisible(false)}
                center={true}
            >
                <div className='candidateDetails-body-Container'>
                    <div className='details-row'>
                        <h6 className='card-lable-heading'>Name</h6>
                        <p>-</p>
                        <h6 className="card-lable-value">{selectedUser?.first_name}{selectedUser?.last_name && " " + selectedUser?.last_name}</h6>
                    </div>
                    <div className='details-row'>
                        <h6 className='card-lable-heading'>CDC Number</h6>
                        <p>-</p>
                        <h6 className="card-lable-value">{selectedUser?.cdc_number || "NA"}</h6>
                    </div>
                    <div className='details-row'>
                        <h6 className='card-lable-heading'>CDC Expiry</h6>
                        <p>-</p>
                        <h6 className="card-lable-value">{selectedUser?.cdc_details[0]?.valid_to || "NA"}</h6>
                    </div>
                    <div className='details-row'>
                        <h6 className='card-lable-heading'>Country</h6>
                        <p>-</p>
                        <h6 className="card-lable-value">{selectedUser?.country || "NA"}</h6>
                    </div>
                    <div className='details-row'>
                        <h6 className='card-lable-heading'>City</h6>
                        <p>-</p>
                        <h6 className="card-lable-value">{selectedUser?.city || "NA"}</h6>
                    </div>
                    <div className='details-row'>
                        <h6 className='card-lable-heading'>DOB</h6>
                        <p>-</p>
                        <h6 className="card-lable-value">{selectedUser?.cdc_details ? selectedUser?.cdc_details[0]?.date_of_birth : "NA"}</h6>
                    </div>
                    <div className='details-row'>
                        <h6 className='card-lable-heading'>Email</h6>
                        <p>-</p>
                        <h6 className="card-lable-value">{selectedUser?.email}</h6>
                    </div>
                    <div className='details-row'>
                        <h6 className='card-lable-heading'>Mobile</h6>
                        <p>-</p>
                        <h6 className="card-lable-value">{selectedUser?.mobile_number1 || "NA"}</h6>
                    </div>
                    <div className='details-row'>
                        <h6 className='card-lable-heading'>Gender</h6>
                        <p>-</p>
                        <h6 className="card-lable-value">{selectedUser?.gender || "NA"}</h6>
                    </div>

                    <div className='details-row-button'>
                        <button className="logOutButton" onClick={() => { handleDelete(selectedUser?.id) }} style={{ backgroundColor: '#c06c84', marginRight: "10px" }} data-dismiss="modal"><RiDeleteBin6Line style={{ width: "30px", height: "30px", marginLeft: "20px" }} /><span>Delete</span></button>
                        <button onClick={() => setVisible(false)} className="logOutButton" data-dismiss="modal"><AiOutlineClose style={{ width: "30px", height: "30px", marginLeft: "20px" }} /><span>Close</span></button>
                    </div>
                </div>
            </Modal>

            {tableData.length > 0 && <div style={{ maxWidth: "50px", marginTop: "30px", margin: 'auto' }}>
                <ResponsivePagination
                    current={currentPage}
                    total={Math.ceil(count / 20)}
                    // total={10}
                    onPageChange={(e) => pageChange(e)}
                />
            </div>}
        </div>
    )
}

export default VerifiedCandidates;






