import React, { useState } from "react";
import OtpInput from "react-otp-input";
import "./forgetPass.css";
import { ToastContainer, toast } from "react-toastify";
import { verifyEmail, verifyOtp } from "../../Apis/postApis";
import { useNavigate } from "react-router-dom";
import { FaRegEnvelope } from "react-icons/fa";

const ForgetPasswordEmail = () => {
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [otpRequest, setOtpRequest] = useState(false);
  const navigate = useNavigate();

  const errorOtpToast = () => toast.error("Enter six digit OTP");
  const successOtpSent = () => toast.success("OTP sent to Email Address");
  const errorOtpVerify = () => toast.error("Invalid OTP");
  const errorToast = () => toast.error("Enter Valid Email");

  const verifyNewOtp = async (e) => {
    e.preventDefault();
    if (otp.length === 6) {
      await verifyOtp({ email: email, otp: otp })
        .then((res) => {
          localStorage.setItem("token", JSON.stringify(res?.data?.data?.token));
          localStorage.setItem("userData", JSON.stringify(res?.data?.data?.user));
          navigate("/resetPassword");
        })
        .catch((err) => {
          errorOtpVerify();
          console.error(err);
        });
    } else {
      errorOtpToast();
    }
  };

  const getOtpRequest = (e) => {
    if (email !== "") {
      verifyEmail({ email: email })
        .then((res) => {
          successOtpSent();
          setOtpRequest(true);
        })
        .catch((err) => {
          errorToast();
          setEmail("");
          console.error(err);
        });
    } else {
      errorToast();
    }
  };

  return (
    <div className="MainContainer container-fluid" style={{ height: "100vh" }}>
      <form className="login-form-container gap-y-6 pb-4" style={{ minHeight: "368px", maxHeight: "368px", height: "368px" }}>
        <div className="imageContainer mt-5 mb-3" style={{ marginTop: "0px" }}>
          <img src="/assets/authAssets/Sailors-Hub-logo.png" alt="img" style={{ filter: "drop-shadow(10px 10px 5px #999)" }} />
        </div>
        <div className="formFields-Container pb-2">
          <div className="field-container login-email-field" style={{ marginTop: "0px" }}>
            <label className="text-base">Enter Valid Email</label>
            <div className="inputWithIcon">
              <FaRegEnvelope style={{ margin: "3px -17px 0px 14px", width: "30px", height: "30px" }} />
              <input type="email" name="email" id="inputField" className="pl-2 text-sm" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            {otpRequest && (
              <div className="otpField">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<b>&nbsp;&nbsp;&nbsp;&nbsp;</b>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    width: "33px",
                    height: "33px",
                    boxShadow: "inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "5px",
                  }}
                  containerStyle={{ display: "flex", alignItems: "center" }}
                />
              </div>
            )}
            {!otpRequest && (
              <button type="button" className="loginbtn" onClick={(e) => getOtpRequest(e)}>
                <img src="./assets/authAssets/loginbtn.svg" className="btnsvg" alt="svg" /> <h6>Get OTP</h6>
              </button>
            )}
            {otpRequest && (
              <button type="button" className="loginbtn" onClick={(e) => verifyNewOtp(e)}>
                <img src="./assets/authAssets/loginbtn.svg" className="btnsvg" alt="svg" /> <h6>Verify</h6>
              </button>
            )}
          </div>
        </div>
      </form>
      <ToastContainer position="top-left" style={{ maxHeight: "50px" }} />
    </div>
  );
};

export default ForgetPasswordEmail;
