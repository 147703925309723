import React, { useEffect, useState } from 'react'
import {FaCaretDown, FaPen } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BallTriangle } from 'react-loader-spinner';
import { getCountries, getShipTypeList } from '../../../../Apis/getApis';
import { deleteUserShip } from '../../../../Apis/deleteApis';
import { saveUserShip } from '../../../../Apis/postApis';
import { updateShip } from '../../../../Apis/updateApis';
import Swal from 'sweetalert2';
import { successToast } from '../../../../utils/helperFunctions/HelperFunctions';
import useToken from '../../../../customHook/useToken';

const Shiptype = () => {
  const access = useToken();
  const [loading,setLoading] = useState(false);
  const [isEdit,setIsEdit] = useState(false);
  const [ship,setShip] = useState('');
  const [shipList,setShipList] = useState([]);
  const [selectedShip,setSelectedShip] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedCountries,setSelectedCountries] = useState([]);
  const [search,setSearch] = useState('');
  const [searchData,setSearchData] = useState([]);
  const [countries,setCountries] = useState([]);
  const handleCancel = ()=>{
    setShip('');
    setIsEdit(false);
    setSelectedCountries([])
  }

  const deleteShip = (id)=>{

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete Ship Type ? " ,
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes,Delete Ship Type!" 
  }).then((result) => {
      if (result.isConfirmed) {
        deleteUserShip(id,access)
        .then((res)=>{
          console.log(res)
          getShipData();
          successToast("Ship Type is deleted.");
        })
        .catch((err)=>{
          console.log(err)
        })

          
      }
    })

    
  }

  useEffect(() => {
    setTimeout(() => {

      if (search !== '') {
        let arr = [];
        for (let i = 0; i < countries.length; i++) {
          if (countries[i].name.toLowerCase().includes(search.toLowerCase())) {
            const isThere = (data) => {
              for (let j = 0; j < arr.length; j++) {
                if (arr[j].id === data.id) {
                  return true;
                }
              }
              return false;
            }
            if (!isThere(countries[i])) {
              arr.push(countries[i]);
            }
          }
        }
        setSearchData([...searchData, ...new Set(arr)])
      } else {
        setSearchData([])
      }
    }, 1000)
  }, [search])

  const addCountry = (countryData) => {
    const isThere = selectedCountries.some((item) => isEdit ? item.id == countryData.id : item == countryData.id);
    if (!isThere) {
      if (isEdit) {
        setSelectedCountries([...selectedCountries, { id: countryData.id, name: countryData.name }]);
      } else {
        setSelectedCountries([...selectedCountries, countryData.id]);
      }
    }
  }

  const editShipType = (item)=>{
    setIsEdit(true);
    setSelectedShip(item);
    setSelectedCountries(item?.countries);
    setShip(item?.name)
  }


  const findById = (id) => {

    for (let i = 0; i < countries.length; i++) {
      if (countries[i].id === id) {
        return countries[i]
      }
    }
  }

  const removeCountry = (data) => {
    const countries = [...selectedCountries]
    const newCountry = countries.filter((item) => item !== data);
    setSelectedCountries(newCountry);
  }

  const saveShip = ()=>{
    if(!isEdit){
      saveUserShip({name:ship,countries:selectedCountries},access)
      .then((res)=>{
        console.log(res,"pavan")
      getShipData();
      handleCancel();

      })
      .catch((err)=>{
        console.log(err,"error")
      })
    }else{
      updateShip(selectedShip?.id,{name:ship,countries:[]}, access)
      .then((res)=>{
        console.log(res);
      getShipData();
        handleCancel();
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }

  function getShipData(){

    getShipTypeList()
    .then((res)=>{
      setShipList(res?.data?.data?.results)
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  
  useEffect(()=>{
    getShipData();

    getCountries()
      .then((res) => {
        setLoading(false);
        console.log(res?.data?.data, "countries");
        setCountries(res?.data?.data)
      })
      .catch((err) => {
        setLoading(false);
        console.log(err)
      })
  },[])
  return (
    <div className='institute-container animate__animated animate__fadeIn'>
      <div className='instituteOnboarding-list-view'>
        <div className='headerPart'>
          <h4>Ship Type</h4>
        </div>
      </div>
      <div className='table-container position-relative'>
      <span className='mustStar' style={{textAlign:'end', fontSize:"15px", position:'absolute',top:"-20px",left:"-16px"}}>Fields marked (*) are mandatory.</span>
        <div className='allDropDown allField-learning-courses'>

        <span className='d-flex add-course-container add-course-country-container justify-content-center' style={{ maxWidth: "100%", padding: "20px" }}>
            <div className="dropdown dropDown-locality" >
              <button
                              type="button"
                              className="w-auto h-10 border-[1px] border-slate-600 rounded-md flex flex-row items-center justify-center px-2 gap-x-2"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              style={{ backgroundColor: "white", maxWidth: "200px", border:"1px solid rgb(207, 196, 196)" }}
                            >
                              Country
                              <FaCaretDown className="size-4 w-4" />
                            </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>
                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                  <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                    <input  type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control position-static" placeholder="Search ......" aria-label="Recipient's username" />

                  </div>

                </div>
                {search == '' && <React.Fragment>{countries.length > 0 ? countries?.map((item, index) => {
                  return (
                    <div className="dropdown-item" style={{ height: "50px", cursor: "pointer" }} onClick={() => addCountry(item)}>{item?.name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}</React.Fragment>}

                {search !== '' && <>{searchData.length > 0 ? searchData.reverse()?.map((item, index) => {
                  return (
                    <div className="dropdown-item" style={{ height: "50px", cursor: "pointer" }} onClick={() => addCountry(item)}>{item.name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>No Match</center>}</>}

              </div>
            </div>
            <div className={selectedCountries?.length > 0 ? 'selectedCountries-container' : 'selectedCountries-container noDataContainer'}>

              {selectedCountries.length > 0 ? <>{selectedCountries.map((item) => {
                return (
                  <div className='selectedCountry' >
                    <p style={{ marginTop: "37px", }}>{findById(item).name}</p>
                    <button onClick={() => removeCountry(item)} type="button" className="close" style={{ width: "15px", height: "15px" }}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )
              })}</> : <center style={{ marginTop: "15px" }}>All Countries</center>}
            </div>
          </span>
          
          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Type of Ship</p>
            
            <input type='text' value={ship} onChange={(e)=>setShip(e.target.value)} className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
          </span> */}

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Type of Ship<span className='mustStar'>*</span></label>
            <div className="col-sm-9">
              <input value={ship} maxLength={50} onChange={(e) => setShip(e.target.value)} type="text" className="form-control sailors-Input-field" />
            </div>
          </div>

          
         
          
        </div>
        <div className='form-btns' style={{ marginBottom: "10px" }}>
          <button onClick={saveShip} type="submit" className="btn">{isEdit ? "Update" : "Save"}</button>
          <button onClick={handleCancel} className="btn btn-OnboardForm">Cancel</button>
        </div>
      </div>
      <div className='instituteOnboarding-list-view' style={{ marginTop: "20px" }}>
        <div className='headerPart'>
          <h4>All Ship Types List</h4>
        </div>
      </div>
      {loading ?
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>

          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>

        : <div className='table-container'>

          {shipList?.length > 0 ?
            <div className='table-Data'>
              <table className='table'>
                <thead>
                  <tr style={{ display: 'flex', justifyContent: "flex-start" }}>
                  <tr style={{ display: 'flex', justifyContent: "flex-start" }}>
                    <th style={{ fontWeight: "700", maxWidth: "50px" }}>Sr No.</th>
                    <th style={{ fontWeight: "700", textAlign: 'center' }}>Ship Type</th>
                    <th style={{ fontWeight: "700" }}>Actions</th>
                  </tr>
                  </tr>
                </thead>
                <tbody>
                  {shipList.map((item, index) => {
                    return (
                      <tr className='' style={{ display: 'flex', justifyContent: "space-around" }}>
                        <th scope="row">{index + 1}</th>
                        <td style={{ textWrap: 'wrap', textAlign: 'center' }}>{item.name}</td>
                        <td>
                          <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                            <FaPen onClick={() => editShipType(item)} title='Details' style={{ width: "40px", height: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /><RiDeleteBin6Line onClick={() => deleteShip(item.id)} title='Delete' style={{ width: "40px", height: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>


              </table>
            </div>
            :
            <center>No Data found</center>}
        </div>}

    </div>
  )
}

export default Shiptype;
