import React from "react";
import SailorChart from "./charts/firstcardChart/SailorChart";
import InstituteChartAdmin from "./charts/firstcardChart/instituteChartAdmin";
import CompanyChartAdmin from "./charts/firstcardChart/CompanyChartAdmin";

const DashboardCard = React.memo(({ dashboardsAllData }) => {

  return (
    <div className="adminDashboard-cards h-100 stransition-all duration-300">
      <div className="">
        <SailorChart dashboardsAllData={dashboardsAllData} />
      </div>
      <div>
        <InstituteChartAdmin dashboardsAllData={dashboardsAllData} />
      </div>
      <div>
        <CompanyChartAdmin dashboardsAllData={dashboardsAllData} />
      </div>
    </div>
  );
});

export default DashboardCard;
