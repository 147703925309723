import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';

export const useAuth = ()=>{
    const user = JSON.parse(localStorage.getItem('userData'));
    const token = JSON.parse(localStorage.getItem('token'));

    return {token:token,user:user};
}

export function isUrlValid(userInput) {
    var regexQuery = "/\b(https?|ftp|file):[\-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[\-A-Za-z0-9+&@#\/%=~_|]/";
    var url = new RegExp(regexQuery);
    if (url.test(userInput)) {
        return true;
    }
    return false;
}

export function isValidEmail(email){
    let regexQuery = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
    let url = new RegExp(regexQuery);
    if (url.test(email)) {
        return true;
    }
    return false;
}

const uniqueStringFunction = (selectedFile) => {
    let uniqueString = uuidv4();
    let fileName = selectedFile?.name.replaceAll(/\s/g, '');
    return uniqueString.toString() + fileName.toString();
  }
export const uploadFile = async (selectedFile) => {
    
    const S3_BUCKET = "sailorshub";
    const REGION = "ap-south-1";

    AWS.config.update({
      accessKeyId: "AKIAZP4A3A4LFPDRB7VX",
      secretAccessKey: "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",
    });

    const s3 = new S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    let Name = uniqueStringFunction(selectedFile).toString();

    const params = {
      Bucket: S3_BUCKET,
      Key: Name,
      Body: selectedFile,
      ContentType: "image/*"
    };
    try {
       await s3.putObject(params).promise()
      toast.success("File uploaded successfully.");
      
      return `https://sailorshub.s3.ap-south-1.amazonaws.com/${Name}`
    } catch (error) {
      console.error(error);
      Swal.fire("Error uploading file: " + error.message)
    }
  };


  export function validatePassword(password) {
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
}



  export const errorToast = (msg) => toast.error(msg);
  export const successToast = (msg) => toast.success(msg);
  export const deleteToast = (msg)=>toast(`🗑️ ${msg}`)
    
