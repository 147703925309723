import React, { useEffect, useState } from "react";
import { BallTriangle } from "react-loader-spinner";
import { FaCaretDown, FaPen } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  GetLearningCourses,
  getLearningCourseSearch,
  getSubjects,
  loadMoreData,
} from "../../../../Apis/getApis";
import { deleteCourseModule } from "../../../../Apis/deleteApis";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { saveCourseModule } from "../../../../Apis/postApis";
import { updateCourseModule } from "../../../../Apis/updateApis";
import { allowedTypes } from "../../../../utils/variables/folderStructureFiles";
import {
  errorToast,
  successToast,
  uploadFile,
} from "../../../../utils/helperFunctions/HelperFunctions";
import InfiniteScroll from "react-infinite-scroll-component";
import useToken from "../../../../customHook/useToken";

const SubLearningCourses = () => {
  const access = useToken()
  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState("Choose Course");
  const [moduleName, setModuleName] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [moduleDescription, setModuleDescription] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [openCourse, setOpencourse] = useState();
  const [subjectList, setSubjectList] = useState([]);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [sequence, setSequence] = useState();
  const navigate = useNavigate();
  const [subject, setSubject] = useState("Select Subject");
  const [image, setImage] = useState("");
  const [next, setNext] = useState();
  const editData = (item) => {
    setIsEdit(true);
    setSequence(item.sequence);
    setCourse(item.course);
    setModuleName(item.module_name);
    setModuleDescription(item?.module_description);
    setSubject(item.subject_id);
    setSelectedItem(item);
  };

  function loadData() {
    if (search == "") {
      loadMoreData(next)
        .then((res) => {
          setCourseData([...courseData, ...res?.data?.data?.results]);
          setNext(res?.data?.data?.next);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return;
  }

  const getSubjectById = (val) => {
    if (isNaN(val)) {
      return "Choose Subject";
    } else {
      for (let i = 0; i < subjectList.length; i++) {
        if (parseInt(val) === subjectList[i].id) {
          return subjectList[i].name;
        }
      }
    }
  };

  const getNewModuleList = () => {
    setTimeout(() => {
      if (course !== "Choose Course") {
        for (let i = 0; i < courseData.length; i++) {
          if (courseData[i].id == course) {
            setOpencourse(courseData[i]);
          }
        }
      }
    }, 1000);
  };

  const getCourseById = (val) => {
    if (isNaN(val)) {
      return "Choose Course";
    } else {
      for (let i = 0; i < courseData.length; i++) {
        if (val === courseData[i].id) {
          return courseData[i].course_name;
        }
      }
    }
  };

  const addSequence = (val) => {
    const data = openCourse?.modules_details;
    setSequence(val);
    setTimeout(() => {
      if (val < 1 && val) {
        errorToast("Sequence should be more than 0");
        setSequence();
        return false;
      } else {
        for (let i = 0; i < data?.length; i++) {
          if (data[i]?.sequence === parseInt(val)) {
            errorToast("Sequence Number already Exist");
            setSequence();
            return false;
          }
        }
      }
      return true;
    }, 1000);
  };
  useEffect(() => {
    if (course !== "Chooose Course") {
      getNewModuleList();
    }
  }, [courseData]);

  useEffect(() => {
    getSubjects()
      .then((res) => {
        console.log(res, "subjectData");
        setSubjectList(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (allowedTypes.includes(selectedFile?.type)) {
      setFile(selectedFile);
      let res = uploadFile(selectedFile);
      res.then((data) => {
        console.log(data, "uploadedImage");
        setImage(data);
      });
    } else {
      alert(
        "Invalid file type. Only images, videos, audios and PDFs are allowed."
      );
    }
  };

  useEffect(() => {
    let timer;
    if (search !== "") {
      timer = setTimeout(() => {
        async function getTillNext(resNext) {
          if (resNext == null) {
            return;
          }

          await loadMoreData(resNext)
            .then((res) => {
              setTimeout(() => {
                setSearchData((prev) => [...prev, ...res?.data?.data?.results]);
                getTillNext(res?.data?.data?.next);
              }, 200);
            })
            .catch((err) => {
              console.log(err);
              return;
            });
        }

        getLearningCourseSearch(search)
          .then((res) => {
            setSearchData([...res?.data?.data?.results]);
            getTillNext(res?.data?.data?.next, []);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    } else {
      setSearchData([]);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const handleCancel = () => {
    setIsEdit(false);
    setModuleName("");
    setModuleDescription("");
    setSequence("");
    setSubject("Select Subject");
  };

  const addOtherSubject = () => {
    navigate("/master-subjects");
  };

  const deleteModule = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete Module",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete Module!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCourseModule(id,access)
          .then((res) => {
            console.log(res);
            getAllLearningCourses();
            getNewModuleList();
          })
          .catch((err) => {
            console.log(err);
          });
        successToast("Deleted Successfully");
      } else {
        setSelectedItem();
      }
    });
  };

  const submitCourse = (e) => {
    e.preventDefault();
    if (course === "Choose Course") {
      errorToast("Kindly Choose the course");
      return;
    } else if (moduleName == "") {
      errorToast("Kindly give Module Name");
      return;
    } else if (!sequence) {
      errorToast("Kindly select Proper sequence");
      return;
    } else if (moduleDescription == "") {
      errorToast("Kindly Give The module description");
      return;
    } else if (subject == "Select Subject") {
      errorToast("Kindly Choose the subject");
      return;
    } else if (!isEdit) {
      saveCourseModule({
        course: course,
        module_name: moduleName,
        module_description: moduleDescription,
        moduleImage: image,
        is_active: true,
        sequence: sequence,
        subjects: subject,
      },access)
        .then((res) => {
          console.log(res, "courseData");
          handleCancel();
          setModuleName("");
          setModuleDescription("");
          setSequence("");
          setSubject("Select Subject");
          getAllLearningCourses();
          getNewModuleList();
          successToast("Module Created Succefully");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      updateCourseModule(selectedItem.id, {
        course: selectedItem?.course,
        module_name: moduleName,
        sequence: sequence,
        module_description: moduleDescription,
        module_image: image,
      }, access)
        .then((res) => {
          console.log(res);
          successToast("Module Updated Succefully");
          handleCancel();
          getAllLearningCourses();
          getNewModuleList();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function getAllLearningCourses() {
    GetLearningCourses("accepted")
      .then((res) => {
        console.log(res?.data?.data?.results, "institute Data");
        setLoading(false);
        setCourseData(res?.data?.data?.results);
        setNext(res?.data?.data?.next);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  useEffect(() => {
    setLoading(true);
    getAllLearningCourses();
  }, []);

  return (
    <div className="institute-container animate__animated animate__fadeIn">
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4>Sub Category Of Learning Courses (Module)</h4>
        </div>
      </div>
      <div className="table-container position-relative fluid-container">
        <span
          className="mustStar"
          style={{
            textAlign: "end",
            fontSize: "15px",
            position: "absolute",
            top: "-20px",
            left: "-16px",
          }}
        >
          Fields marked (*) are mandatory.
        </span>
        <div className="allDropDown allField-learning-courses">
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              Learning Course Name<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <select
                className="form-select sailors-Input-field"
                onFocus={loadData}
                value={course}
                onChange={(e) => setCourse(e.target.value)}
              >
                {/* <InfiniteScroll
                  dataLength={courseData.length}
                  next={loadData}
                  hasMore={next!==null ? true : false}
                  loader={<h6>Loading...</h6>}
                  scrollableTarget='scrollableDiv'
                  > */}
                <option>Select Learning Course</option>
                {courseData.length > 0 ? (
                  courseData?.map((item, index) => {
                    return (
                      <option
                        value={item?.id}
                        style={{ height: "50px", cursor: "pointer" }}
                      >
                        {item?.course_name}
                      </option>
                    );
                  })
                ) : (
                  <option style={{ marginTop: "10%" }}>
                    !Opps check the Internet
                  </option>
                )}
                {/* </InfiniteScroll> */}
              </select>
            </div>
          </div>

          {/* <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
            <p style={{ maxWidth: "231px", margin: "0px 11px 0px -11px" }}>Learning Course Name<span className='mustStar'>*</span></p>

            <div className="dropdown dropDown-locality dropDown-subLearning-Courses position-relative" style={{ maxWidth: "978px", marginRight: "-23px", marginLeft: "5px" }}>
              <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                {getCourseById(course)}<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" id='scrollableDiv' style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)" , width: "95%", height: "200px", overflowY: 'scroll' }}>
                <div className='searchBar-Container position-sticky' style={{ maxHeight: "50px",top:'0' }}>
                  <div className="input-group" style={{ marginBottom: "-100px", paddingLeft: "10px"}}>
                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control position-static" placeholder="Search ......" aria-label="Recipient's username" />
                  </div>
                </div>

                <InfiniteScroll
                  dataLength={search=='' ? courseData.length : searchData.length}
                  next={loadData}
                  hasMore={next !== null && search=='' ? true : false}
                  loader={<h6>Loading...</h6>}
                  scrollableTarget='scrollableDiv'
                >
                  {search == '' && <React.Fragment>{courseData.length > 0 ? courseData?.map((item, index) => {
                    return (
                      <div className="dropdown-item" onClick={() => { setOpencourse(item); setCourse(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{item?.course_name}</div>
                    )
                  }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}</React.Fragment>}

                  {search !== '' && <>{searchData.length > 0 ? searchData?.map((item, index) => {
                    return (
                      <div className="dropdown-item" onClick={() => { setOpencourse(item); setCourse(item.course_name) }} style={{ height: "50px", cursor: "pointer" }} >{item.course_name}</div>
                    )
                  }) : <center style={{ marginTop: "10%" }}>No Match</center>}</>}

                </InfiniteScroll>

              </div>
            </div>
          </span> */}

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              Module Name<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <input
                value={moduleName}
                onChange={(e) => {
                  setModuleName(e.target.value);
                }}
                type="text"
                className="form-control sailors-Input-field"
              />
            </div>
          </div>

          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Module Name<span className='mustStar'>*</span></p>

            <input required type='text' value={moduleName} onChange={(e) => setModuleName(e.target.value)} className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
          </span> */}

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              Module sequence<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <input
                value={sequence}
                onChange={(e) => {
                  addSequence(e.target.value);
                  setSequence(e.target.value);
                }}
                type="number"
                className="form-control sailors-Input-field"
              />
            </div>
          </div>

          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Module sequence<span className='mustStar'>*</span></p>

            <input required value={sequence} onChange={(e) => { addSequence(e.target.value); setSequence(e.target.value) }} type='number' className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
          </span> */}

          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              Module Description<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9">
              <textarea
                value={moduleDescription}
                onChange={(e) => setModuleDescription(e.target.value)}
                rows="3"
                className="form-control sailors-Input-field"
              />
            </div>
          </div>

          {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}> Module Description<span className='mustStar'>*</span></p>

            <textarea
              required
              size='sm'
              rows='3'
              resize={'vertical'}
              className='form-control sailors-Input-field'
              value={moduleDescription}
              onChange={(e) => setModuleDescription(e.target.value)}
            />
          </span> */}

          <span
            className="add-course-container add-course-country-container"
            style={{ maxWidth: "100%", padding: "20px" }}
          >
            <p
              className="col-form-label"
              style={{ maxWidth: "231px", margin: "0px 11px 0px -11px" }}
            >
              Module Subject<span className="mustStar">*</span>
            </p>

            <div
              className="dropdown dropDown-locality dropDown-subLearning-Courses"
              style={{
                maxWidth: "978px",
                marginRight: "-23px",
                marginLeft: "5px",
              }}
            >
              <button
                type="button"
                className={
                  width < 500
                    ? "btn drdpbtn form-control sailors-Input-field col "
                    : "btn form-control drdpbtn sailors-Input-field row "
                }
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ backgroundColor: "white", maxWidth: "100%" }}
              >
                {getSubjectById(subject)}
                <FaCaretDown style={{ width: "15px" }} />
              </button>
              <div
                className="dropdown-menu"
                style={{
                  boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)",
                  width: "300px",
                  height: "200px",
                  overflowY: "scroll",
                }}
              >
                <div
                  className="searchBar-Container"
                  style={{ maxHeight: "50px" }}
                >
                  <div
                    className="input-group position-sticky"
                    style={{ marginBottom: "-100px", paddingLeft: "10px" }}
                  >
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control position-static"
                      placeholder="Search ......"
                      aria-label="Recipient's username"
                    />
                  </div>
                </div>
                {search == "" && (
                  <React.Fragment>
                    {subjectList.length > 0 ? (
                      subjectList?.map((item, index) => {
                        return (
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              setSubject(item?.id);
                            }}
                            style={{ height: "50px", cursor: "pointer" }}
                          >
                            {getSubjectById(item.id)}
                          </div>
                        );
                      })
                    ) : (
                      <center style={{ marginTop: "10%" }}></center>
                    )}
                    <center style={{ height: "50px", cursor: "pointer" }}>
                      <div>
                        <div onClick={addOtherSubject}>Add Other Subject</div>
                      </div>
                    </center>
                  </React.Fragment>
                )}

                {search !== "" && (
                  <>
                    {searchData.length > 0 ? (
                      searchData.reverse()?.map((item, index) => {
                        return (
                          <div
                            className="dropdown-item"
                            style={{ height: "50px", cursor: "pointer" }}
                          >
                            {item.course_name}
                          </div>
                        );
                      })
                    ) : (
                      <center style={{ marginTop: "10%" }}>No Match</center>
                    )}
                  </>
                )}
              </div>
            </div>
          </span>
          <span
            className="d-flex add-course-container justify-content-center"
            style={{ maxWidth: "100%", padding: "10px" }}
          >
            <p className="col-form-label" style={{ maxWidth: "231px" }}>
              {" "}
              Module Image
            </p>
            <p style={{ maxWidth: "12px", marginRight: "10px" }}>-</p>
            <input type="file" onChange={handleFileChange} />
          </span>
        </div>
        <div className="form-btns" style={{ marginBottom: "10px" }}>
          <button type="submit" onClick={submitCourse} className="btn">
            {isEdit ? "Update" : "Save"}
          </button>
          <button onClick={handleCancel} className="btn btn-OnboardForm">
            Cancel
          </button>
        </div>
      </div>
      <div
        className="instituteOnboarding-list-view"
        style={{ marginTop: "20px" }}
      >
        <div className="headerPart">
          <h4>Module List of Course</h4>
        </div>
      </div>
      {loading ? (
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>
          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>
      ) : (
        <div className="table-container">
          {course === "Choose Course" ? (
            <center>
              <h3>Course Not Selected</h3>
            </center>
          ) : (
            <React.Fragment>
              {openCourse?.modules_details?.length > 0 ? (
                <div className="table-Data">
                  <table className="table">
                    <thead>
                      <tr
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <th style={{ fontWeight: "700", maxWidth: "50px" }}>
                          Sr No.
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "center" }}>
                          Course Name
                        </th>
                        <th style={{ fontWeight: "700", textAlign: "center" }}>
                          Module Name
                        </th>
                        <th style={{ fontWeight: "700" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {openCourse?.modules_details.map((item, index) => {
                        return (
                          <tr
                            className=""
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td style={{ textAlign: "center" }}>
                              {openCourse.course_name}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item?.module_name}
                            </td>
                            <td>
                              <div
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                <FaPen
                                  onClick={() => editData(item)}
                                  data-toggle="modal"
                                  data-target="#instituteDetailsModal"
                                  title="Details"
                                  style={{
                                    width: "30px",
                                    color: "#0CB1C0",
                                    border: "1px solid gray",
                                    padding: "5px",
                                    borderTopLeftRadius: "10px",
                                    borderBottomLeftRadius: "10px",
                                  }}
                                />{" "}
                                <RiDeleteBin6Line
                                  onClick={() => deleteModule(item.id)}
                                  title="Delete"
                                  style={{
                                    width: "30px",
                                    color: "#F84F31",
                                    border: "1px solid gray",
                                    padding: "5px",
                                    borderTopRightRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <center>No Data found</center>
              )}
              {/* {
                  openCourse?.modules_details.length > 0 ? openCourse?.modules_details.length.map((mod) => {
                    return (
                      <>

                      </>
                    )
                  })
                    :
                    <center><h3>Selected Subject has No modules</h3></center>
                } */}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default SubLearningCourses;
