import React, { useEffect, useRef, useState } from 'react'
import { getCities, getCountries, getStates } from '../../../Apis/getApis';
import { FaCaretDown, FaCloudUploadAlt, FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { v4 as uuidV4 } from "uuid";
import OtpInput from 'react-otp-input';
import { RegisterNewUser,  sendUserOtpEmail, verifiedEmailCheck, verifyUserOtpEmail } from '../../../Apis/postApis';
import "./InstituteRegister.css";
import Swal from 'sweetalert2';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from "react-simple-captcha";
import { Link, useNavigate } from 'react-router-dom';
import { isValidEmail, successToast, uploadFile, validatePassword } from '../../../utils/helperFunctions/HelperFunctions';
import { allowedTypesImages } from '../../../utils/variables/folderStructureFiles';
import validator from 'validator';
import useToken from '../../../customHook/useToken';
import LoaderSmall from '../../../common/loader/LoaderSmall';
const InstituteRegister = () => {
  const access = useToken();
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cities, setCitiesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPassword,setShowpassword] = useState(false);
  const [otp, setOtp] = useState('');
  // form fields
  const [buttonName, setButtonName] = useState("Institute/College");
  const [contactPerson, setContactPerson] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [entityName, setEntityName] = useState('');
  const [email, setEmail] = useState('');
  const [websiteName, setWebsiteName] = useState('');
  const [countryName, setCountryName] = useState("Country");
  const [city, setCity] = useState("City");
  const [state, setState] = useState("State");
  const [address, setAddress] = useState('');
  const [otherAddress, setOtherAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [otherPhone, setOtherPhone] = useState('');
  const [image, setImage] = useState('');
  const [countryId, setCountryId] = useState();
  const [stateId, setStateId] = useState();
  const [cityId, setCityId] = useState();
  const [file, setFile] = useState(null);
  const [validCaptcha, setValidCaptch] = useState(false);
  const [captchaValue, setCaptchaValue] = useState('');
  const [terms, setTerms] = useState(true);
  const [isPopUp, setIsPopUp] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [emailLoading,setEmailLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const errorToast = (msg) => toast.error(msg);

  const navigate = useNavigate();

  const verifyUserEmail = () => {
    console.log(otp, "otp")
    if (otp.length == 6) {
      verifyUserOtpEmail({ email: email, otp: otp })
        .then((res) => {
          setIsEmailVerified(true);
          setIsPopUp(false);
          successToast("Email Verified SuccessFully")
        })
        .catch((err) => {
          console.log(err);
          errorToast("Incorrect OTP")
          setOtp('')
        })
    }

    setOtp('')

  }

  const getCountryById = (id) => {
    for (let i = 0; i < countriesList.length; i++) {
      if (countriesList[i]?.id == id) {
        return countriesList[i];
      }
    }
  }

  const getStateById = (id) => {
    for (let i = 0; i < stateList.length; i++) {
      if (stateList[i]?.id == id) {
        return stateList[i];
      }
    }
  }

  const getCityById = (id) => {
    for (let i = 0; i < cities.length; i++) {
      if (cities[i]?.id == id) {
        return cities[i];
      }
    }
  }

  const passwordMatch = () => {
    if(password!=='' && !validatePassword(password)){
      errorToast('Password with 1 digit, 1 upper and 1 special');
      setPassword('');
      return;
  }

  if(confirmPassword!=='' && !validatePassword(confirmPassword)){
      errorToast('Password with 1 digit, 1 upper and 1 special');
      setConfirmPassword('');
      return;
  }

  if (password !== "" && password.length <= 3 || password.length >= 16) {
      setPassword("");
      errorToast("Password Must be more than 3 characters and less than 15 characters");
      return;
  }
  if (confirmPassword !== "" && confirmPassword.length <= 3 || confirmPassword.length >= 16) {
      setConfirmPassword('')
      errorToast("ConfirmPassword Must be more than 3 characters and less than 15 characters");
      return;
  }

  if (password !== "" && confirmPassword !== "" && password !== confirmPassword) {
      errorToast("Password and confirm Password not matching");
      setPassword('');
      setConfirmPassword("");
      return;
  }
  }
  

  function validUrl() {
    if (websiteName !== '') {
      if (!validator.isURL(websiteName)) {
        errorToast("Please enter valid url");
        setWebsiteName('');
        return;
      }
    }
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (allowedTypesImages.includes(selectedFile?.type)) {
      setFile(selectedFile);
      let res = uploadFile(selectedFile);
      res.then((data) => {
        console.log(data, "uploadedImage")
        setImage(data);
      })
    } else {
      errorToast('Invalid file type. Only images are allowed.');
    }
  };

  const makeCountry = (item) => {
    console.log(item,"i am country")
    if(!item){
      setCountryName("");
    setPhoneCode("")
    setCountryId("");  
      return;
    }
    setCountryName((prev) => prev = item?.name);
    setPhoneCode(item?.phone_code)
    setCountryId(item?.id);
    getStates(item?.id)
      .then((res) => {
        setStateList(res?.data?.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const makeState = (item) => {
    if(!item){
      setState("State");
      setStateId();
      return;
  }
    setState(item.name);
    setStateId(item?.id)
    setState((prev) => prev = item.name)
    getCities(item.id)
      .then((res) => {
        setCitiesList(res?.data?.data)

      })
      .catch((err) => {
        console.log(err)
      })

  }

  useEffect(() => {
    if (captchaValue.length == 4 && !validCaptcha) {
      if (validateCaptcha(captchaValue)) {
        successToast("captcha Validated")
        setValidCaptch(true);
      }
      else {
        errorToast("Opps Entered Invalid Captch");
        setCaptchaValue('')
      }
    }
  }, [captchaValue])

  const makeCity = (item) => {
    setCityId(item?.id);
    setCity((prev) => prev = item?.name)
  }
  const handleCancel = () => {
    setEmail('');
    setContactPerson('');
    setAddress('');
    setEntityName('');
    setPassword('');
    setCitiesList([]);
    setCity('');
    setCityId();
    setConfirmPassword('');
    setWebsiteName('');
    setPhone('');
    setOtherPhone('');
    setOtherAddress('');
    setStateList([]);
    setStateId();
    setState('')
  }
  const saveForm = (e) => {
    e.preventDefault();
    if (contactPerson == '') {
      errorToast("Please Give Contact Person Name");
      return;
    }
    if (password == '') {
      errorToast("Please Enter password");
      return;
    }
    if (confirmPassword == '') {
      errorToast("Please Select Confirm the password");
      return;
    }
    if (entityName == '') {
      errorToast(`Please Enter ${buttonName} Name`);
      return;
    }
    if (email == '') {
      errorToast("Please enter email Id");
      return;
    }
    if (websiteName == '') {
      errorToast("Please Enter Website URL");
      return;
    }
    if (countryId == '') {
      errorToast("Please Select the country");
      return;
    }
    if (address == '') {
      errorToast("Please Give address");
      return;
    }
    if (phone == '') {
      errorToast("Please Enter the phone number");
      return;
    }
    if (!validCaptcha) {
      errorToast("Please validate the captcha");
      return;
    }

    if(!isEmailVerified){
      errorToast('Please verify the Email')
      return;
    }

    let object = {

      email: email,
      first_name: entityName,
      contact_person: contactPerson,
      website: websiteName,
      mobile_number1: phone,
      mobile_number2: otherPhone,
      address1: address,
      address2: otherAddress,
      state: stateId,
      city: cityId,
      country: countryId,
      user_type: "institute",
      password: password,
      status: "pending",
      profile_pic: image,
      pin_code: "string",
      terms_and_conditions: terms
    }
    setUploading(true);
    RegisterNewUser(object, access)
      .then((res) => {
        console.log(res);
        successToast("Institute Saved Successfully")
        handleCancel();
        setUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
        errorToast("Something Went wrong");
      })
  }

 const sendEmail = (e) => {
         e.preventDefault();
         if (email !== "") {
           if (isValidEmail(email)) {
            setEmailLoading(true);
             sendUserOtpEmail({ email: email })
             .then((res) => {
                 setIsPopUp(!isPopUp);
                 console.log(res);
                 setEmailLoading(false);
               })
               .catch((err) => {
                 console.log(err);
                 setEmailLoading(false);
                 errorToast(err?.response?.data?.message?.non_field_errors[0])
               });
           } else {
             errorToast("Email is Invalid");
           }
         } else {
           errorToast("Please enter the email");
         }
     }

  useEffect(() => {
    setLoading(true);
    loadCaptchaEnginge(4);
    getCountries()
      .then((res) => {
        setLoading(false);
        setCountriesList(res?.data?.data)
      })
      .catch((err) => {
        setLoading(false);
        console.log(err)
      })
  }, [])
  return (
    <div
      className="boarding-form company-registration-form-container md:px-6 px-0 pb-8"
      style={{
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        overflow: "scroll",
        position: "fixed",
      }}
    >
      <div
        className="instituteOnboarding-list-view p-10"
        style={{ maxHeight: "150px", marginTop: "0px" }}
      >
        <div
          className="headerPart"
          style={{ display: "flex", flexDirection: "column", marginTop: "2px" }}
        >
          <center className="flex flex-column justify-center gap-y-2 h-14 my-8 w-auto">
            <Link to="https://sailorshub.us.tempcloudsite.com/">
              <img
                src="/assets/authAssets/Sailors-Hub-logo.gif"
                alt="logo"
                className="w-60 h-14 object-contain  mx-auto"
                style={{ filter: "drop-shadow(10px 10px 5px #999)" }}
              />
            </Link>
            <span>Register As Institute</span>
          </center>
        </div>

        <form className="container mt-5 md:pl-4 p-4 bg-white shadow rounded-2xl h-auto mb-5 text-base mx-auto font-montserrat">
          {/* <span className='mustStar h-25' style={{ textAlign: 'end', fontSize: "15px", position: 'absolute', top: "-20px", left: "-16px" }}>Fields marked (*) are mandatory.</span> */}
          <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="institute">
                                Institute/College<span className='mustStar'>*</span>
                            </label> */}
              <select
                style={{
                  boxShadow: "-1px 3px 20px 0px #0000001A",
                  minHeight: "40px",
                  outlineColor: "#86D3FF",
                }}
                id="institute"
                value={buttonName}
                onChange={(e) => setButtonName(e.target.value)}
                className="form-select sailors-Input-field"
              >
                <option value="">Select Institute/College </option>
                <option value={"Institute"}>Institute</option>
                <option value={"College"}>College</option>
              </select>
            </div>
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="contactPerson">
                            Contact Person<span className='mustStar'>*</span>
                        </label> */}
              <input
                placeholder="Contact Person"
                required
                id="contactPerson"
                type="text"
                value={contactPerson}
                onChange={(e) => setContactPerson(e.target.value)}
                className="px-2 sailors-Input-field"
              />
            </div>
          </div>

          <div className="row mb-0 d-flex flex-row justify-content-around companyFormDiv">
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-25">
              {/* <label className="form-label" htmlFor="password">
                            Password<span className='mustStar'>*</span>
                        </label> */}
              <input
                placeholder="Password"
                required
                id="password"
                value={password}
                onBlur={() => passwordMatch()}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                className="px-2 sailors-Input-field"
              />
              {!showPassword ? (
                <FaEyeSlash
                  onClick={() => setShowpassword(!showPassword)}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    color: "#0000004D",
                    width: "25px",
                    height: "25px",
                    right: "22",
                    top: "10",
                  }}
                />
              ) : (
                <FaEye
                  onClick={() => setShowpassword(!showPassword)}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    color: "#0000004D",
                    width: "25px",
                    height: "25px",
                    right: "22",
                    top: "10",
                  }}
                />
              )}
              <p
                className="mt-1 ml-1"
                style={{ fontSize: "10px", color: "#76767680" }}
              >
                Password with 1 digit, 1 upper and 1 special
              </p>
            </div>
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="confirmPassword">
                            Confirm Password<span className='mustStar'>*</span>
                        </label> */}
              <input
                placeholder="Confirm Password"
                required
                id="confirmPassword"
                value={confirmPassword}
                onBlur={() => passwordMatch()}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                className="px-2 sailors-Input-field"
              />
              {!showPassword ? (
                <FaEyeSlash
                  onClick={() => setShowpassword(!showPassword)}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    color: "#0000004D",
                    width: "25px",
                    height: "25px",
                    right: "22",
                    top: "10",
                  }}
                />
              ) : (
                <FaEye
                  onClick={() => setShowpassword(!showPassword)}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    color: "#0000004D",
                    width: "25px",
                    height: "25px",
                    right: "22",
                    top: "10",
                  }}
                />
              )}
              <p
                className="mt-1 ml-1"
                style={{ fontSize: "10px", color: "#76767680" }}
              >
                Password with 1 digit, 1 upper and 1 special
              </p>
            </div>
          </div>

          <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="instituteName">
                            Pin Code
                        </label> */}
              <input
                id="instituteName"
                placeholder={
                  buttonName !== ""
                    ? `${buttonName} Name`
                    : "Institute/College Name"
                }
                value={entityName}
                onChange={(e) => setEntityName(e.target.value)}
                type="text"
                className="px-2 sailors-Input-field"
              />
            </div>
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="email">
                            Email<span className='mustStar'>*</span>
                        </label> */}
              <input
                placeholder="Email"
                required
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="px-2 sailors-Input-field"
              />
              <button
                className="btn button-88 text-sm top-1 z-50"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                type="button"
                disabled={isEmailVerified ? true : false}
                onClick={(e) => {
                  sendEmail(e);
                }}
                style={{
                  borderRadius: "5px",
                  width: "auto",
                  maxHeight: "35px",
                  position: "absolute",
                  right: "25px",
                }}
              >
                {isEmailVerified ? <img src='/assets/authAssets/check-mark.gif' className='size-12' alt='verified'/> : emailLoading ? <LoaderSmall/> : "Verify"}
              </button>
            </div>
          </div>

          <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="websiteName">
                            Website URL<span className='mustStar'>*</span>
                        </label> */}
              <input
                placeholder="Website URL"
                id="websiteName"
                value={websiteName}
                onBlur={validUrl}
                onChange={(e) => setWebsiteName(e.target.value)}
                type="text"
                className="px-2 sailors-Input-field"
              />
            </div>
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="country">
                            Country<span className='mustStar'>*</span>
                        </label> */}
              <select
                id="country"
                style={{ minHeight: "40px" }}
                value={countryId}
                onChange={(e) => makeCountry(getCountryById(e.target.value))}
                className="form-select sailors-Input-field text-sm"
              >
                <option value="">Select Country</option>
                {countriesList?.map((item) => {
                  return (
                    <option
                      value={item?.id}
                      key={uuidV4()}
                      className="dropdown-item"
                      style={{ height: "50px" }}
                    >
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="state">
                            State
                        </label> */}
              <select
                id="state"
                style={{ minHeight: "40px" }}
                value={stateId}
                onChange={(e) => makeState(getStateById(e.target.value))}
                className="form-select sailors-Input-field"
              >
                <option>Select State</option>
                {stateList?.map((item) => {
                  return (
                    <option
                      value={item.id}
                      key={uuidV4()}
                      className="dropdown-item"
                      style={{ height: "50px" }}
                    >
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="city">
                            City
                        </label> */}
              <select
                id="city"
                value={cityId}
                style={{ minHeight: "40px" }}
                onChange={(e) => makeCity(getCityById(e.target.value))}
                className="form-select sailors-Input-field"
              >
                <option>Select City</option>
                {cities?.map((item) => {
                  return (
                    <option
                      value={item?.id}
                      key={uuidV4()}
                      className="dropdown-item"
                      style={{ height: "50px" }}
                    >
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="address">
                            Address<span className='mustStar'>*</span>
                        </label> */}
              <textarea
                rows="3"
                id="address"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                type="text"
                className="form-control sailors-Input-field"
              />
            </div>
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="otherAddress">
                            Other Address
                        </label> */}
              <textarea
                rows="3"
                id="otherAddress"
                placeholder="Other Address"
                value={otherAddress}
                onChange={(e) => {
                  setOtherAddress(e.target.value);
                }}
                type="text"
                className="form-control sailors-Input-field"
              />
            </div>
          </div>

          <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="contactNumber">
                            Contact Number<span className='mustStar'>*</span>
                        </label> */}
              <div className="relative flex flex-row items-center">
                <div
                  className={`mx-auto w-12 bg-slate-200 h-auto text-xs absolute left-2 py-2 ${phoneCode!=="" ? "px-1" : "px-2"} rounded-md`}
                  style={{ width: "auto", textAlign: "center" }}
                >
                  {" "}
                  {phoneCode !== "" ? phoneCode : "📞"}
                </div>
                <input
                  id="contactNumber"
                  placeholder="Contact Number"
                  type="number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="sailors-Input-field rounded-s-none focus:outline-none placeholder:text-base pl-5 ml-1"
                  style={{borderTopLeftRadius:"none"}}
                />
              </div>
            </div>

            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="otherContactNumber">
                            Other Contact Number
                        </label> */}
              <div className="relative flex flex-row items-center">
              <div
                  className={`mx-auto w-12 bg-slate-200 h-auto text-xs absolute left-2 py-2 ${phoneCode!=="" ? "px-1" : "px-2"} rounded-md`}
                  style={{ width: "auto", textAlign: "center" }}
                >
                  {" "}
                  {phoneCode !== "" ? phoneCode : "📞"}
                </div>
                <input
                  value={otherPhone}
                  placeholder="Other Contact Number"
                  onChange={(e) => setOtherPhone(e.target.value)}
                  id="otherContactNumber"
                  type="number"
                  className="sailors-Input-field text-sm rounded-s-none focus:outline-none placeholder:text-base pl-5 ml-1"
                />
              </div>
            </div>
          </div>

          <div className="row mb-3 d-flex flex-row justify-content-around items-center companyFormDiv">
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              <label
                className="form-label h-50  flex flex-row"
                htmlFor="uploadLogo"
              >
                <span>
                  Upload Logo{" "}
                </span>
                <FaCloudUploadAlt
                  style={{ width: "30px", marginLeft: "10px" }}
                />
              </label>
            </div>
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
               <input
                  id="uploadLogo"
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                  className="sailors-Input-field flex items-center py-2 px-2"
                />
            </div>
          </div>

          <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              <div className=" h-25  ntb2" htmlFor="uploadLogo">
                <LoadCanvasTemplate
                  reloadText="Reload captcha"
                  reloadColor="red"
                />
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              <div className="input-group">
                <input
                  type="text"
                  value={captchaValue}
                  onChange={(e) => setCaptchaValue(e.target.value)}
                  className="sailors-Input-field"
                  maxLength={4}
                  placeholder="Enter Captcha"
                />
              </div>
            </div>
          </div>

          <div
            className="flex md:flex-row flex-col justify-center items-center gap-x-2 gap-y-2 md:gap-y-2"
            style={{
              marginBottom: "20px",
              marginTop: "50px",
              maxHeight: "50px",
            }}
          >
            <button
              type="submit"
              onClick={(e) => saveForm(e)}
              className="py-1 rounded-md w-10 bg-[#005A80]"
              style={{border:'1px solid #005A80'}}
            >
              {uploading ?<center> <LoaderSmall/> </center>: "register"}
            </button>
            <button
              type="button"
              onClick={() => {
                navigate("/login");
              }}
              style={{border:'1px solid #005A80'}}
              className=" py-1 w-auto border-[#005A80] border-2 px-1 rounded-md"
            >
              Go To Login
            </button>
          </div>
        </form>
      </div>
      {/* <form className='animate__animated animate__fadeIn main-form' style={{ maxWidth: "50%",margin: "auto", paddingLeft: "30px", marginBottom: "91px", backgroundColor: "#ffffff" }}>

        <div className={width < 500 ? " col" : " row"} style={{ width: "100%", maxHeight: "4em", marginLeft: "-10px" }}>
          <div className={width < 500 ? 'form-group selectedField-row row' : 'form-group selectedField col'} style={{ paddingTop: "7px" }}>
            <button type="button" className={width < 500 ? "btn drdpbtn sailors-Input-field col" : "btn drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
              {buttonName}<FaCaretDown style={{ width: "15px" }} />
            </button>
            <div className="dropdown-menu" style={{ width: "95%", height: "100px" }}>
              <span onClick={(e) => { setButtonName((prev) => prev = "Institute") }} className="dropdown-item" style={{ height: "50px" }}>Institute</span>
              <span onClick={(e) => { setButtonName((prev) => prev = "College") }} className="dropdown-item" >College</span>
            </div>
          </div>
          <div className={width < 500 ? 'form-group row' : 'form-group col'}>
            <input value={contactPerson} onChange={(e) => setContactPerson(e.target.value)} type="text" className="form-control sailors-Input-field" placeholder="Contact Person" style={{ margin: "5px 0px 0px 5px" }} />
          </div>
        </div>

        <div className={width < 500 ? "form-group col" : "form-group row"} style={{ marginRight: "20px", gap: "25px", maxHeight: "50px" }}>
          <div className={width < 500 ? 'form-group row' : 'form-group col'}>
            <input value={password} onBlur={passwordMatch} onChange={(e) => setPassword(e.target.value)} type="password" className="form-control sailors-Input-field" placeholder="Password" />
          </div>
          <div className={width < 500 ? 'form-group row' : 'form-group col'}>
            <input value={confirmPassword} onBlur={passwordMatch} onChange={(e) => setConfirmPassword(e.target.value)} type="password" className="form-control sailors-Input-field" placeholder="Confirm Password" />
          </div>
        </div>

        <div className={width < 500 ? "form-group col" : "form-group row"} style={{ marginRight: "20px", gap: "25px", maxHeight: "55px" }}>
          <div className={width < 500 ? 'form-group row' : 'form-group col'}>
            <input type="text" value={entityName} onChange={(e) => setEntityName(e.target.value)} className="form-control sailors-Input-field" placeholder={`${buttonName} Name`} />
          </div>
          <div className={width < 500 ? 'form-group row' : 'form-group col'} style={{ display: "flex", flexDirection: 'row' }}>
            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control sailors-Input-field" placeholder="Email" />
            <button className='btn button-88' data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" onClick={(e) => { sendEmail(e) }} style={{ borderRadius: '5px', width: "150px", marginLeft: "5px", maxHeight: "45px" }}>Verify Email</button>
          </div>
        </div>

        <div className={width < 500 ? "form-group col" : "form-group row"} style={{ marginRight: "20px", gap: "30px", maxHeight: "57px" }}>
          <div className={width < 500 ? 'form-group row' : 'form-group col'}>
            <input value={websiteName} onChange={(e) => setWebsiteName(e.target.value)} type="text" className="form-control sailors-Input-field" placeholder='Website Name' />

          </div>
          <div className={width < 500 ? 'form-group row' : 'form-group col'} style={{ marginRight: "-16px" }}>
            <button type="button" className={width < 500 ? "btn drdpbtn col sailors-Input-field" : "btn sailors-Input-field drdpbtn row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
              {countryName}<FaCaretDown style={{ width: "15px" }} />
            </button>
            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
              {countriesList?.map((item) => {
                return (
                  <div key={uuidV4()} className="dropdown-item" style={{ height: "50px" }} onClick={() => makeCountry(item)}>{item?.name}</div>
                )
              })}
              {
                countriesList.length === 0 && <h4>!Opps check the Internet </h4>
              }
            </div>
          </div>
        </div>

        <div className={width < 600 ? "form-group col" : "form-group row"} style={{ gap: "25px", maxHeight: "61px", marginLeft: "-10px" }}>
          <div className={width < 600 ? 'form-group selectedField-row row' : 'form-group selectedField col'}>
            <button type="button" className={width < 500 ? "btn drdpbtn col sailors-Input-field" : "btn sailors-Input-field drdpbtn row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
              {state}<FaCaretDown style={{ width: "15px" }} />
            </button>
            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
              {stateList?.map((item) => {
                return (
                  <div key={uuidV4()} className="dropdown-item" style={{ height: "50px" }} onClick={() => makeState(item)}>{item?.name}</div>
                )
              })}
              {
                stateList.length === 0 && countryName === "Country" && <h5>Please select The Country</h5>
              }
            </div>
          </div>
          <div className={width < 600 ? 'form-group row' : 'form-group col'} style={{ marginRight: '-16px', maxHeight: "50px" }}>
            <button type="button" className={width < 500 ? "btn drdpbtn col sailors-Input-field" : "btn sailors-Input-field drdpbtn row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
              {city}<FaCaretDown style={{ width: "15px" }} />
            </button>
            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
              {cities?.map((item) => {
                return (
                  <div key={uuidV4()} className="dropdown-item" style={{ height: "50px" }} onClick={() => makeCity(item)}>{item?.name}</div>
                )
              })}
              {
                cities.length === 0 && state === "State" && <h5>Please select The State</h5>
              }
            </div>
          </div>



        </div>

        <div className={width < 600 ? "form-group col" : "form-group row"} style={{ marginLeft: "-33px", maxHeight: "70px" }}>
          <div className={width < 600 ? 'form-group selectedField-row  row' : 'form-group selectedField ntb col'}>
            <input type="text" className="form-control sailors-Input-field" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Address" style={{ padding: "0px 10px 0px 10px", margin: "0px -12px 0px -14px" }} />
          </div>
          <div className={width < 600 ? 'form-group row' : 'form-group col'} style={{ maxHeight: "50px" }}>
            <input type="text" className="form-control sailors-Input-field" value={otherAddress} onChange={(e) => setOtherAddress(e.target.value)} placeholder="Other Address" style={{ marginLeft: "10px" }} />
          </div>
        </div>
        <div className={width < 600 ? "form-group col" : "form-group row"} style={{ gap: "1px", maxHeight: "70px", marginTop: "-15px", marginLeft: "-25px" }}>
          <div className={width < 600 ? 'form-group selectedField-row row' : 'form-group selectedField col'}>
            <form className="form-inline">
              <div className="input-group">
                <div className="input-group-prepend" style={{ maxWidth: "35px", height: "46px" }}>
                  <div className="input-group-text">📞</div>
                </div>
                <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control numberField sailors-Input-field" id="inlineFormInputGroupUsername2" placeholder="Contact Number" />
              </div>
            </form>
          </div>
          <div className={width < 600 ? 'form-group row' : 'form-group col'}>
            <form className="form-inline">
              <div className="input-group">
                <div className="input-group-prepend" style={{ maxWidth: "35px", height: "46px" }}>
                  <div className="input-group-text">📞</div>
                </div>
                <input type="number" value={otherPhone} onChange={(e) => setOtherPhone(e.target.value)} className="form-control sailors-Input-field numberField" id="inlineFormInputGroupUsername2" placeholder="Other Contact Number" />
              </div>
            </form>
          </div>
          <div className={width < 600 ? 'form-group row' : 'form-group col'} style={{ maxHeight: "40px" }}>
            <form className="form-inline">
              <div className="input-group">
                <input type="file" className='uploadLogo-Input customFileInput' placeholder='Images' onChange={(e) => handleFileChange(e)} />
              </div>

            </form>
          </div>
        </div>

        <div className={width < 600 ? "form-group col" : "form-group row"} style={{ marginLeft: "-12px", maxHeight: "70px" }}>
          <div className={width < 600 ? 'form-group selectedField-row  row' : 'form-group selectedField ntb2 col'}>
            < LoadCanvasTemplate reloadText="Reload My Captcha" reloadColor="red" />
          </div>
          <div className={width < 600 ? 'form-group row' : 'form-group col'} style={{ maxHeight: "50px", marginRight: "10px", marginLeft: '14px' }}>
            <input type="text" value={captchaValue} onChange={(e) => setCaptchaValue(e.target.value)} className="form-control sailors-Input-field" maxLength={4} placeholder="Enter Captcha" style={{ marginLeft: "10px" }} />
          </div>
        </div>

        <div className={width < 600 ? "form-group col" : "form-group row"} style={{ marginLeft: "-12px", maxHeight: "20px", display: 'flex', flexDirection: 'row', width: "100%", justifyContent: 'start', alignItems: 'center' }}>
          <input type='checkbox' value={terms} onChange={(e) => setTerms(e.target.checked)} style={{ width: '20px', cursor: 'pointer' }} />
          <label style={{ width: "300px", marginLeft: "10px", cursor: 'pointer' }}>Accept Terms and conditions</label>
        </div>

        <div className='form-btns' style={{ maxHeight: "50px", marginBottom: "30px", marginTop: "65px" }}>
          <button disabled={validCaptcha} onClick={(e) => saveForm(e)} type="submit" className="btn" style={{ cursor: validCaptcha ? "not-allowed" : 'pointer' }}>Register</button>
          <button type='button' onClick={() => { navigate("/login") }} className="btn btn-OnboardForm" style={{ maxWidth: "200px", width: 'auto' }}>Go To Login</button>
        </div>

      </form> */}

      {isPopUp && (
        <div className="verifyEmailPopUp" style={{ position: "absolute" }}>
          <div class="modal-dialog" style={{ height: "auto" }}>
            <div class="modal-content">
              <div class="modal-header" style={{ margin: "auto" }}>
                <center>
                  <h5 class="modal-title" id="exampleModalLabel">
                    Verify Email 📧
                  </h5>
                </center>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={()=>setIsPopUp(false)}
                ></button>
              </div>
              <div class="modal-body">
                <center>
                  <h5>Your Email OTP is sent on Email</h5>
                </center>
                <div>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<b>&nbsp;&nbsp;&nbsp;&nbsp;</b>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      width: "33px",
                      height: "33px",
                      boxShadow:
                        "inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                      borderRadius: "5px",
                    }}
                    containerStyle={{ display: "flex", alignItems: "center" }}
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{ borderRadius: "10px",textAlign:"center", backgroundColor:'#005A80' }}
                  onClick={verifyUserEmail}
                >
                  Verify
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => setIsPopUp(!isPopUp)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InstituteRegister;
