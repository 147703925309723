import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const ChartComponent = ({employeeByDepartment}) => {
    
    let labelArray = [];
    for(let i=0;i<employeeByDepartment.length;i++){
        if(!labelArray.includes(employeeByDepartment[i]?.department)){
                labelArray.push(employeeByDepartment[i]?.department.toUpperCase())
            }
    }
    

    function giveData(){
        let dataArray = Array.from({ length: labelArray.length }, () => 0); // Initialize with 0s
        for(let i=0; i<labelArray.length; i++){
            for(let j=0; j<employeeByDepartment.length; j++){
                if(labelArray[i] === employeeByDepartment[j].department.toUpperCase()){
                    dataArray[i] = employeeByDepartment[j].count;
                }
            }
        }
        return dataArray;
    }

    const data = {
        labels: labelArray,
        datasets: [
            {
                label: 'Employees By Department',
                data: giveData(),
                backgroundColor: ['#005B6B', '#5C95AD', '#5C95AD', '#5C95AD'],
                barPercentage: 0.5,
                stack: 'Stack 1',
            },
        ],
    };

    const options = {
        responsive: true,
        aspectRatio: 1.15,
        plugins: {
            datalabels: {
                display: true,
                color: "black",
                formatter: Math.round,
                anchor: "end",
                offset: -20,
                align: "start"
              },
            legend: {
                display: true
            },
            tooltip: {
                mode: 'index',
                intersect: true,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };
    

    return (
        <div className=" mt-2 px-4">
            <Bar data={data} options={options} />
        </div>
    );
};

export default ChartComponent;